/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Grid, Image, Icon } from 'semantic-ui-react';

import { toggleSidebar } from '../sidebar/SidebarActions';
import { returnToLoginMode } from '../user/userActions';
import LoginModal from '../login/LoginModal';
//import * as api from './headerApi';
//import * as marketActions from '../pages/markets/actions/actions';
import SocialWidget from '../util/widgets/social/SocialDropdown';

import MarketTrendingHeader from '../pages/markets/widgets/header/marketTrendingHeader'
import HeaderWalletConnect from './HeaderWalletConnect'
import wallet_connect from "../../staticAssets/wallet_connect.png"
import PlatformMenu from '../components/PlatformMenu';

class Header extends Component {
  constructor(props) {
    super(props);
    const theme = window.localStorage.getItem('trybe_theme') ? window.localStorage.getItem('trybe_theme') : 'light';
    this.state = {
      visible: false,
      openLoginModal: false,
      publishIssue: false,
      menuItems: false,
      lightTheme: theme === 'light' ? true : false,
      isDropdown: false

    };

    this.notificationFeed = null;
  }

  async componentDidMount() {
    const {getHeaderData} = this.props;
    const el = document.querySelector('.app-body');
    el.addEventListener('scroll', this.handleScroll);
    //await getHeaderData();
    //await api.getPublishingIssue().then(publishIssue => this.setState({ publishIssue }));
    //await api.getMenuItems().then(menuItems => this.setState({ menuItems }));
  }

  componentWillUnmount() {
    const el = document.querySelector('.app-body');
    el.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const {
      getNotificationsFeed,
    } = this.props;
    const { isAuthorized, userInfo } = this.props;

    if (prevProps.isAuthorized !== isAuthorized) {
      if (isAuthorized) {
        this.closeLoginModal();
      }
    }

    if (prevProps.isAuthorized === false && isAuthorized === true) {
      this.subscribeOnRealtimeNotifications(userInfo.id);
      getNotificationsFeed({ userId: userInfo.id, markSeen: false });
    }

    if (prevProps.isAuthorized === true && isAuthorized === false) {
      this.notificationFeed.unsubscribe();
    }
  }

  handleScroll({ target }) {
    let postHeight = document.getElementById("postEnd")?.scrollHeight;
    let pageScroll = window.innerHeight + target.scrollTop;
    let pageHeight = target.scrollHeight;
    if (postHeight > 0) {
      document.querySelector(
        ".progress-loop"
      ).style.background = `linear-gradient(90.24deg, #6959E6 5.6%, #eeeeee26 ${pageScroll / (pageHeight - (postHeight + 1000)) * 100}%, #eeeeee26 ${pageScroll / (pageHeight - (postHeight + 1000)) * 100}%)`;
      document.querySelector(
        ".progress-loop"
      ).style.display = 'block';
    }
  }

  showLoginModal = () => this.setState({ openLoginModal: true });

  closeLoginModal = () => {
    const { resetModal } = this.props;
    this.setState({ openLoginModal: false });
    resetModal();
  }

  reopenLogin = () => {
    const { resetModal } = this.props;
    resetModal();
  }

  openSidebar() {
    const { sidebarOpened } = this.state;
    this.setState({ sidebarOpened: true });
  }

  toggleMenu = () => this.setState(prevState => ({ visible: !prevState.visible }))

  render() {
    const { history, toggleSidebarAction, scrollPosition, unseen, isAdmin, loadingNotifications, isAuthorized } = this.props;
    const { publishIssue, openLoginModal, sidebarOpened, menuItems, isDropdown } = this.state;
    const pageName = window.location.pathname;
    const marketPages = ['/', '/token/', '/trending/', '/gainers/','/losers/','/recent/']
    const marketPageCheck = marketPages.includes(pageName);
    const articlePage = pageName.includes("-");

    const toogleDropDown = () => {
      this.setState({ isDropdown: !this.state.isDropdown })
    }
    return (
      <Grid>
        {/*<MarketTrendingHeader data={marketData} />*/}

        <header>
          <Container>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
              <div className="container">
                <a className="navbar-brand fw-bold" href="/">
                  {/* <img src="/img/log-loop.png" alt="" /> */}
                  <img src="/img/loop_logo.png" className="logo" alt="" width="80px" />
                </a>
                <img className="cztoggle-menu-mobile" onClick={() => toggleSidebarAction('pages')} src="/menu.svg" alt="menu" />
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav mr-auto my-3 my-lg-0">
                    {/* {menuItems && menuItems[0].mainMenu.map((item) => {
                      return (<li hidden={!item.active} className="nav-item"><a className={pageName.includes(item.name.toLowerCase()) ? 'nav-link me-lg-3 active' : 'nav-link me-lg-3'} href={item.name == 'Community' ? '/community/' : item.link}><span>{item.name}</span></a></li>)
                    })}

                    <li hidden={!menuItems} className="nav-item dropdown" onClick={toogleDropDown}><span>. . .</span>
                      {isDropdown && (
                        <ul>
                          {menuItems[1].subMenu.map((item) => {
                            return (
                              <>
                                <li key={item.name} hidden={!item.active}><a className="dropdown-item" href={item.link} target="_blank"><span>{item.name}</span></a></li>
                              </>
                            )
                          })}
                        </ul>
                      )}
                    </li> */}
                  </ul>
                  {/* <HeaderWalletConnect /> */}
                  <PlatformMenu />
                  {/*
                  <SocialWidget />
                  */}
                  {/*isAuthorized ? (
                    <Grid className="user-area">
                      <Grid className="user-online">
                        <Image as={Link} to="../../../profile/my-articles/" className="img-profile rounded-circle" src={this.props.userInfo.avatar_urls[96]} />
                        <Link to="../../../profile/notifications/">
                          <span>
                            {unseen.length !== 0 && loadingNotifications === false && (
                              unseen.length
                            )}
                            {unseen.length === 0 && (
                              <Icon name="bell" style={{ margin: 'auto !important', height: 'auto !important' }} />
                            )}
                          </span>
                        </Link>
                      </Grid>
                    </Grid>
                  ) : (
                    <div className="combinedBtn">
                      <HeaderWalletConnect />
                    </div>
                  )*/}
                </div>
              </div>
            </nav>
          </Container>
          <div className="progress-loop" />
          {publishIssue ? (
            <>
            {/*<div className="newMessageHub">We are currently experiencing issues, Sorry for the inconvenience.</div>*/}
            <div className="newMessageHub">Account Creation is currently disabled while we work on community upgrades.</div>
            </>
          ) : ''}
        </header>
        <LoginModal
          open={openLoginModal}
          close={this.closeLoginModal}
          closePassword={this.reopenLogin}
        />
      </Grid >
    );
  }
}

export default withRouter(
  connect(
    state => ({
      userInfo: state.user.userInfo,
      isAuthorized: state.user.userIsLogged,
      loginning: state.user.loginning,
      subsribed: state.notifications.subscribed,
      realtimeNotifications: state.notifications.realtime,
      unseen: state.notifications.unseen,
      loadingNotifications: state.notifications.loading,
      acctName: state.user.userInfo.eosAccount,
      isAdmin: state.user.userInfo.extra_capabilities,
      info: state.post.postInfo,
    }),

    dispatch => ({
      toggleSidebarAction: (params) => {
        console.log(params);
        dispatch(toggleSidebar(params));
      },
      resetModal: () => {
        dispatch(returnToLoginMode());
      },
      getHeaderData: () => {
        dispatch(marketActions.getHeaderData());
      }
    })
  )(Header)
);
