import React, { Component } from 'react';
import { Image, Button } from 'semantic-ui-react';
import * as marketApi from '../../pages/markets/Api';

class MarketWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenData: null,
    };
  }

  async componentDidMount() {
    await marketApi.fetchTokenData().then(tokenData => this.setState({ tokenData }));
  }



  render() {
  const { tokenData } = this.state;
  
  return (
  <div className="main_action_btn cz-home-set-icons">
  <div className="marketsWidget">
    <div className="iconsInner">
      <div className="d-flex align-items-center justify-content-between">  <h3>Top 5</h3> <p className="updateData"><span></span>Live Prices</p></div>
        <div className="iconProgress">
        {tokenData && (
          <>
            {tokenData.slice(0, 1).map((data) => {
              // Return the element. Also pass key
              return (
              <ul className="activehover">
                <li className="iconTxtWrp"><div className="">
                <Image src={data?.icon} />
                  </div>
                  <div className="imgTxt">
                    <h5>{data.name}</h5>
                    <p>{data?.symbal}</p>
                  </div>
                  </li>
                  {/*
                  <li className="grphImg">
                  <Image src={graphImg} />
                  </li>
                  */}
                  <li className="rankHigh">
                    <h5>{data?.price ? data?.price : '?'}</h5>
                    <p><small>{data?.day > -0.01 ? <span className="marketUp">{data?.day}%</span> : <span className="marketDown">{data?.day}%</span>}</small></p>
                  </li>
                </ul>
              );
            })}
            {tokenData.slice(1, 5).map((data) => {
              // Return the element. Also pass key
              return (
                <ul>
                <li className="iconTxtWrp"><div className="">
                <Image src={data?.icon} />
                  </div>
                  <div className="imgTxt">
                    <h5>{data.name}</h5>
                    <p>{data?.symbal}</p>
                  </div>
                  </li>
                  {/*
                  <li className="grphImg">
                  <Image src={graphImg} />
                  </li>
                  */}
                  <li className="rankHigh">
                    <h5>{data?.price ? data?.price : '?'}</h5>
                    <p><small>{data?.day > -0.01 ? <span className="marketUp">{data?.day}%</span> : <span className="marketDown">{data?.day}%</span>}</small></p>
                  </li>
                </ul>
              );
            })}
          </>
        )}
      </div>
      <a href="https://dex.loop.markets/swap#Swap" target="_blank"><Button className="tradeButton" >Trade Now</Button></a>
    </div>
  </div>
</div>
)}
}


export default (MarketWidget);