import React, { Component } from 'react';
import { Container, Grid, Button, Image, Header, Card, List, Table, Tab, Menu, GridColumn, Segment, Modal, Icon, Divider } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import Helmet from 'react-helmet';
import * as actions from '../../yourtrybe/articles/articlesActions';
import * as api from './Api';
import ReCAPTCHA from 'react-google-recaptcha';
import * as marketApi from '../markets/Api';
import FeaturedCollections from './widgets/featuredCollections/index';
import ServicesSection from './widgets/services/index';
import CollectionsSection from './widgets/collections/index';
import Community from './widgets/community/index';
import CTASection from './widgets/cta/index';
import Banner from './widgets/banner/index';
import Footer from '../../footer/Footer';

class Home extends Component {
  constructor(props) {
    SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
    super(props);
    this.updateCategoriesQuery = Home.updateCategoriesQuery;
    this.state = {
      acfData: null,
      getPromos: '',
      currentAuthorQuery: [],
      currentCategoryQuery: [],
      accordinValue: 'accordionLoop',
      accordinValue1: 'mint',
      email: '',
      show: false,
      articlerecaptcha: false,
      jobLink: '',
      activeClass: 'normal',
      walletModal: false
    };
  }

  async componentDidMount() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'homepage_view',
      'event_category': 'page',
      'event_action': 'homepage_view'
    });
    const el = document.querySelector('.app-body');
    el.addEventListener('scroll', this.onScrollTo);


    // Detect request animation frame
    var scroll = window.requestAnimationFrame ||
      // IE Fallback
      function (callback) { window.setTimeout(callback, 1000 / 60) };
    var elementsToShow = document.querySelectorAll('.show-on-scroll');

    function loop() {

      Array.prototype.forEach.call(elementsToShow, function (element) {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });

      scroll(loop);
    }

    // Call the loop for the first time
    loop();

    // Helper function from: http://stackoverflow.com/a/7557433/274826
    function isElementInViewport(el) {
      // special bonus for those using jQuery
      if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
      }
      var rect = el.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }

    //Counter Js Start 

    $('.count').each(function () {
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 3000,
        easing: 'swing',
        step: function (now) {

          $(this).text((Math.ceil(now)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));


        }
      });
    });
    
    await marketApi.fetchTokenData().then(tokenData => this.setState({ tokenData }));
  }

  onScrollTo = ({ target }) => {
    var items = $(".timeline li"),
    var timelineHeight = $(".timeline ul").height(),
    var greyLine = $('.default-line'),
    var lineToDraw = $('.draw-line');

    if (lineToDraw.length) {
      var redLineHeight = lineToDraw.height();
      var greyLineHeight = greyLine.height();
      var windowDistance = $(window).scrollTop();
      var windowHeight = $(window).height() / 2;
      var timelineDistance = $(".timeline").offset().top;


      if (windowDistance >= timelineDistance - windowHeight) {
        var line = windowDistance - timelineDistance + windowHeight;

        if (line <= greyLineHeight) {

          lineToDraw.css({
            'height': line + 20 + 'px'
          });
        }
      }

      // This takes care of adding the class in-view to the li:before items
      var bottom = lineToDraw.offset().top + lineToDraw.outerHeight(true);

      var czchangecolorWrap = $(".timeline #draw6").offset().top;
      //console.log("timelinelogger",bottom,czchangecolorWrap)
      if (bottom > czchangecolorWrap) {
        lineToDraw.css({
          'background': '#bf20ab'
        });

      } else {
        lineToDraw.css({
          'background': '#20A474'
        });
      }

      items.each(function (index) {
        var circlePosition = $(this).offset();
        var leftID;
        if (bottom > circlePosition.top) {
          leftID = $(this).attr('id');

          $("#left" + leftID).addClass('left-animation-div');
          $("#mobileRight" + leftID).addClass('left-animation-div');
          $("#right" + leftID).addClass('left-animation-div');
          if (bottom < czchangecolorWrap)
            $(this).addClass('in-view');
        } else {
          leftID = $(this).attr('id');
          $("#left" + leftID).removeClass('left-animation-div');
          $("#mobileRight" + leftID).removeClass('left-animation-div');
          $("#right" + leftID).removeClass('left-animation-div');
          $(this).removeClass('in-view');
        }
      });

    }
  };

  sortOfficalAuthor() {
    const { acfData } = this.state;
    const query = acfData.authors.map(({ id }) => [id]);
    this.setState({ currentAuthorQuery: query });
  }

  handleOnChangeEmail = email => {
    this.setState({
      email: email
    })
  }

  handleSendEmail = email => {
    console.log(email)
  }

  showModal = (value, type) => {
    this.setState({ show: true });
    let url = ''
    if (type == 'partner') {
      url = "mailto:partner@loop.markets?subject=Partnership"
      this.setState({ jobLink: url });
    } else if (type == 'job') {
      url = "mailto:jointheteam@loop.do?subject=" + value + "&body=Tell%20us%20why%20you’re%20the%20best,%20and%20how%20you’ll%20complement%20what%20we%20do.%0D%0DPlease%20attach%20your%20Git%20and%20Linkedin%20or%20a%20Resume.%0D%0DGood%20Luck!"
      this.setState({ jobLink: url });
    } else {
      url = "mailto:jointheteam@loop.do?subject=Why%20you%20guys%20need%20me&body=Tell%20us%20how%20you%20can%20take%20Loop%20to%20the%20next%20level"
      this.setState({ jobLink: url });
    }

  };

  onScroll = ({ target }) => {
    let userScrollHeight = window.innerHeight + window.scrollY;
    let windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight && !this.state.scrollStatus) {
      this.setState({ activeClass: "Top" });
    }
  };


  hideModal = () => {
    this.setState({ show: false });
    window.grecaptcha.reset();
    this.setState({ articlerecaptcha: false })
    this.setState({ jobLink: '' });
  };

  openLink = () => {
    const { jobLink } = this.state
    window.open(jobLink, "_blank")
    this.hideModal();
  }

  handleRecaptcha = () => {
    const { articlerecaptcha } = this.state;
    this.setState({ articlerecaptcha: !articlerecaptcha })
    if (!articlerecaptcha) {
      this.openLink()
    }
  }

  static updateCategoriesQuery(chosenCategories) {
    const query = chosenCategories[0]
      ? chosenCategories.map(({ id }) => id).join(',')
      : null;
    return query;
  }

  activeAccordin(value) {
    const { accordinValue } = this.state
    if (accordinValue == value) {
      this.setState({ accordinValue: '' })
    } else {
      this.setState({ accordinValue: value })
    }
  };

  activeAccordin1(value) {
    const { accordinValue1 } = this.state
    if (accordinValue1 == value) {
      this.setState({ accordinValue1: '' })
    } else {
      this.setState({ accordinValue1: value })
    }
  };


  render() {
    const { currentCategoryQuery, currentAuthorQuery, tokenData, getPromos, accordinValue, accordinValue1, show, articlerecaptcha, jobLink, walletModal } = this.state;
    const { promos, history, isAuthorized } = this.props;
    const promoData = getPromos ? getPromos : promos;
    const panes = [
      { menuItem: <Menu.Item><span>1</span>Tokenized Rewards</Menu.Item>, render: () => <Tab.Pane><span>Unlike existing social networks, who keep all the money for themselves – we reward you for your contribution to Loop!</span><span>By creating amazing content on Loop you will be rewarded by the community in the LOOP token - our very own cryptocurrency that can be exchanged for any other currency that you choose.</span><span>The better your content the higher the reward! Start sharing your knowledge and earning today!</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>2</span>Great Articles</Menu.Item>, render: () => <Tab.Pane><span>Find great articles on a whole range of interesting topics and learn something new!</span><span>Keep up to date with topics of interest as our community scours the web for the most recent and relevant information and creates great content about it.</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>3</span>Crypto Exchange</Menu.Item>, render: () => <Tab.Pane><span>Use our decentralised exchange to swap all of your Terra assets.</span><span>Provide liquidity to our exchange and start earning fees from trades and from yield farming.</span><span>Stake your LOOP tokens and start earning staking rewards today!</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>4</span>Great Community</Menu.Item>, render: () => <Tab.Pane><span>Once upon a time, humans shared their knowledge, skills, and resources with their loop - that is what helped them to thrive.</span><span>In this day and age, it's more important than ever to be surrounded by the right people in order to succeed.</span><span>Join our rapidly growing network of forward thinking entrepreneurs, solopreneurs, cryptocurrency investors, ratbags, geniuses, and people who want to build a better life for ourselves and the world around us.</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>5</span>Our Own Economy</Menu.Item>, render: () => <Tab.Pane><span>By incentivizing sharing of knowledge, skills, and resources through cryptocurrency rewards, we are not just building a community but our very own global, peer to peer economy-decentralized, and free from the tyranny and corruption of established governments and corporations.</span></Tab.Pane> },
      // { menuItem: `${6} Build Your Own Trybe`, render: () => <Tab.Pane><span>Would you like to start building your own community using our gamification and incentivized reward systems?</span><span>You choose the rules, and the rewards, based on the actions you want your users to make.</span><span>You can either use points and create reward tiers like a traditional loyalty program, or you can introduce your very own cryptocurrency token into the mix. TRVBE</span><span>Either way - we will help you get set up and started.</span></Tab.Pane> },
    ];
    return (

      <main className="homepage HomePageFull">
        <div className='PopupWrap'></div>
        <Modal
          open={walletModal}
          onClose={() => this.setState((prevState) => ({ walletModal: !prevState.walletModal }))}
          size='small'
        >
          <Modal.Header className="loginModalHeader">

            <p>Work In Progress</p>
            <Button
              floated="right"
              className="cancel-button loginCloseBtn"
              icon
              basic
              onClick={() => this.setState((prevState) => ({ walletModal: !prevState.walletModal }))}>
              <Icon name="close" />
            </Button>
          </Modal.Header>
          <Modal.Content>
            <div className='ComingSoon'>
              <h1>Coming Soon</h1>
            </div>
          </Modal.Content>
          <Divider />
        </Modal>

        <Helmet>
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-KBD87CK"></script>
        </Helmet>
        {/* 
        <a href="https://restore-earth.angelprotocol.io/" target="_blank" style={{textDecoration: 'blink'}}>
          <div className="banner">
            <div className="banner-track">
              <div className="banner-text">
                <div>
                  <span>Donate. Get rewarded. <strong class="banner-text__bold"> Help Restore Earth 🌎</strong></span>
                </div>
              </div>
            </div>
          </div>
        </a>
        */}
        {/* ================   Hero section   =============== */}
        <div className="home">
          <FeaturedCollections />
          <ServicesSection />
          <CollectionsSection />
          <Community />
          <CTASection />
          <Banner />
          <Footer />
        </div>
      </main >
    );
  }
}
export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        promos: state.articles.promos,
        isAuthorized: state.user.userIsLogged,
        userInfo: state.user.userInfo,
      };
    },
    dispatch => ({
      getPromos: () => dispatch(actions.getAllPromos())
    })
  )(Home)
);