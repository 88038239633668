import axios from 'axios';

const BEARER_TOKEN = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImRhdGEiOnsidXNlcm5hbWUiOiJhdG9tbGF1bmNoIiwiZW1haWwiOiJlcmljQGxpbDJnb29kLmNvbSIsImNyZWF0ZWRfYXQiOiIyMDIxLTA4LTI0IDA3OjU5OjQzIiwidXBkYXRlZF9hdCI6IjIwMjEtMDgtMjQgMDc6NTk6NDMiLCJpZCI6MX0sImlhdCI6MTYyOTgxNzE4M30.4H1VCotSIHHBg2yImuv3DDXDTQkZatkvp2r0rChL1es`;

export async function getHeaderData() {
  const result = await axios
    .get(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v2/trending`,
      {headers: {Authorization: BEARER_TOKEN}});
  return result;
}

export async function getFeaturedNfts() {
  const result = await axios
    .get(`${process.env.REACT_APP_DIRECTUS_BACKEND}/items/featured_nfts?fields=status,collection_name,collection_image,url,coming_soon,launching,artist.*`);
  return result;
}