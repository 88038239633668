import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { ReactDOM } from 'react-dom';

function ModalExampleShorthand(props: ReactDOM) {
  return (
    <Modal
      className='marketModal'
      closeIcon
      basic
      open={props.open}
      onClose={() => props.closeAction()}
      header={`${props.modalType} ${props.asset}`}
      content='<===========> Action Here <===========>'
      size="mini"
    />
  )
}

export default ModalExampleShorthand;