import React from "react";
import { Container, Grid, Header, Image, List, Input } from "semantic-ui-react";

//import discordLogo from '../../staticAssets/images/discord-logo.svg';

const Footer = () => (
  <>
    <div className="footer_wrap">
      <Container>
        <Grid className="loop-grid">
          <Grid.Row className="footer-section">
            <Grid.Column
              className="about-company"
              computer={5}
              mobile={16}
              tablet={7}
            >
              <Image
                src="/img/logo_small.svg"
                wrapped
                ui={false}
                alt="loop-Logo"
                className="footerLogo"
              />
              <p>Loop: Trade any digital assets on Juno</p>
              <Grid.Row className="social-footer-section">
                <Grid.Column
                  className="social-footer"
                  computer={8}
                  mobile={16}
                  tablet={8}
                >
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="https://twitter.com/loop_markets"
                      >
                        <img src="/img/s1.svg" alt="twitter_social" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://t.me/loopfinance">
                        <img src="/img/s2.svg" alt="telegram_social" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://discord.gg/loopfinance">
                        <img src="/img/s3.svg" alt="discord_social" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCecsvrIVwT-bgy6-lDIEBTQ"
                      >
                        <img src="/img/s4.svg" alt="youtube" />
                      </a>
                    </li>
                  </ul>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column
              className="link-design"
              computer={3}
              mobile={16}
              tablet={7}
            >
              <Header as="h2">Support</Header>
              <List link inverted>
                <List.Item as="a" href="/help">
                  Help
                </List.Item>
                <List.Item as="a" href="https://docs.loop.markets/loop-finance/community/community-overview" target="_blank">
                  Docs
                </List.Item>
                <List.Item as="a" href="https://mail.loop.onl/privacy-policy/" target="_blank">
                  Privacy Policy
                </List.Item>
                <List.Item hidden as="a" href="#" target="_blank">
                  Apply for IDO
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column
              className="link-design"
              computer={3}
              mobile={16}
              tablet={7}
            >
              <Header as="h2">Community</Header>
              <List link inverted>
                <List.Item as="a" href="https://t.co/F8M5MpkDgP" target="_blank">
                  Telegram
                </List.Item>
                <List.Item as="a" href="https://t.me/loopannouncements" target="_blank">
                  Announcements
                </List.Item>
                <List.Item as="a" href="https://t.co/IxkmQgjkqD" target="_blank">
                  Discord
                </List.Item>
                <List.Item as="a" href="https://twitter.com/loop_markets" target="_blank">
                  Twitter
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column
              className="link-design"
              computer={5}
              mobile={16}
              tablet={7}
              hidden
            >
              <Header as="h2">Subscribe to newsletter</Header>
              <Input className="emailList" placeholder="Enter email" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
    <p className="copyright">Copyright © LOOP 2022. All rights reserved.</p>
  </>
);

export default Footer;
