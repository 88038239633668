import React, { PureComponent } from 'react';
import './Articles.scss';
import {
  Grid,
  Divider,
  Responsive,
  List,
  Image,
  GridColumn,
  Card,
  Button
} from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import Truncate from 'react-truncate';
import TextTruncate from 'react-text-truncate';
import moment from 'moment';
import Helmet from 'react-helmet';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { setPageForGoogleAnalytics } from '../../util/helperFunctions';
import Feed from '../../util/feed/Feed';
import * as actions from './articlesActions';
import * as followActions from '../following/FollowingActions';
import PayoutCountdown from '../../pages/payout/components/Countdown';
import Search from '../../pages/search/search';
import SideControl from '../components/SideControl';
//import * as api from './articlesApi';
import MarketsWidget from '../../util/widgets/markets';
import Footer from '../../footer/Footer';
import FeaturedCollections from '../../pages/markets/widgets/featuredCollections/index';


class Articles extends PureComponent {
  constructor(props) {
    SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
    super(props);
    this.addCategory = this.addCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectFavourites = this.selectFavourites.bind(this);
    this.selectTerraNews = this.selectTerraNews.bind(this);
    this.updateCategoriesQuery = Articles.updateCategoriesQuery;
    this.updateAuthorsQuery = Articles.updateAuthorsQuery;
    this.state = {
      currentAuthorQuery: [],
      currentCategoryQuery: [],
      orderQuery: '',
      categoriesState: 'all',
      // tabName: 'Latest',
      getPromos: '',
      acfData: null,
      hideFeedMenu: false,
      feedTabsEnabled: false,
    };
  }

  async componentDidMount() {
    setPageForGoogleAnalytics('your-trybe/articles');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event':'community_view',
      'event_category':'page',
      'event_action':'community_view'
    });
    const { getPromos } = this.props;
    const promoCache = JSON.parse(localStorage.getItem('trybe_promos'));
    const pageName = window.location.pathname;
    let tabStatus = localStorage.getItem('tabName');

    if (!promoCache && pageName == '/community/') {
      await getPromos();
    } else if (promoCache && pageName == '/community/') {
      this.setState({ getPromos: promoCache });
      await getPromos();
    }

    if (tabStatus) {
      this.handleOrderChange(tabStatus)
    } else {
      if(this.props.userInfo && this.props.userInfo.id){
        this.handleOrderChange('Feed')
      }else{
        this.handleOrderChange('Latest')
      }
    }
    this.getFollowingAndFollowers();

    //await api.getAcfInfo().then(acfData => this.setState({ acfData }));
    /*
    if (preChosenCategories) {
      this.setState(() => {
        const newCategories = [...preChosenCategories];
        const query = this.updateCategoriesQuery(newCategories);
        return {
          chosenCategories: newCategories,
          currentCategoryQuery: query
        };
      });
    }
    */
    // setTimeout(() => {
    //   let feedScroll = localStorage.getItem('communityScroll')
    //   console.log(feedScroll);
    //   console.log('helo wordl')
    //   if (feedScroll) {
    //     document.querySelector(".app-body").scrollTo(0, feedScroll)
    //   }
    // }, 10000)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { isLoadingFollowers, userInfo, following } = this.props;
    if (prevProps.isLoadingFollowers == isLoadingFollowers && prevProps.userInfo.id != userInfo.id) {
      this.getFollowingAndFollowers();
    }
    if (prevProps.following != following) {
      this.selectFavourites();
    }
    
    /*
    if (acfData && currentAuthorQuery?.length === 0 && tabName == 'Latest') {
      this.sortOfficalAuthor();
    }
    */
  }
  /*
  sortOfficalAuthor() {
    const { acfData } = this.state;
    const query = acfData.authors.map(({ id }) => [id]);
    this.setState({ currentAuthorQuery: query });
  }
  */
  getFollowingAndFollowers = () => {
    const { getFollowing, getFollowers, userInfo } = this.props;
    userInfo.id && getFollowing({
      userId: userInfo.id,
      page: 1
    });
    userInfo.id && getFollowers({
      userId: userInfo.id,
      page: 1
    });
  }

  addCategory(category) {
    const { categoriesState } = this.state;
    categoriesState === 'favorites'
      && this.addFavoriteCategory(category.id);
    this.setState((state) => {
      const { chosenCategories } = state;
      const newCategories = [...chosenCategories, category];
      const query = this.updateCategoriesQuery(newCategories);
      return {
        chosenCategories: newCategories,
        currentAuthorQuery: query
      };
    });
  }

  addFavoriteCategory = (categoryId) => {
    const { cookies, addFavoriteCategory } = this.props;
    const token = cookies.get('trybe_jwt');
    addFavoriteCategory({ category: categoryId, token });
  };

  deleteFavoriteCategory = (categoryId) => {
    const { cookies, deleteFavoriteCategory } = this.props;
    const token = cookies.get('trybe_jwt');
    deleteFavoriteCategory({ category: categoryId, token });
  };

  deleteCategory(category) {
    const { categoriesState } = this.state;
    categoriesState === 'favorites'
      && this.deleteFavoriteCategory(category.id);
    this.setState((state) => {
      const { chosenCategories } = state;
      const newChosenCategories = chosenCategories.filter(
        ({ id }) => id != category.id
      );
      const query = this.updateCategoriesQuery(newChosenCategories);
      return {
        chosenCategories: newChosenCategories,
        currentAuthorQuery: query
      };
    });
  }

  selectFavourites() {
    const { following } = this.props;
    const authors = following.map(data => (data.person));
    this.setState({
      categoriesState: 'favorites',
      currentAuthorQuery: this.updateAuthorsQuery(authors),
      currentCategoryQuery: []
    });
  }

  selectTerraNews() {
    this.setState({
      categoriesState: 'all',
      currentAuthorQuery: [],
      //currentCategoryQuery: this.updateCategoriesQuery(preChosenCategories),
      currentCategoryQuery: []
    });
  }

  selectAll() {
    this.setState({
      categoriesState: 'all',
      currentAuthorQuery: [],
      currentCategoryQuery: []
    });
  }

  static updateAuthorsQuery(authors) {
    const query = authors[0]
      ? authors.map(({ id }) => id).join(',')
      : null;
    return query;
  }

  static updateCategoriesQuery(chosenCategories) {
    const query = chosenCategories[0]
      ? chosenCategories.map(({ id }) => id).join(',')
      : null;
    return query;
  }


  // _extractPreChosenCategories(categories = []) {
  //   const {preChosenCategories, categories} = this.props;
  //   preChosenCategories.forEach((category) => {
  //     categories.push(category);
  //     if (category.subCategories[0] ) {
  //       this._extractPreChosenCategories(category.subCategories);
  //     }
  //   });
  //   return categories;
  // }

  handleOrderChange(value) {
    localStorage.setItem('tabName', value);
    //document.querySelector('.app-body').scrollTo(0, 0);
    // 30 Days
    const month = moment().subtract(30, 'days').toString();
    const month_date = new Date(month);

    // Week
    const week = moment().subtract(7, 'days').toString();
    const week_date = new Date(week);

    // Today
    const today = moment().subtract(1, 'day').toString();
    //const today_date = new Date(today);
    this.setState({ tabName: value });
    if (value === 'Feed') {
      this.selectFavourites();
      this.setState({ orderQuery: '' });
    } else if (value === 'Latest') {
      //this.selectTerraNews();
      this.setState({ orderQuery: '&role=author' });
      this.selectAll();
    } else if (value === 'Today') {
      this.setState({ orderQuery: '' });
      this.selectAll();
    } else if (value === 'Week') {
      this.setState({ orderQuery: `&after=${week_date.toISOString()}&orderby=token_score_average` });
      this.selectAll();
    } else if (value === 'Month') {
      this.setState({ orderQuery: `&after=${month_date.toISOString()}&orderby=token_score_average` });
      this.selectAll();
    } else if (!value) {
      this.setState({ orderQuery: '' });
      this.selectAll();
    }

    if (this.state.hideFeedMenu) {
      this.setState({ tabName: null });
      this.toggleFeedMenu(false)
    }
  }

  toggleFeedMenu() {
    this.setState({ hideFeedMenu: this.state.hideFeedMenu ? false : true });
  }

  render() {
    const { promos, userInfo, acctName, following, followers, isLoadingFollowers } = this.props;
    const { currentCategoryQuery, currentAuthorQuery, orderQuery, tabName, getPromos, hideFeedMenu, isFeedFollowers } = this.state;
    const promoData = getPromos ? getPromos : promos;
    const { history } = this.props;
    const follow_count = { following: following.length, followers: followers.length };
    //tabName == 'Feed' && !isLoadingFollowers && currentAuthorQuery && console.log("LOL")
    //tabName == 'Feed' && !isLoadingFollowers && currentAuthorQuery && console.log(currentAuthorQuery)

    return (
      <Grid className="promo">
        <Helmet>
          <title>Loop Finance | Community</title>
        </Helmet>
          <div className='featured_articles'>
            {promoData && (
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation
                loop
                autoplay={{ delay: 7000 }}
                className="feedSwiper"
                slideToClickedSlide
                breakpoints={{
                  1200: {
                    freemode: true,
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  768: {
                    freemode: true,
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  640: {
                    freemode: true,
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  320: {
                    freemode: true,
                    slidesPerView: 1,
                    spaceBetween: 20,
                  }
                }}
              >
                {promoData.map(data => (
                  <SwiperSlide>
                    <Card href={data?.article_link}>
                      <Image src={data?.article_image} wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>
                          <TextTruncate
                            line={2}
                            truncateText="..."
                            className="font-bold"
                            text={data?.article_title}
                          />
                        </Card.Header>
                        <Card.Description>
                          <TextTruncate
                            line={3}
                            truncateText="..."
                            className="font-thin"
                            text={data?.article_description}
                          />
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        <Grid className="page-wrapper">
          <SideControl />
          <div className="content-wrapper">
            <Grid className="c-row">
              <GridColumn computer={11} mobile={16} tablet={16} className="bg-info cz-set-new-blog">
                <GridColumn computer={5} mobile={16} tablet={16} className="right_side only-for-mobile">
                  {this.props.isAuthorized
                    ? (
                      <div>
                        <Grid className="loopCard userProfileCard">
                          <Grid className="profileInner">
                            <Grid className="profilePic position-relative">
                              <Image className="profileImg" src={userInfo.avatar_urls?.['96']} />
                              <span className="online" />
                            </Grid>
                          </Grid>
                          <Grid className="profilePic-details">
                            <p className="name">{userInfo.name}</p>
                            <p className="username">@{userInfo.username}</p>
                            <Button className="show-my-profile-mobile" onClick={() => history.push('/communityProfile')}>View My Engagement</Button>
                          </Grid>
                        </Grid>
                      </div>
                    )
                    : (
                      <Grid className="loopCard tokenCard">
                        <p className="joinText">Why Join Loop Community?</p>
                        <br />
                        <span className="text-light">
                          <p>Get all the latest ALPHA on NFT launches, IDO token sales, airdrops, and other important events in the Cosmos ecosystem!</p>
                          <br />
                          <br />
                          <p>Create content, win bounties, and interact with other Loop members to improve your crypto journey</p>
                        </span>
                        <br />
                        <br />
                        <button className="btn px-3 mb-2 mb-lg-0 joinBtn" type="button" onClick={() => history.push('/sign-up')}>Join</button>
                      </Grid>
                    )

                  }
                </GridColumn>
                <Grid className="tab-article">
                  <List link inverted className="post_tabs">
                    {userInfo.id && (
                      <List.Item as="a" className="nav-link" active={tabName == 'Feed'} onClick={() => this.handleOrderChange('Feed')}>
                        <span>My Feed</span>
                      </List.Item>
                    )}
                    <List.Item as="a" className="nav-link" active={tabName == 'Latest'} onClick={() => this.handleOrderChange('Latest')}>
                      <span>Official Authors</span>
                    </List.Item>
                    <List.Item as="a" className="nav-link" active={tabName == 'Today'} onClick={() => this.handleOrderChange('Today')}>
                      <span>Discover</span>
                    </List.Item>
                    <List.Item as="a" className="nav-link" active={tabName == 'Week'} onClick={() => this.handleOrderChange('Week')}>
                      <span>Weekly Best</span>
                    </List.Item>
                    {/*
                    <List.Item as="a" className="nav-link" active={tabName == 'Month'} onClick={() => this.handleOrderChange('Month')}>
                      <span>Monthly Best</span>
                    </List.Item>
                    */}
                    <List.Item className="nav-link" active={tabName == 'Month'}>
                      <div className="menu_actions">
                        {hideFeedMenu && (<Button className="searchIcon_exit" onClick={() => this.toggleFeedMenu()}><i class="fas fa-times"></i></Button>)}
                        {!hideFeedMenu && (<Button className="searchIcon" onClick={() => this.toggleFeedMenu()}><i class="fas fa-search"></i></Button>)}
                        {userInfo.id ? (
                          <Button as={Link} to='../post/editor/new/' className="write_article"><img src="../img/icons/write_article.svg" /> WRITE ARTICLE</Button>
                        ) : (
                          <Button as={Link} to='../sign-up' className="write_article">Join Community</Button>
                        )}
                      </div>
                    </List.Item>
                  </List>
                </Grid>
                <div>
                  <div className="mobile_menu_actions">
                    {hideFeedMenu && (<Button className="searchIcon_exit" onClick={() => this.toggleFeedMenu()}><i class="fas fa-times"></i></Button>)}
                    {!hideFeedMenu && (<Button className="searchIcon" onClick={() => this.toggleFeedMenu()}><i class="fas fa-search"></i></Button>)}
                    {userInfo.id ? (
                      <Button as={Link} to='../post/editor/new/' className="write_article"><img src="../img/icons/write_article.svg" /> WRITE ARTICLE</Button>
                    ) : (
                      <Button as={Link} to='../sign-up' className="write_article">Join Community</Button>
                    )}
                  </div>
                </div>

                {hideFeedMenu && (
                  <Search />
                )}
                {tabName == 'Feed' && !isLoadingFollowers && !hideFeedMenu && (
                  <Feed
                    isMyFeed={true}
                    follow_count={follow_count}
                    isLoadingFollowers={isLoadingFollowers}
                    tabName={
                      tabName
                    }
                    queryParams={
                      orderQuery + `&author=${currentAuthorQuery}`
                    }
                  />
                )}
                {tabName != 'Feed' && !hideFeedMenu && (
                  <Feed
                    isMyFeed={true}
                    follow_count={follow_count}
                    isLoadingFollowers={isLoadingFollowers}
                    tabName={
                      tabName
                    }
                    queryParams={
                      orderQuery + `${currentCategoryQuery.length > 0 ? `&categories=${currentCategoryQuery}` : ``}&cache=true`
                    }
                  />
                )}
              </GridColumn>
              <GridColumn computer={5} mobile={16} tablet={16} className="right_side cz-com-page-profile">
                {/*<MarketsWidget />*/}
                {this.props.isAuthorized
                  ? (
                    <div>
                      <Grid className="loopCard userProfileCard">
                        <Grid className="profileInner">
                          <p>
                            <Image src="../img/binoculars.svg" />{follow_count?.following}
                          </p>
                          <Grid className="profilePic position-relative" onClick={() => history.push('/profile/my-articles/')}>
                            <Image className="profileImg" src={userInfo.avatar_urls?.['96']} />
                            <span className="online" />
                          </Grid>
                          <p>
                            <Image src="../img/userTick.svg" />{follow_count?.followers}
                          </p>
                        </Grid>
                        <p className="name">{userInfo.name}</p>
                        <p className="username">@{userInfo.username}</p>
                        <Divider />
                        {/*
                        <p className="item"><span>Rank:</span> {userInfo.details?.rank}</p>
                        {acctName && (<p className="item"> Next Rank: {userInfo.details.engagement?.next_rank}</p>)}
                        {acctName && (<p className="item"> Tokens needed: {userInfo.details.engagement?.next_rank}</p>)}
                        */}

                        <Button className="show-my-profile" onClick={() => history.push('/profile/my-articles/')}>View Profile</Button>
                      </Grid>

                      {/* 
                      <Grid className="loopCard engagementCard">
                        <p className="title">Weekly Engagement Bonus</p>
                        {/*<span style={{ textAlign: 'center', fontSize: '12px' }}>Reach all the engagement goals <br /> ({userInfo.details.engagement.limit?.rates_limit} ratings, {userInfo.details.engagement.limit?.comments_limit} comments, and {userInfo.details.engagement.limit?.posts_limit} posts) to get 100% engagement and receive your full payout!</span>
                        <Grid className="enga">
                          <Grid className="innerBox">
                            <p>Engagement</p>
                            {/*<small><span className="number">{userInfo.details.engagement?.engagement + '%'}</span></small>
                            <small><span className="number">{(userInfo.details.engagement.limit?.rates_count > 100 ? '100' : userInfo.details.engagement.limit?.rates_count) + '%'}</span></small>
                          </Grid>
                          {userInfo.details.engagement.limit?.rates_limit > 0 && (
                            <Grid className="innerBox">
                              <p>Ratings <span>({userInfo.details.engagement.percent?.rate_percent})</span></p>
                              <small><span className="number">{userInfo.details.engagement.limit?.rates_count + '/' + userInfo.details.engagement.limit?.rates_limit}</span></small>
                            </Grid>
                          )}
                          {userInfo.details.engagement.limit?.posts_limit > 0 && (
                            <Grid className="innerBox">
                              <p>Posts <span>({userInfo.details.engagement.percent?.post_percent})</span></p>
                              <small><span className="number">{userInfo.details.engagement.limit?.posts_count + '/' + userInfo.details.engagement.limit?.posts_limit}</span></small>
                            </Grid>
                          )}
                          {userInfo.details.engagement.limit?.comments_limit > 0 && (
                            <Grid className="innerBox">
                              <p>Comments <span>({userInfo.details.engagement.percent?.comment_percent})</span></p>
                              <small><span className="number">{userInfo.details.engagement.limit?.comments_count + '/' + userInfo.details.engagement.limit?.comments_limit}</span></small>
                            </Grid>
                          )}
                        </Grid>
                        {/*<Button className="show-my-profile" onClick={() => history.push('/profile/engagement/')}>View Engagement Details</Button>
                      </Grid>
                      <Grid className="loopCard PayoutAmount">
                        <p className="title">Next payout amount</p>
                        <span className="blueTxt">{userInfo.details.engagement?.total_payout}</span>
                        <p className="line">Will be paid out in:</p>
                        <Grid className="d-flex align-items-center justify-content-between timeBox">
                          <PayoutCountdown />
                        </Grid>
                      </Grid>
                      */}
                    </div>
                  )
                  : (
                    <Grid className="loopCard tokenCard profileSticky">
                      <p className="joinText">Why Join Loop Community?</p>
                      <br />
                      <span className="text-light">
                        <p>Get all the latest ALPHA on NFT launches, IDO token sales, airdrops, and other important events in the Cosmos ecosystem!</p>
                        <br />
                        <br />
                        <p>Create content, win bounties, and interact with other Loop members to improve your crypto journey</p>
                      </span>
                      <br />
                      <br />
                      <button className="btn px-3 mb-2 mb-lg-0 joinBtn" type="button" onClick={() => history.push('/sign-up')}>Join</button>
                    </Grid>
                  )

                }
                {/* <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="TwitterDev"
                  options={{ height: 400 }}
                  theme="dark"
                  noHeader="true"
                  noBorders="true"
                  noFooter="true"
                /> */}
              </GridColumn>
            </Grid>

          </div>
          {/* ========================== Footer ====================== */}
          <Footer></Footer>
          {/* ========================== Footer End ====================== */}
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => {
        return {
          acctName: state.user.userInfo.eosAccount,
          currentCategory: state.articles.currentCategory,
          isAuthorized: state.user.userIsLogged,
          userInfo: state.user.userInfo,
          followers: state.following.followers,
          following: state.following.following,
          isLoadingFollowers: state.following.loadingList,
          isLoadingSubscription: state.following.loadingSubscribe,
          loadingIndexes: state.following.loadingIndexes,
          categories: state.articles.categories,
          promos: state.articles.promos,
          powerUpData: state.powerUp,
          postsData: state.feed.posts,
          cookies: ownProps.cookies
        };
      },
      dispatch => ({
        getPromos: () => dispatch(actions.getAllPromos()),
        addFavoriteCategory: params => dispatch(actions.addFavoriteCategory(params)),
        deleteFavoriteCategory: params => dispatch(actions.deleteFavoriteCategory(params)),
        getFollowing: (params) => {
          dispatch(followActions.getFollowing(params));
        },
        getFollowers: (params) => {
          dispatch(followActions.getFollowers(params));
        },
      })
    )(Articles)
  )
);

