import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Image,
  Loader,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import he from 'he';
import SideControl from '../yourtrybe/components/SideControl';
import { Twitter, Facebook, Whatsapp, Telegram, Linkedin, Reddit } from 'react-social-sharing';
import CategoriesAndTags from './components/CategoriesAndTags';
import BountiesArticles from './bountiesArticles'
import Helmet from 'react-helmet';
import * as actions from './bountyActions';
import * as postActions from '../post/postActions';
import * as api from './bountyApi';


class bountiesArticleList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { getBountyInfo, info, resetCommentSuccess } = this.props;
    resetCommentSuccess();
    //const { activePage } = this.state;
    if (!info.id) {
      const pathname = location.pathname.split('/');
      pathname.pop();
      const id = pathname.pop();
      getBountyInfo({ id });
    } else {
      const pathname = location.pathname.split('/');
      pathname.pop();
      const id = pathname.pop();
      id && getBountyInfo({ id });
      //id && getPostComments({ post: info.id, page: activePage });
    }

    //document.querySelector('.app-body').scrollTop = 0;
  }

  componentDidUpdate(prevProps) {
    const { info } = this.props;
    if (info.bounty) {
      api.getTopEntries(info.bounty[0]).then(bountyFeed => {
        this.setState({ bountyFeed: bountyFeed.data })
      });
    }
  }

  render() {
    const { history, info, isBountyLoading, userInfo } = this.props;
    const { bountyFeed } = this.state;
    const { id, title, _embedded, content, date_gmt, bounty, details, bounty_open, acf } = info;

    return (
      <Grid className="promo">
        <Helmet>
          <title>Loop Finance | Bounties Articles</title>
        </Helmet>
        {id && !isBountyLoading && (
          <Grid className="page-wrapper">
            <Helmet>
              <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
              <meta name="title" content={he.decode(title.rendered).replace(/(<([^>]+)>)/ig, '')} />
              <meta name="description" content={he.decode(info.excerpt.rendered).replace(/(<([^>]+)>)/ig, '')} />
              <meta property="og:site_name" content="Loop" />
              <meta property="og:url" content={'https://loop.markets/' + info.slug} />
              <meta property="og:type" content="article" />
              <meta property="og:title" content={he.decode(title.rendered).replace(/(<([^>]+)>)/ig, '')} />
              <meta property="og:description" content={he.decode(info.excerpt.rendered).replace(/(<([^>]+)>)/ig, '')}  />
              <meta property="og:image" content={_embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'][0].source_url} />
              <meta property="og:updated_time" content={info.modified} />
              <meta property="article:published_time" content={info.date} />
              {/* Twitter Card Data */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content={`https://loop.markets/${info.slug}`} />
              <meta name="twitter:title" content={he.decode(title.rendered).replace(/(<([^>]+)>)/ig, '')} />
              <meta name="twitter:description" content={he.decode(info.excerpt.rendered).replace(/(<([^>]+)>)/ig, '')}  />
              <meta name="twitter:image" content={_embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'][0].source_url} />
            </Helmet>
            <SideControl />
            <div className="content-wrapper cz-bountyPageWrap Bounty_competitionsArticle" style={{marginBottom: '100px'}}>
              <div className="cz-bountyPage single">
                <div className="createCompetitionArticle">
                  <div className="approveHeading bountyHeading">
                    <div className="approveHeading_inner">
                      <span onClick={() => history.push('/bounties/')}><img src="/../wt-back-arrow.png" /> Back to All Bounties</span>
                      <h1><span>LOOP </span> Bounty competitions</h1>
                    </div>
                  </div>


                  <Grid.Column className="post-header">
                    <div className="post-header__visual">
                      <h3>{he.decode(title.rendered)}</h3>
                    </div>
                    <div className="btn_box">
                      <CategoriesAndTags
                        bounty_tag={_embedded['wp:term'] && _embedded['wp:term'][2]}
                        tags={_embedded['wp:term'] && _embedded['wp:term'][1]}
                        categories={_embedded['wp:term'] && _embedded['wp:term'][0]}
                      />
                    </div>
                    <div class="post-date">
                      <div class="post-author">
                        <div class="img-author">
                          <Image
                            src={_embedded.author[0].avatar_urls[96]}
                            class="ui avatar circular image"
                            as={Link}
                            to={`/user/${_embedded.author[0].id}/`}
                            onClick={() => this.onUserClick(_embedded.author[0])}
                          />
                        </div>
                        <div class="text-author">
                          <p>
                            <div className="user_name"> Published By: {_embedded.author[0].name}<span className="dot">.</span></div>
                            <span class="time-txt">
                              {moment
                              .utc(date_gmt)
                              .local()
                              .format('MMMM DD, YYYY')}
                            </span>
                            <span className="dot">.</span>
                            <span class="times-txt"><img src="/cmnt_icn.png" /> {details.post_comments_number}</span>{/* <span className="dot">.</span> */}
                            {/* <span class="time-txt"><a className="follow_btn" href="#"><span>+</span> follow</a> </span> */}
                          </p>

                        </div>
                      </div>
                    </div>
                  </Grid.Column>


                  {_embedded['wp:featuredmedia'] && (
                    <Grid.Row className="cz-post-header-top">
                      <Grid.Column>
                        <div className="post-featured-image">
                          <Image
                            src={`${_embedded['wp:featuredmedia'][0].source_url}`}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  )}


                  <Grid.Row className="cz-set-set">
                    <div
                      className="post"
                      dangerouslySetInnerHTML={{ __html: content.rendered }}
                    />
                  </Grid.Row>


                  <Grid.Row className="social_wrap btnWrap">
                    <Grid.Column>
                      <div className="social-buttons-container">
                        <Facebook link={window.location.href} />
                        <Telegram link={window.location.href} />
                        <Twitter link={window.location.href} />
                        <Linkedin link={window.location.href} />
                        <Whatsapp link={window.location.href} />
                        <Reddit link={window.location.href} />
                      </div>
                    </Grid.Column>
                  </Grid.Row>

                </div>
                  <BountiesArticles bountyFeed={bountyFeed} status={bounty_open} bounty_reward={acf.bounty_reward} bounty_end={acf.end_date} vote_end={acf.voting_period} />
              </div>
            </div>
          </Grid>
        )}
        <Loader active={isBountyLoading} />
      </Grid>
    )
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        isAuthorized: state.user.userIsLogged,
        info: state.bounties.bountyInfo,
        isBountyLoading: state.bounties.bountyLoading,
        userInfo: state.user.userInfo,
        following: state.userReview.amIFollowing,
        isLoadingSubscription: state.following.loadingSubscribe,
        gettingUserReviewData: state.userReview.loading,
      };
    },
    dispatch => ({
      getBountyInfo: (params) => {
        dispatch(actions.getBountyInfo(params));
      },
      resetCommentSuccess: () => {
        dispatch(postActions.resetCommentSuccess());
      },
    })
  )(bountiesArticleList)
);