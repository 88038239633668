import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Container, Grid, Image, Button } from "semantic-ui-react";
import styles from "../../index.module.scss";
import ticketing from "../../../../../staticAssets/images/home/ticketing.png";
import digital from "../../../../../staticAssets/images/home/digital.png";
import metaverse from "../../../../../staticAssets/images/home/metaverse.png";

class CTASection extends Component {
  render() {
    return (
      <>
        <div className={(styles.section, styles.section__cta)}>
          <div className={styles.heading}>
            <Container>
              <h3>In The Loop</h3>
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do{" "}
                amet sint. Velit officia consequat duis enim velit mollit.{" "}
              </p>
            </Container>
          </div>
          <div className={styles.content}>
            <Container>
              <Grid columns="four" divided>
                <Grid.Row>
                  <Grid.Column className={styles.column}>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news1.png" />
                    </div>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news5.png" />
                    </div>
                  </Grid.Column>
                  <Grid.Column className={styles.column}>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news2.png" />
                    </div>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news8.png" />
                    </div>
                  </Grid.Column>
                  <Grid.Column className={styles.column}>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news3.png" />
                    </div>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news7.png" />
                    </div>
                  </Grid.Column>
                  <Grid.Column className={styles.column}>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news4.png" />
                    </div>
                    <div className={styles.content}>
                      <Image src="/assets/images/news/news6.png" />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          <div className={styles.footer}>
            <Container>
              <Button>View More</Button>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(CTASection);
