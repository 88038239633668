import { useCallback, useState } from "react";
import { Popup } from "semantic-ui-react";
import wallet_connect from "../../staticAssets/wallet_connect.png";

const WalletConnect = () => {
  const [contractAddress, setContractAddress] = useState("");
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("");

  const truncate = (str, n) => {
    return str.length > n
      ? str.substr(0, n - 1) + "..." + str.substr(40, 3)
      : str;
  };

  return (
    <div>
      <button
        id="loginBtnHead"
        className="btn headerBtn headerBtn--connect px-3 mb-2 mb-lg-0"
        type="button"
        //onClick={connect}
        //disabled
      >
        <img
          src={wallet_connect}
          alt="wallet connect"
          style={{ width: "25px", marginRight: "5px" }}
        />{" "}
        Connect Wallet
      </button>
    </div>
  );
};

export default WalletConnect;
