import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './postActions';
import * as api from './postApi';

export const postWatchers = [
  getPostInfoWatch,
  getPostCommentsWatch,
  sendPostCommentWatch,
  successfullCommentSendPostWatch,
  successfullReplySendPostWatch,
  sendReplyToPostCommentWatch,
  sendUpdateToPostCommentWatch,
  successfullUpdateSendPostWatch,
  failureUpdateSendPostWatch,
  sendReportToPostCommentWatch,
  successfullReportSendPostWatch,
  sendUpVoteToPostCommentWatch,
  getPostRatingWatch,
  sendPostRatingWatch,
  getAuthorPostWatch,
  getPrerenderWatch,
  sendPrerenderWatch,
  getReportArticleWatch,
  sendCuratePostWatch,
];

export function* getPostInfoWatch() {
  yield takeLatest(actions.getPostInfo.TRIGGER, getPostInfo);
}
export function* getReportArticleWatch() {
  yield takeLatest(actions.reportArticleRoutine.TRIGGER, reportArticle);
}
export function* getPrerenderWatch() {
  yield takeLatest(actions.getPrerenderRoutine.TRIGGER, getPrerender);
}
export function* sendPrerenderWatch() {
  yield takeLatest(actions.sendPrerenderRoutine.TRIGGER, sendPrerenderAction);
}
export function* getAuthorPostWatch() {
  yield takeLatest(actions.getAuthorPost.TRIGGER, getAuthorPost);
}
export function* getPostCommentsWatch() {
  yield takeLatest(actions.getPostComments.TRIGGER, getPostComments);
}
export function* sendPostCommentWatch() {
  yield takeLatest(actions.sendPostComment.TRIGGER, sendPostComment);
}
export function* successfullCommentSendPostWatch() {
  yield takeLatest(actions.sendPostComment.SUCCESS, getPostComments);
}
export function* getPostRatingWatch() {
  yield takeLatest(actions.getPostRating.TRIGGER, getPostRating);
}
export function* sendReplyToPostCommentWatch() {
  yield takeLatest(actions.replyToPostComment.TRIGGER, sendReplyToPostComment);
}
export function* successfullReplySendPostWatch() {
  yield takeLatest(actions.replyToPostComment.SUCCESS, getPostComments);
}


export function* sendUpdateToPostCommentWatch() {
  yield takeLatest(actions.updateToPostComment.TRIGGER, sendUpdateToPostComment);
}
export function* successfullUpdateSendPostWatch() {
  yield takeLatest(actions.updateToPostComment.SUCCESS, getPostComments);
}
export function* failureUpdateSendPostWatch() {
  yield takeLatest(actions.updateToPostComment.FAILURE, getPostComments);
}

export function* sendReportToPostCommentWatch() {
  yield takeLatest(actions.reportToPostComment.TRIGGER, sendReportToPostComment);
}
export function* successfullReportSendPostWatch() {
  // yield takeLatest(actions.reportToPostComment.SUCCESS, getPostComments);
}

export function* sendUpVoteToPostCommentWatch() {
  yield takeLatest(actions.upVoteToPostComment.TRIGGER, sendUpVoteToPostComment);
}

export function* sendCuratePostWatch() {
  yield takeLatest(actions.setCuratePost.TRIGGER, sendCuratePost);
}

export function* sendPostRatingWatch() {
  yield takeLatest(actions.sendPostRating.TRIGGER, sendPostRating);
}

function* getPostInfo({ payload }) {
  const {id} = payload;
  let serverResponse;
  if (parseInt(id)) serverResponse = yield api.getPostInfo(payload);
  else serverResponse = yield api.getPostInfoBySlug(payload);

  if (serverResponse.data) {
    Array.isArray(serverResponse.data)
      ? yield put(actions.getPostInfo.success(serverResponse.data[0]))
      : yield put(actions.getPostInfo.success(serverResponse.data));
  } else {
    yield put(actions.getPostInfo.failure(serverResponse));
  }
}

function* reportArticle(payload) {
  const serverResponse = yield api.reportArticle(payload);
  if (serverResponse.status == 200) {
    yield put(actions.reportArticleRoutine.success(serverResponse.data));
  } else {
    yield put(actions.reportArticleRoutine.failure(serverResponse));
  }
}


function* getPrerender(data) {
  const url = `https://www.loop.markets/${data.payload}/`
  console.log(url)
  const draft = data.payload == '';
  if (!draft) {
    const serverResponse = yield api.getPrerender(url);
    if (serverResponse.data[0]) {
      yield put(actions.getPrerenderRoutine.success(serverResponse.data[0]));
    } else {
      yield put(actions.getPrerenderRoutine.failure(serverResponse));
    } 
  } else {
    yield put(actions.getPrerenderRoutine.failure(serverResponse));
  }
}

function* sendPrerenderAction(data) {
  const url = `https://www.loop.markets/${data.payload}/`
  console.log(url)
  const serverResponse = yield api.sendPrerender(url);
  if (serverResponse) {
    console.log(serverResponse)
    yield put(actions.sendPrerenderRoutine.success(serverResponse.data));
  } else {
    yield put(actions.sendPrerenderRoutine.failure(serverResponse));
  }
}

function* getAuthorPost({ payload }) {
  const serverResponse = yield api.getAuthorPost(payload);
  if (serverResponse.data) {
    yield put(actions.getAuthorPost.success(serverResponse.data));
  } else {
    yield put(actions.getAuthorPost.failure(serverResponse));
  }
}

function* getPostComments({ payload }) {
  const serverResponse = yield api.getPostComments(payload);
  if (serverResponse.data) {
    yield put(actions.getPostComments.success(serverResponse.data));
  } else {
    yield put(actions.getPostComments.failure(serverResponse));
  }
}

function* sendPostComment({ payload }) {
  console.log('comment info', payload);
  const serverResponse = yield api.sendComment(payload);
  if (serverResponse.data.status == "approved") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event':'comment-btn',
    'event_category':'community',
    'event_action':'comment-btn',
    'event_label': payload.authorName //the article's author
    });
    yield put(actions.sendPostComment.success({post: serverResponse.data.post, page: 1}));
  } else {
    yield put(actions.sendPostComment.failure(serverResponse));
  }
}

function* getPostRating({ payload }) {
  const serverResponse = yield api.getPostRating(payload);
  if (serverResponse.status === 200) {
    yield put(actions.getPostRating.success(serverResponse.data));
  } else {
    yield put(actions.getPostRating.failure(serverResponse));
  }
}

function* sendPostRating({ payload }) {
  const serverResponse = yield api.sendPostRating(payload);
  if (serverResponse.status === 200) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event':'rate-article',
    'event_category':'community',
    'event_action':'rate-article',
    'event_label': payload.authorName, //the article’s author
    'event_value': payload.rating //quantity of stars as the number
    });
    yield put(actions.sendPostRating.success(serverResponse.data));
  } else {
    yield put(actions.sendPostRating.failure(serverResponse));
  }
}

function* sendReplyToPostComment({ payload }) {
  console.log(payload)
  const serverResponse = yield api.sendReplyToPostComment(payload);
  if (serverResponse.data) {
    yield put(actions.replyToPostComment.success({post: serverResponse.data.post, page: 1}));
  } else {
    yield put(actions.replyToPostComment.failure(serverResponse));
  }
}


function* sendUpdateToPostComment({ payload }) {
  const serverResponse = yield api.sendUpdateToPostComment(payload);
  if (serverResponse.data) {
    yield put(actions.updateToPostComment.success({post: serverResponse.data.post, page: 1}));
  } else {
    yield put(actions.updateToPostComment.failure(serverResponse));
  }
}

function* sendReportToPostComment({ payload }) {
  const serverResponse = yield api.sendReportToPostComment(payload);
  if (serverResponse.data) {
    yield put(actions.reportToPostComment.success({post: serverResponse.data.post, page: 1}));
  } else {
    yield put(actions.reportToPostComment.failure(serverResponse));
  }
}

function* sendUpVoteToPostComment({ payload }) {
  const serverResponse = yield api.sendUpVoteToPostComment(payload);
  if (serverResponse.data) {
    yield put(actions.reportToPostComment.success({post: serverResponse.data.post}));
  } else {
    yield put(actions.reportToPostComment.failure(serverResponse));
  }
}

function* sendCuratePost({ payload }) {
  const serverResponse = yield api.sendCuratePostApi(payload);
  if (serverResponse.data) {
    yield put(actions.setCuratePost.success(serverResponse));
  } else {
    yield put(actions.setCuratePost.failure(serverResponse));
  }
}