import { ReactDOM } from 'react-dom';

function format(value: any) {
  return Math.abs(Number(value)) >= 1.0e+9
    ? (Math.abs(Number(value)) / 1.0e+9).toFixed(0) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(value)) >= 1.0e+6
      ? (Math.abs(Number(value)) / 1.0e+6).toFixed(0) + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(value)) >= 1.0e+3
        ? (Math.abs(Number(value)) / 1.0e+3).toFixed(0) + "K"
        : Math.abs(Number(value));
}

function numFormatter(value: number) {
  return format(value)
}

export default numFormatter;