import React, { Component } from 'react';
import { Form, Button, Ref } from 'semantic-ui-react';

// const options = [
//   { key: 'm', text: 'Male', value: 'male' },
//   { key: 'f', text: 'Female', value: 'female' }
// ];

class FormExampleSubcomponentControl extends Component {
  state = {};

  componentDidMount() {
    const el = this.textAreaRef.querySelector('textarea');
    el.addEventListener('input', FormExampleSubcomponentControl.autosize);
    this.textarea = el;
    // this.setState({ value: this.props.value ? this.props.value : '' })
  }

  componentWillUnmount() {
    this.textarea.removeEventListener('input', FormExampleSubcomponentControl.autosize);
  }

  textAreaRef = null;

  handleRef = (node) => {
    this.textAreaRef = node;
  };

  handleChange = (e, { value }) => this.setState({ value });

  static autosize({ target }) {
    const el = target;
    el.style.cssText = 'height:auto; padding:0';
    el.style.cssText = '-moz-box-sizing:content-box';
    el.style.cssText = 'height:' + el.scrollHeight + 'px';
  }

  markup = () => {
    var divContainer= document.createElement("div");
    divContainer.innerHTML = this.props.value ? this.props.value : '';
    return divContainer.textContent || divContainer.innerText || "";
  }

  render() {
    const { rows, placeholder, handler, openEditField } = this.props;
    const { value } = this.state;
    return (
      <Form inverted>
        <Ref innerRef={this.handleRef}>
          <>
            <Form.TextArea
              defaultValue={this.markup() }
              className="post-textarea"
              rows={rows || '6'}
              placeholder={placeholder}
              onChange={this.handleChange}
            />
          </>
        </Ref>

        <div className="buttons">
          {/* <Button.Group fluid>
            <Form.Button>
              <Icon name="image outline" />
              Photo
            </Form.Button>
            <Form.Button>
              <Icon name="tv" />
              SlideShow
            </Form.Button>
            <Form.Button>
              <Icon name="linkify" />
              Link
            </Form.Button>
          </Button.Group> */}
          <Button.Group fluid>
            <Form.Button
              disabled={!value}
              onClick={() => value
                ? handler(value)
                : console.log('empty textarea', this.state)
              }
              floated="right"
            >
              {openEditField ? 'Update Comment': 'Leave Reply'}
            </Form.Button>
          </Button.Group>
        </div>
      </Form>
    );
  }
}

export default FormExampleSubcomponentControl;
