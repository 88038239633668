import React, { Component, useReducer } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import FroalaEditor from '../util/froalaEditor/froalaEditor';
import { connect } from 'react-redux';
import * as editorActions from '../util/dashboard/EditorActions';
import moment from 'moment';
import Helmet from 'react-helmet';
import {
  Grid,
  Image,
  Button,
  Container,
  Dimmer,
  Loader,
  Icon,
  Input,
  Divider
} from 'semantic-ui-react';
import SideControl from '../yourtrybe/components/SideControl';


class createCompetitions extends Component {
  constructor(props) {
    super(props)
    console.log('props', props);
    const { featuredImageUrl } = props;
    this.state = {
      featuredImagePreviewUrl: featuredImageUrl || null,
      data: {
        title: '',
        content: '',
      }
    }
    this.handleAddingFeaturedImage = this.handleAddingFeaturedImage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      featuredImage, userInfo
    } = this.props;
    console.log('userInfo', userInfo)
    if (prevProps.featuredImage !== featuredImage && featuredImage.id) {
      this.upadteFeaturedImage(featuredImage);
    }
  }


  handleAddingFeaturedImage(file) {
    const { cookies, uploadFeatureImage } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    uploadFeatureImage({ file, token });
  }

  uploadFeaturedImageHandler = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size;
    const fileMath = Math.round((fileSize / 1024));
    if (file && fileMath < 4096) {
      reader.onloadend = () => {
        this.setState({
          // uploadedFeaturedImage: file,
          featuredImagePreviewUrl: reader.result
        });
        this.handleAddingFeaturedImage(file);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Image too Big, please select a Image less than 4MB");
    }
  };

  upadteFeaturedImage(featuredImage) {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        featured_media: featuredImage.id
      }
    });
  }


  handleChangeTitle = (event) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        title: event.target.value
      }
    });
  }

  handleChangeStory = (story) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        content: story
      }
    });
  };

  selectCategory = () => {
    const { featuredImagePreviewUrl, data } = this.state
    const { history } = this.props
    let articleData = {
      imageUrl: featuredImagePreviewUrl,
      content: data.content,
      title: data.title
    }
    localStorage.setItem('boutiesArticle', JSON.stringify(articleData));
    history.push('/competitionsCategory/')
  }

  render() {
    const { featuredImagePreviewUrl, data } = this.state
    const { userInfo, feauturedImageLoading } = this.props
    return (
      <Grid className="promo">
        <Helmet>
          <title>Loop Finance | Community</title>
        </Helmet>
        <Grid className="page-wrapper">
          <SideControl />
          <div className="content-wrapper cz-bountyPageWrap">
            <div className="createCompetitionArticle">
              <Grid.Column className="post-header">
                <div className="post-header__visual">
                  <h3><Input className="title" placeholder="Name your article" fluid autoFocus onChange={this.handleChangeTitle} />
                    <span>Minimum: 10 char.</span></h3>
                </div>
                <div className="post-date">
                  <Divider />
                  <div className="post-author">
                    <div className="img-author">
                      <Image
                        src={userInfo.avatar_urls && userInfo.avatar_urls[96]}
                        avatar
                        circular
                        to="/"
                      />
                    </div>
                    <div className="text-author">
                      <p style={{ marginRight: '5px', color: '#4DD4B6' }}> Published By : {userInfo.name}
                        <span className="dot"> {' . '}</span> <span className="time-txt"> {moment().format('MMMM DD, YYYY')}</span></p>
                    </div>

                    <Button
                      content="Follow"
                      floated="right"
                      size="tiny"
                      disabled
                      className="green-bordered-button"
                    />
                  </div>
                  <Divider />
                </div>
              </Grid.Column>


              {/* separte */}
              <Container className="img-editor">
                <Grid.Row>
                  <Grid.Column>
                    {!featuredImagePreviewUrl ? (
                      <div className="post-featured-image">
                        <div className="meta-data-segment-featured-image">
                          <div className="upload-text-wrapper">
                            <div className="upload-link">
                              <label htmlFor="file-input">
                                <div className="img-up-cz"><Image src="/cz-upp.svg" /></div>
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                accept="image/png, image/jpeg, image/gif"
                                onChange={this.uploadFeaturedImageHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="post-featured-image">
                        <div className="meta-data-segment-featured-image">
                          <Dimmer active={feauturedImageLoading}>
                            <Loader size="massive" indeterminate />
                          </Dimmer>
                          <div className="upload-text-wrapper">
                            <div className="upload-link">
                              <label htmlFor="file-input">
                                <Image
                                  src={featuredImagePreviewUrl}
                                />
                                <Icon name="cloud upload" size="massive" />
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                onChange={this.uploadFeaturedImageHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Container>
              <div className="textEditor">
                <FroalaEditor className="editor_text_box" data={data} handleChangeStory={this.handleChangeStory} />
                <div className="btnWrap">
                  {/* <Link to="/competitionsCategory/"> */}
                  <button class="bountySubmitArticle"
                    onClick={this.selectCategory}>Select Category<img src="../right_arrow.png"></img> </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }


}

export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => ({
        isAdmin: state.user.userInfo.extra_capabilities,
        userInfo: state.user.userInfo,
        uploadingImage: state.image.uploadingImage,
        featuredImage: state.editor.featuredImage,
        feauturedImageLoading: state.editor.feauturedImageLoading,
        cookies: ownProps.cookies
      }),
      dispatch => ({
        uploadFeatureImage: ({ file, token }) => {
          dispatch(editorActions.uploadFeaturedImageRoutine({ file, token }));
        },
      })
    )(createCompetitions)
  )
);