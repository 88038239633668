import { put, all, takeLatest } from 'redux-saga/effects';
import * as actions from './bountyActions';
import * as api from './bountyApi';

export const bountyWatchers = [
  getBountyInfoWatch,
  getBountyListWatcher
];

export function* getBountyInfoWatch() {
  yield takeLatest(actions.getBountyInfo.TRIGGER, getBountyInfo);
}

export function* getBountyListWatcher() {
  yield takeLatest(actions.getBountyList.TRIGGER, getBountyList);
}

function* getBountyInfo({ payload }) {
  const {id} = payload;
  let serverResponse;
  if (parseInt(id)) serverResponse = yield api.getBountyInfo(payload);
  else serverResponse = yield api.getBountyInfoBySlug(payload);

  if (serverResponse.data) {
    Array.isArray(serverResponse.data)
      ? yield put(actions.getBountyInfo.success(serverResponse.data[0]))
      : yield put(actions.getBountyInfo.success(serverResponse.data));
  } else {
    yield put(actions.getBountyInfo.failure(serverResponse));
  }
}


export function* getBountyList({payload}) {
  console.log(payload)
  const serverResponse = yield api.getBountyList();
  if (serverResponse.data) {
    console.log(serverResponse)
    yield put(actions.getBountyList.success(serverResponse.data));
  } else {
    yield put(actions.getBountyList.failure(serverResponse));
  }
}
