import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import Helmet from 'react-helmet';
import {
  Grid,
  Image,
  Card,
} from 'semantic-ui-react';
import SideControl from '../yourtrybe/components/SideControl';
import BountiesArticles from './bountiesArticles'
class bounties extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidUpdate() { }

  render() {
    const { isAuthorized } = this.props
    return (
      <Grid className="promo">
        <Helmet>
          <title>Loop Finance | Bounties</title>
        </Helmet>
        <Grid className="page-wrapper">
          <SideControl />
          <div className="content-wrapper cz-bountyPageWrap">
            <div className="cz-bountyPage">
              {/* {isAuthorized &&
                <div className="bountyHeading">
                  <Link to="/createCompetitions/"><button class="bountySubmitArticle">+ Submit Article</button></Link>
                </div>
              } */}
              <BountiesArticles />
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        isAuthorized: state.user.userIsLogged,
      };
    },
    dispatch => ({
    })
  )(bounties)
);