import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Select, Option } from 'semantic-ui-react';
import Footer from '../../footer/Footer';
import './search.scss';

import { Dropdown } from 'semantic-ui-react'

class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchOptions : [
        {
          key: 'Topic',
          text: 'Topic',
          value: 'Topic',
          //image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
        },
        {
          key: 'User',
          text: 'User',
          value: 'User',
          //image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
        }
      ]
    };
    
  }
  

  
  

  render() {
     return (
      <div>
        <div className="search-input">
            <div className="searchInner">
              <Input placeholder="Search..." />


              <Dropdown placeholder='State' search selection options={this.state.searchOptions} />

               <button className="searchIcon"><i class="fas fa-search"></i> Search</button>
        </div>
        </div>
        
        {/* ========================== Footer ====================== */}
        <Footer></Footer>
        {/* ========================== Footer End ====================== */}

      </div>
    );
  }
}
export default withRouter(Search);
