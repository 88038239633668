import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Accordion, Form, Container, Image, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setPageForGoogleAnalytics } from '../../../util/helperFunctions';
import * as api from './Api';
import Helmet from 'react-helmet';
import SideControl from '../../../yourtrybe/components/SideControl';
import Feed from '../../../util/feed/Feed'
import { Line } from 'react-chartjs-2';
import watchlistIcon from '../assets/watchlist.png'

class TokenCard extends Component {
  render() {
    const { header, body, footer, customClass } = this.props;

    return (
      <Card className={customClass}>
        <Card.Content>
          <div className="headerContent">
            {header}
          </div>
          <Card.Description>
          {body}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
        {footer}
        </Card.Content>
      </Card>
    );
  }
}
export default withRouter((TokenCard)
);
