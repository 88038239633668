import { useEffect, useState } from "react";

import axios from "axios";

async function fetchUserData() {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}platform_menu`,
    method: "get",
    data: {},
  });

  return result.data;
}

export const MenuItems = () => {
  const [menu, setMenu] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const fetchMenu = async () => {
      if (!loaded) {
        await fetchUserData().then((menu) => {
          //console.log(menu)
          setMenu(menu);
          setLoaded(true);
          return;
        });
        setLoaded(true);
      }
    };
    fetchMenu();
  }, [!loaded]);
  return menu;
};
