import axios from 'axios';

const BEARER_TOKEN = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImRhdGEiOnsidXNlcm5hbWUiOiJhdG9tbGF1bmNoIiwiZW1haWwiOiJlcmljQGxpbDJnb29kLmNvbSIsImNyZWF0ZWRfYXQiOiIyMDIxLTA4LTI0IDA3OjU5OjQzIiwidXBkYXRlZF9hdCI6IjIwMjEtMDgtMjQgMDc6NTk6NDMiLCJpZCI6MX0sImlhdCI6MTYyOTgxNzE4M30.4H1VCotSIHHBg2yImuv3DDXDTQkZatkvp2r0rChL1es`

export async function getAcfInfo() {
  const result = await axios
    .get(`https://${process.env.REACT_APP_TRYBE_WP}/wp-json/wp/v2/pages/880696`);
  return result.data?.acf;
}

export async function getAuthors() {
  const result = await axios
    .get(`https://${process.env.REACT_APP_TRYBE_WP}/wp-json/wp/v2/pages/1038451`);
  return result.data?.acf;
}

export async function fetchTokens(pathname, page) {
  const result = await axios
    .get(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v2/tokens${pathname}?page=${page}`,
      { headers: { Authorization: BEARER_TOKEN } });
  return result.data;
}

export async function fetchTokenData(token) {
  const result = await axios
    .get(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v2/token/?symbol=${token}`,
      { headers: { Authorization: BEARER_TOKEN } });
  return result.data;
}

export async function fetchTrendingData() {
  const result = await axios
    .get(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v2/trending`,
      {headers: {Authorization: BEARER_TOKEN}});
  return result.data;
}
