import formActionSaga from 'redux-form-saga';
import { fork, all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { feedWatchers } from '../util/feed/feedSaga.js';
import { bountyFeedWatchers } from '../util/bountyFeed/feedSaga.js';
import { userWatchers } from '../user/userSaga.js';
import { articlesWatchers } from '../yourtrybe/articles/articlesSaga.js';
import { postWatchers } from '../post/postSaga';
import { editorWatchers } from '../util/dashboard/EditorSaga';
import { uploadImageWatchers } from '../util/articleEditor/imageAdd/ImageAddSaga';
import { socialFeedWatchers } from '../yourtrybe/social/SocialFeedSaga';
import { followingWatchers } from '../yourtrybe/following/FollowingSaga';
import { userReviewWatchers } from '../user-review/userReviewSaga';
import { notificationsWatchers } from '../sidebar/notifications/NotificationsSaga';
import { friendsWatchers } from '../yourtrybe/friends/FriendsSaga';
import { chatWatchers } from '../chat/ChatSaga';
import { signUpWatchers } from '../sign-up/signUpSaga';
import {settingsWatchers} from '../yourtrybe/settings/settingsSaga';
import { bountyWatchers } from '../bounties/bountySaga';

import { homeWatches } from '../pages/home/actions/saga';
import { marketWatches } from '../pages/markets/actions/saga';

export default function* root() {
  const allWatchers = [
    ...settingsWatchers,
    ...feedWatchers,
    ...bountyFeedWatchers,
    ...userWatchers,
    ...postWatchers,
    ...editorWatchers,
    ...uploadImageWatchers,
    ...articlesWatchers,
    ...socialFeedWatchers,
    ...userReviewWatchers,
    ...followingWatchers,
    ...friendsWatchers,
    ...notificationsWatchers,
    ...chatWatchers,
    ...signUpWatchers,
    ...bountyWatchers,
    ...homeWatches,
    ...marketWatches,
    routinePromiseWatcherSaga,
    formActionSaga
  ];

  yield all(allWatchers.map(saga => fork(saga)));
}
