import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Container, Button } from "semantic-ui-react";
import ticketing from "../../../../../staticAssets/images/home/ticketing.png";
import digital from "../../../../../staticAssets/images/home/digital.png";
import metaverse from "../../../../../staticAssets/images/home/metaverse.png";

class CTASection extends Component {
  render() {
    return (
      <>
        <div className="section section__cta">
          <Container>
            <h3 className="heading_h3 heading_h3__small">
              We help <span>Brands</span> level up <br className="break" />
              fan <span>Engagement</span> with <span>NFTs</span>
            </h3>
            <div className="services">
              <div className="service_item">
                <Image className="serviceImage" src={ticketing} />
                <div className="content">
                  <h4>NFT Ticketing</h4>
                  <p>Faster and Secure NFT Ticketing Solution with Zero Fraud</p>
                </div>
                <a hidden>Learn More</a>
              </div>
              <div className="service_item">
                <Image className="serviceImage" src={digital} />
                <div className="content">
                  <h4>Distribute Digital Swag</h4>
                  <p>Create NFTs that are Redeemable for Physical Assets</p>
                </div>
                <a hidden>Learm More</a>
              </div>
              <div className="service_item">
                <Image className="serviceImage" src={metaverse} />
                <div className="content">
                  <h4>Metaverse NFTs</h4>
                  <p>We Bring Digital Assets to the Metaverse with NFTs</p>
                </div>
                <a hidden>Learm More</a>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(CTASection);
