import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import * as categoriesActions from '../yourtrybe/articles/articlesActions';
import { connect } from 'react-redux';
import {
  Grid
} from 'semantic-ui-react';
import MetaData from '../util/dashboard/metadata/MetaData';
import SideControl from '../yourtrybe/components/SideControl';
import Helmet from 'react-helmet';
class competitionCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        categories: [],
      },

    }
    this.deleteCategory = this.deleteCategory.bind(this);
    this.addCategory = this.addCategory.bind(this);
  }

  componentDidMount() {
    const { data } = this.props
    console.log('state data:- ', data);
    const { getCategories } = this.props;
    getCategories();
    console.log('called categories')
  }

  addCategory(category) {
    const { data } = this.state;
    this.setState(
      {
        data: {
          ...data,
          categories: [category.id]
        },
        selectedCat: category.name,
      },
    );
  }

  deleteCategory(category) {
    const { data } = this.state;
    this.setState(
      {
        data: {
          ...data,
          categories: data.categories.filter(id => category.id !== id)
        }
      },
      () => console.log(this.state)
    );
  }

  render() {
    const { categories, history } = this.props;
    return (

      <Grid className="promo">
        <Helmet>
          <title>Loop Finance | Community</title>
        </Helmet>
        <Grid className="page-wrapper">
          <SideControl />
          <div className="content-wrapper cz-bountyPageWrap">

            <div className="competitionArticle createCompetitionArticle ui grid cz-new-js">
              <div className="approveHeading">
                <span onClick={() => history.push('/createCompetitions/')}><img src="../wt-back-arrow.png" /> Back to Article Editing</span>
                <h1><span>Please</span> Select Category</h1>
              </div>
              <Grid.Row>
                <Grid.Column>
                  <div className="post-categories-n-tags">
                    <MetaData
                      deleteCategory={this.deleteCategory}
                      addCategory={this.addCategory}
                      categories={categories && categories}
                      chosenCategories={categories.map(id => ({
                        id
                      }))}
                    />
                  </div>
                </Grid.Column>

                <Grid.Column>
                  <div className="post-question-n-tags">
                    <div class="btnWrap">
                      <a href="/previewApprove/">
                        <button class="bountySubmitArticle">Bounty or competition?</button>
                      </a>
                    </div>
                  </div>
                </Grid.Column>



              </Grid.Row>
              <div class="btnWrap">
                <Link to="/previewApprove/"><button class="bountySubmitArticle">Approve<img src="../right_arrow.png"></img></button></Link>
              </div>
            </div>

          </div>
        </Grid>
      </Grid>
    )
  }

}
export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => ({
        isAdmin: state.user.userInfo.extra_capabilities,
        userInfo: state.user.userInfo,
        categories: state.articles.categories,
        categoriesAreLoading: state.articles.categoriesAreLoading,
        cookies: ownProps.cookies
      }),
      dispatch => ({
        getCategories: (data) => {
          dispatch(categoriesActions.getAllCategories(data));
        },

      })
    )(competitionCategory)
  )
);