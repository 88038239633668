/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, ReactNode, useEffect, useState } from "react";
import { List, Grid, Menu, Segment, Sidebar, Image } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import * as actions from "./SidebarActions";
import { returnToLoginMode, userSignOut } from "../user/userActions";
import { changeTheme } from "../themes";
import LoginModal from "../login/LoginModal";
import PlatformMenuMobile from "../components/PlatformMenu/mobile";

const SidebarCustom = ({
  children,
  sidebar,
  closeSidebar,
  visible,
  isAuthorized,
  address,
  logout,
}: any) => {
  const [lightTheme, setLightTheme] = useState(false);
  const [isStarsOpen, setStarsOpen] = useState(false);
  const [isCollectablesOpen, setCollectablesOpen] = useState(false);
  const pageName = window.location.pathname;

  useEffect(() => {
    const theme = window.localStorage.getItem("trybe_theme")
      ? window.localStorage.getItem("trybe_theme")
      : "light";
    if (theme === "light") {
      setLightTheme(true);
    } else {
      setLightTheme(false);
    }
  });

  /* signOut = () => {
    const { cookies, closeSidebar, signOut, userInfo } = this.props;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "logout",
      event_category: "engagement",
      event_action: "logout",
      userId: userInfo.id,
    });
    localStorage.clear();
    cookies.remove("trybe_jwt", { path: "/", domain: ".loop.markets" });
    signOut();
    closeSidebar();
  }; */

  const menuItems = {
    stars: [
      {
        name: "LoopFans para Creadores",
        link: "/",
        active: true,
      },
      {
        name: "Salud, Fitness y Bienstar",
        link: "/fitness",
        active: true,
      },
      {
        name: "Influenciadores",
        link: "/influencer",
        active: true,
      },
      {
        name: "Deportistas y Atletas",
        link: "/sports",
        active: true,
      },
      {
        name: "Músicos",
        link: "/music",
        active: true,
      },
      /* {
        name: "Content Creators",
        link: "/creators",
        active: false,
      }, */
    ],
    types: [
      {
        name: "Todos los Tipos",
        link: "/",
        active: true,
      },
      {
        name: "Concertos y Tickets para Eventos",
        link: "/events",
        active: true,
      },
      {
        name: "Membresias Privadas",
        link: "/private-memberships",
        active: true,
      },
      {
        name: "Medios Digitales",
        link: "/digital",
        active: true,
      },
      /* {
        name: "Giveaways",
        link: "/giveaways",
        active: false,
      },
      {
        name: "Merchndise",
        link: "/",
        active: false,
      },
      {
        name: "Metaverse experience",
        link: "/metaverse",
        active: false,
      },
      {
        name: "Tokens and Crypto",
        link: "/crypto",
        active: false,
      }, */
    ],
  };

  return (
    <div
      className={
        visible
          ? "sidebarVisible sidebar-wrapper cz-mobile-menu cz-mobile-menu--blur"
          : "sidebarVisible sidebar-wrapper cz-mobile-menu"
      }
    >
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          vertical
          direction="right"
          visible={visible}
          width="wide"
        >
          <Segment inverted className="sidebar-main">
            <Segment inverted className="controls-panel-wrapper">
              <Grid.Row>
                {/* <span className="menuText">Menu</span> */}
                <img src="/img/loop_logo.png" alt="" className="sideLogo" />
                <p className="closeBtn" onClick={closeSidebar}>
                  <img src="/menu-close.svg" alt="close" />
                </p>
              </Grid.Row>
              <Grid.Row>
                <List link inverted>
                  <PlatformMenuMobile />
                </List>
              </Grid.Row>
            </Segment>
          </Segment>
        </Sidebar>

        <Sidebar.Pusher
          onClick={() => {
            if (visible) closeSidebar();
          }}
          dimmed={visible}
        >
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.sidebar.visible,
});

export default withRouter(
  connect(mapStateToProps, (dispatch) => ({
    closeSidebar: () => {
      dispatch(actions.closeSidebar());
    },
    signOut: () => {
      dispatch(userSignOut());
    },
    resetModal: () => {
      dispatch(returnToLoginMode());
    },
  }))(SidebarCustom)
);
