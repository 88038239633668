import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import moment from 'moment';
import { Container, Grid, Input, Image, Dimmer, Loader, Button, Message, Modal, Icon, Divider, Accordion, Checkbox, Fragment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import he from 'he';
import * as editorActions from '../../util/dashboard/EditorActions';
import * as categoriesActions from '../../yourtrybe/articles/articlesActions';
import MetaData from '../../util/dashboard/metadata/MetaData';
//import FroalaEditor from '../../util/froalaEditor/froalaEditor';
import TinyMceEditor from '../../util/tinymceEditor/tinymceEditor';

import trybeIcon from '../../svg/trybeicon.svg';
//import { setPageForGoogleAnalytics } from '../../util/helperFunctions';
import placeholder from '../../../staticAssets/images/article_placeholder.png';
import SideControl from '../../yourtrybe/components/SideControl';
import ReCAPTCHA from 'react-google-recaptcha';
import * as api from '../postApi'
import IdleTimer from 'react-idle-timer'
import ImageEditor from './imageEditor';

class VisualEditor extends Component {
  constructor(props) {
    super(props);
    const { featuredImageUrl } = props;
    this.state = {
      featuredImagePreviewUrl: featuredImageUrl || null,
      showCat: false,
      selectedCat: '',
      data: {
        title: '',
        content: '',
        categories: [],
        bounties: [],
        show: false,
        publishRule: false,
        articlerecaptcha: false,
        fields: {
          post_subtitle: '',
          canonical_link: '',
        }
      },
      selectedBounty: props.selectedBounty ? props.selectedBounty : [],
      isCanonicalLink: false
    };
    this.deleteCategory = this.deleteCategory.bind(this);
    this.handleAddingFeaturedImage = this.handleAddingFeaturedImage.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.addBounty = this.addBounty.bind(this);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  async componentDidMount() {
    //setPageForGoogleAnalytics('VisualEditor');
    const { getCategories, getBounties } = this.props;
    await getCategories();
    await getBounties();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      featuredImage,
      articleIsCreated,
      articleOnCreatedAction,
      history,
      articleData
    } = this.props;
    const { selectedCat, showCat } = this.state;

    if (prevState.selectedCat !== selectedCat && showCat) {
      this.setState({ showCat: false });
    }

    if (prevProps.featuredImage !== featuredImage && featuredImage.id) {
      this.upadteFeaturedImage(featuredImage);
    }

    if (articleIsCreated && articleData) {
      history.push(`/post/editor/edit/${articleData.id}/`);
      articleOnCreatedAction();
    }
  }

  handleOnIdle() {
    this.createAutoSaveArticle()
  }

  upadteFeaturedImage(featuredImage) {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        featured_media: featuredImage.id
      }
    });
  }

  handleAddingFeaturedImage(file) {
    const { cookies, uploadFeatureImage } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    uploadFeatureImage({ file, token });
  }

  uploadFeaturedImageHandler = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size;
    const fileMath = Math.round((fileSize / 1024));
    if (file && fileMath < 4096) {
      reader.onloadend = () => {
        this.setState({
          // uploadedFeaturedImage: file,
          featuredImagePreviewUrl: reader.result
        });
        this.handleAddingFeaturedImage(file);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Image too Big, please select a Image less than 4MB");
    }
  };

  previewArticle(data) {
    const { categories, userInfo } = this.props
    const endData = JSON.parse(JSON.stringify(data));
    endData.featuredImage = this.state.featuredImagePreviewUrl ? this.state.featuredImagePreviewUrl : '';
    endData.userName = userInfo.name;
    endData.userAvtar = userInfo.avatar_urls && userInfo.avatar_urls[96];
    endData.selectedCat = categories.filter(x => x.id == endData.categories[0]);
    this.props.prevArticle(endData);
    setTimeout(() => {
      window.open('/preview/', '_blank');
    }, 500);
  }

  createArticle(data, status) {
    const { createArticle, cookies } = this.props;
    const endData = JSON.parse(JSON.stringify(data));
    endData.status = status;
    const { data, selectedCat, articlerecaptcha, isCanonicalLink } = this.state;
    const { feauturedImageLoading, postData, userInfo } = this.props;
    if (!isCanonicalLink) {
      endData?.fields?.canonical_link = '';
    }
    if (endData?.fields?.canonical_link && !this.checkValidUrlLink(endData.fields.canonical_link)) {
      alert("Please Enter a valid canonical url.")
      return false
    }
    if (status == 'publish') {
      if ((data.title.length < 10) || !articlerecaptcha || (data.content.length < 300) || !placeholder || !selectedCat || (feauturedImageLoading == undefined || feauturedImageLoading)) {
        this.setState({ publishRule: true })
        setTimeout(() => {
          this.setState({ publishRule: false })
        }, 2500);
      } else {
        this.showModal()
        createArticle({
          data: endData,
          token: cookies.get('trybe_jwt', { path: '/' })
        });
      }

    } else {
      if ((data.title.length < 10) || !selectedCat) {
        this.setState({ draftRule: true })
        setTimeout(() => {
          this.setState({ draftRule: false })
        }, 2500)
      } else {
        this.showModal()
        createArticle({
          data: endData,
          token: cookies.get('trybe_jwt', { path: '/' })
        });
      }
    }
  }

  handleChangeTitle = (event) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        title: event.target.value
      }
    });
  }

  handleChangeSubTitle = (event) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        fields: {
          post_subtitle: event.target.value
        }
      }
    });
  }

  handleChangeCanonicalLink = (event) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        fields: {
          canonical_link: event.target.value
        }
      }
    });
  }

  checkValidUrlLink(value) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(value);
  }

  showModal = () => {
    this.setState({ show: true });
    setTimeout(() => {
      this.hideModal()
    }, 5000)
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  renderTokens(centered) {
    const { selectedCat } = this.state;
    //console.log(selectedCat);
    const style = {
      justifyContent: centered ? 'center' : 'flex-end',
      margin: centered ? '0 auto' : 0
    };
    return (
      <div className="tokens" style={style}>
        0
        <Image src={trybeIcon} />
      </div>
    );
  }

  handleChangeStory = (story) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        content: story
      }
    });
    //console.log(data.content.length);
  };


  handleRecaptcha = () => {
    const { articlerecaptcha } = this.state;
    this.setState({ articlerecaptcha: !articlerecaptcha })
  }

  addCategory(category) {
    const { data } = this.state;
    this.setState(
      {
        data: {
          ...data,
          categories: [category.id],
          bounty: [10102]
        },
        selectedCat: category.name,
      },
    );
  }

  addBounty(bounty) {
    const { data } = this.state;
    this.setState(
      {
        data: {
          ...data,
          bounty: [bounty.id]
        },
        selectedBounty: bounty.name,
      },
    );
  }

  deleteCategory(category) {
    const { data } = this.state;
    this.setState(
      {
        data: {
          ...data,
          categories: data.categories.filter(id => category.id !== id)
        },
        selectedCat: ""
      },
      () => console.log(this.state)
    );
  }

  handleCatToggle() {
    const { showCat } = this.state;
    this.setState({ showCat: showCat ? false : true });
  }

  setCheckboxValue = (bounty) => {
    const { selectedBounty } = this.state;
    return (selectedBounty == bounty.name);
  };

  changeCheckboxValue = (bounty) => {
    this.setState({ selectedBounty: bounty.id });
    this.addBounty(bounty);
  }

  changeIsCanonicalLinkValue = (value) => {
    this.setState({ isCanonicalLink: value })
  }

  createAutoSaveArticle = () => {
    const { articleData } = this.props;
    const { data } = this.state;
    data.is_autosave = true
    if (Object.keys(articleData).length === 0 && !articleData?.id) {
      this.createArticle(data, 'draft');
    }
  }

  render() {
    const { data, featuredImagePreviewUrl, showCat, selectedCat, selectedBounty, show, publishRule, draftRule } = this.state;
    const { userInfo, feauturedImageLoading, categories, bounties, postData, sendingArticle, history, articleIsCreated, isError, errorMessage, clearError, articleData } = this.props;

    const minutes = 0;
    //Time To Read === Start
    if (data && data.content) {
      const WPS = 275 / 60;
      var images = 0
      const regex = /\w/;
      var imageAdjust = images * 4;
      var imageSecs = 0;
      var imageFactor = 12;
      let words = data.content.split(' ').filter((word) => {
        if (word.includes('<img')) {
          images += 1
        }
        return regex.test(word)
      }).length;
      while (images) {
        imageSecs += imageFactor
        if (imageFactor > 3) {
          imageFactor -= 1
        }
        images -= 1
      }
      minutes = Math.ceil(((words - imageAdjust) / WPS + imageSecs) / 60);
    }

    return (
      <Grid className="page-wrapper cz-new-edit">
        <SideControl />
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={10000}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
        <Grid className="cz-new-js">
          <Container>
            <Grid>
              {isError && (
                <div className={isError ? "modal display-block modal-delete" : "modal display-none modal-delete"}>
                  <section className="modal-main">
                    <h5>Error!</h5>
                    <br />
                    {errorMessage && (
                      <div>
                        <p style={{ color: 'var(--red)' }}>{errorMessage?.message}</p>
                        <Divider />
                        <small>If you continue to have this error please backup your content and contact us on telegram. | <a href="https://t.me/loopfinance" target="_blank">https://t.me/loopfinance</a></small>
                        <Divider />
                        <Button variant="secondary" onClick={() => clearError()}>
                          Close and Try again
                        </Button>
                      </div>
                    )}
                  </section>
                </div>
              )}
            </Grid>
          </Container>
          {!sendingArticle && data && (
            <React.Fragment>
              <Container>
                <Grid className="articlePoints">
                  <ul>
                    <li><h5 style={{ color: 'yellow' }}>Article Rules: Please Read Carefully</h5></li>
                    <li>
                      You are limited to 1 Article per day unless you're an official author
                    </li>
                    <li>
                      If you are found posting plagiarised content on our platform your account may be suspended without warning
                    </li>
                    <li>
                      Articles must be more than 300 words or contain a video or other useful content (eg. infographics)
                    </li>
                    <li>
                      Articles must be related to cryptocurrencies or blockchain technology
                    </li>
                    <li>
                      Articles should be carefully edited and include a title, nice cover image, and category
                    </li>
                    <li>
                      Articles must be your own work and not copied from somewhere else
                    </li>
                  </ul>
                </Grid>
              </Container>
              {/*
              <Grid.Row className="editImage">
                <Modal
                  size="fullscreen"
                  trigger={<Button className="post-category"><small>New*</small> Image Editor</Button>}
                  actions={['Close']}
                >
                  <Modal.Content scrolling>
                    <ImageEditor />
                  </Modal.Content>
                </Modal>
              </Grid.Row>
            */}
            </React.Fragment>
          )}
          <Container>
            <Grid.Row>
              <Grid.Column>
                <Grid className="my-grid">
                  <Grid.Row>
                    <Grid.Column width="16" className="cz-write-message">
                      <div className="post-header post-header__visual">
                        <h3><Input className="title" placeholder="Name your article" fluid autoFocus onChange={this.handleChangeTitle} /></h3>
                      </div>
                      <div className="post-header post-header__visual">
                        <h4><Input className="title" placeholder="Enter article subtitle here" fluid onChange={this.handleChangeSubTitle} /></h4>
                      </div>
                      <Grid.Row>
                        <Grid.Column>
                          <div className="post-categories-n-tags">
                            <div className="post-categories">
                              <Button className="post-category" onClick={() => { this.handleCatToggle(); }}>
                                {!showCat ? selectedCat ? selectedCat : 'Choose a Category' : 'Close Categories'}
                              </Button>
                            </div>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <div className="post-date">
                        <div className="post-author">
                          <b style={{ marginRight: '5px', color: '#4DD4B6' }}>
                            <Image
                              src={userInfo.avatar_urls && userInfo.avatar_urls[96]}
                              avatar
                              circular
                              to="/"
                            />
                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bolder' }}>Published By : {userInfo.name}</span>
                          </b>
                          {' | '}
                          <span className="lightShade">{moment().format('MMMM DD, YYYY')}</span>
                          {/* Total Read Time */}
                          <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-readtime.svg" alt="" />
                          <span className="lightShade">{minutes} min</span>
                          {/* Number of Comments */}
                          <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-comment.svg" alt="" />
                          <span className="lightShade">0</span>
                          <Button
                            content="Follow"
                            floated="right"
                            size="tiny"
                            disabled
                            className="green-bordered-button"
                          />
                        </div>
                      </div>
                      <div className="post-date cz-post-date-onmobi">
                        <Divider />
                        <div className="post-author">
                          <b style={{ marginRight: '5px', color: '#4DD4B6' }}>
                            <Image
                              src={userInfo.avatar_urls && userInfo.avatar_urls[96]}
                              avatar
                              circular
                              to="/"
                            />
                            <span style={{ marginLeft: '0.5rem' }}>Published By : {userInfo.name}</span>
                          </b>
                          <i>{moment().format('MMMM DD, YYYY')}</i>
                          <Button
                            content="Follow"
                            floated="right"
                            size="tiny"
                            disabled
                            className="green-bordered-button"
                          />
                        </div>
                        <Divider />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  {showCat && (
                    <div className="competitionArticle createCompetitionArticle ui grid cz-new-js">
                      <Grid.Row style={{ backgroundColor: 'rgb(19 19 19)', borderRadius: 0 }}>
                        <Grid.Column>
                          <div className="approveHeading">
                            <h5 style={{ color: '#fff' }}><span>Select</span> a Category</h5>
                          </div>
                          <div className="post-categories-n-tags">
                            {categories && (
                              <MetaData
                                deleteCategory={this.deleteCategory}
                                addCategory={this.addCategory}
                                categories={categories && categories}
                                chosenCategories={[{id: data?.categories[0] || null}]}                              />
                            )}
                          </div>
                        </Grid.Column>
                        {/*
                          <Grid.Column>
                          <div className="approveHeading">
                            <h5 style={{color: '#fff'}}><span>Enter</span> a Bounty? <small>(Optional)</small></h5>
                          </div>
                            <div className="post-categories-n-tags">
                              {bounties && (
                                bounties.map((bounty, index) => {
                                  return (
                                    <Accordion>
                                      <Accordion.Title
                                        //active={activeIndex === index}
                                        index={index}
                                        className={`accordion-wrapper`}
                                      >
                                      <Checkbox
                                        className="accordion-checkbox"
                                        label={he.decode(bounty.name)}
                                        checked={this.setCheckboxValue(bounty)}
                                        onChange={() => this.changeCheckboxValue(bounty)}
                                      />
                                    </Accordion.Title>
                                  </Accordion>
                                  );
                                })
                              )}
                            </div>
                          </Grid.Column>
                        */}
                      </Grid.Row>
                    </div>
                  )}
                  {!featuredImagePreviewUrl ? (
                    <div className="post__visual-featured-image">
                      <div className="meta-data-segment-featured-image">
                        <div className="upload-text-wrapper">
                          <div className="upload-link">
                            <label htmlFor="file-input">
                              <div className="img-up-cz"><Image src="/cz-upp.svg" /></div>
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              accept="image/png, image/jpeg, image/gif"
                              onChange={this.uploadFeaturedImageHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="post-featured-image">
                      <div className="meta-data-segment-featured-image">
                        <Dimmer active={feauturedImageLoading}>
                          <Loader size="massive" indeterminate />
                        </Dimmer>
                        <div className="upload-text-wrapper">
                          <div className="upload-link">
                            <label htmlFor="file-input">
                              <Image
                                src={featuredImagePreviewUrl}
                              />
                              <Icon name="cloud upload" size="massive" />
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              onChange={this.uploadFeaturedImageHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Container>
                    <Grid.Row className="editor">
                      <Grid.Column>
                        <TinyMceEditor className="editor_text_box" data={data} height="3000" handleChangeStory={this.handleChangeStory} />

                        <div className="canonical-checkBox">
                          <Checkbox
                            label={"Has this been published anywhere else?"}
                            checked={this.state.isCanonicalLink}
                            onChange={(e, data) => this.changeIsCanonicalLinkValue(data.checked)}
                          />
                        </div>
                        {
                          this.state.isCanonicalLink && (<Grid.Row className="editor_canonical">
                            <Grid.Column width="16" className="cz-write-message">
                              <div className="post-header post-header__visual">
                                <h4><Input className="title" placeholder="Please paste the URL to the original article" fluid onChange={this.handleChangeCanonicalLink} /></h4>
                              </div>
                            </Grid.Column>
                          </Grid.Row>)
                        }


                        {/* <span>Min Character: 300/{data.content.length}</span> */}
                        <div className="actions btn-wrap">
                          <ReCAPTCHA className="captcha_box"
                            theme="dark"
                            ref={e => (this.captcha = e)}
                            sitekey="6LfMp8gbAAAAAONc0qQfY6MfmXLPbCLkjd5kfaNJ"
                            onChange={() => this.handleRecaptcha()}
                          />
                          <div className="triger-wrap">
                            <div className="triger-conditions">
                              <div className={draftRule ? 'submitConditionTooltip' : 'hideTooltip'}>
                                {(data.title.length < 10) &&
                                  <p>Title is too short</p>
                                }
                                {!selectedCat &&
                                  <p>Category is not selected</p>
                                }
                              </div>
                              <button
                                className="publish-button right-btn saveArticle save-article"
                                //disabled={data.title.length < 10 && !selectedCat}
                                onClick={() => data.status == 'pending' ? this.createArticle(data, 'pending') : this.createArticle(data, 'draft')}
                              >Draft
                              </button>
                            </div>
                            <div className="triger-conditions">
                              <div className={publishRule ? 'submitConditionTooltip' : 'hideTooltip'}>
                                {(data.title.length < 10) &&
                                  <p>Title is too short</p>
                                }
                                {(data.content.length < 300) &&
                                  <p>The text is less than 300 words</p>
                                }
                                {!selectedCat &&
                                  <p>Category is not selected</p>
                                }
                                {(feauturedImageLoading == undefined || feauturedImageLoading) &&
                                  <p>Cover Image is required</p>
                                }
                              </div>
                              <button
                                //disabled={(data.title.length < 10) || (data.content.length < 300) || (postData && postData.status == 'pending') || !placeholder || !selectedCat || (feauturedImageLoading == undefined || feauturedImageLoading)}
                                className="publish-button right-btn submitArticle publish-article"
                                onClick={() => this.previewArticle(data)}
                              >Preview
                              </button>
                              <button
                                // disabled={(data.title.length < 10) || (data.content.length < 300) || (postData && postData.status == 'pending') || !placeholder || !selectedCat || (feauturedImageLoading == undefined || feauturedImageLoading)}
                                className="publish-button right-btn submitArticle publish-article"
                                onClick={() => this.createArticle(data, 'publish')}
                              >Publish
                              </button>
                            </div>
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Container>

                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Container>
          <div className={show ? "modal display-block modal-delete" : "modal display-none modal-delete"}>
            <section className="modal-main">
              <span onClick={() => this.hideModal()}>+</span>
              <p>{articleData?.is_autosave ? 'Please wait while we save your work...' : 'This post may take up to 15 minutes to appear in the news feed due to server caching. Please be patient!'}</p>
            </section>
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => ({
        isAdmin: state.user.userInfo.extra_capabilities,
        userInfo: state.user.userInfo,
        categories: state.articles.categories,
        categoriesAreLoading: state.articles.categoriesAreLoading,
        bounties: state.articles.bounties,
        bountiesAreLoading: state.articles.bountiesAreLoading,
        articleIsCreated: state.editor.articleIsCreated,
        sendingArticle: state.editor.sendingArticle,
        uploadingImage: state.image.uploadingImage,
        featuredImage: state.editor.featuredImage,
        feauturedImageLoading: state.editor.feauturedImageLoading,
        errorMessage: state.editor.errorMessage,
        isError: state.editor.isError,
        articleData: state.editor.articleData,
        cookies: ownProps.cookies
      }),
      dispatch => ({
        createArticle: (data) => {
          dispatch(editorActions.createArticleRoutine(data));
        },
        getCategories: (data) => {
          dispatch(categoriesActions.getAllCategories(data));
        },
        getBounties: (data) => {
          dispatch(categoriesActions.getAllBounties(data));
        },
        uploadFeatureImage: ({ file, token }) => {
          dispatch(editorActions.uploadFeaturedImageRoutine({ file, token }));
        },
        articleOnCreatedAction: () => {
          dispatch(editorActions.articleIsCreatedRoutine());
        },
        clearError: () => {
          dispatch(editorActions.clearErrorRoutine());
        },
        prevArticle: (data) => {
          dispatch(editorActions.previewArticleRoutine(data));
        }
      })
    )(VisualEditor)
  )
);