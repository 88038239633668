import { put, takeLatest } from 'redux-saga/effects';
import * as feedActions from './feedActions';
import { getPostsCall, getFeedByRole, getRatingsCall } from './feedApi';

export const feedWatchers = [getPostsWatch, getRatingsWatch];

export function* getPostsWatch() {
  yield takeLatest(feedActions.getPostsRoutine.TRIGGER, getPosts);
}
export function* getRatingsWatch() {
  yield takeLatest(feedActions.getRatingsRoutine.TRIGGER, getRatings);
}

function* getPosts({payload}) {
  if (payload.type == 'Latest') {
    const serverResponse = yield getFeedByRole({payload});
    if (serverResponse && serverResponse.status === 200) {
      yield put(feedActions.getPostsRoutine.success(serverResponse.data));
    } else {
      yield put(feedActions.getPostsRoutine.failure(serverResponse));
    }
  }else{
    const serverResponse = yield getPostsCall({payload});
    if (serverResponse && serverResponse.status === 200) {
      yield put(feedActions.getPostsRoutine.success(serverResponse.data));
    } else {
      yield put(feedActions.getPostsRoutine.failure(serverResponse));
    }
  }
}
function* getRatings(payload) {
  const serverResponse = yield getRatingsCall(payload);
  console.log(serverResponse)
  if (serverResponse && serverResponse.status === 200) {
    yield put(feedActions.getRatingsRoutine.success(serverResponse.data));
  } else {
    yield put(feedActions.getRatingsRoutine.failure(serverResponse));
  }
}
