import React, { useEffect, useRef, useState } from "react";
import styles from "./platformmenu.module.scss";
import { MenuItems } from "./menu";

const PlatformMenu: React.FC = () => {
  const containerRef = useRef(null);
  const [isShown, setIsShown] = useState(false);
  const menuItems = MenuItems();

  const handleScroll = () => {
    const container = containerRef.current;

    if (container) {
      const scrollPercentage =
        (container.scrollTop /
          (container.scrollHeight - container.clientHeight)) *
        100;

      // Update the custom scrollbar thumb position
      const thumb = document.getElementById("ecosystem-scrollbar");
      if (thumb) {
        thumb.style.top = `${Math.round(scrollPercentage) * 1.3}%`;
      }
    }
  };

  return (
    <div className="loginStyleHeader">
      <div className="handleDivide">
        <button
          className={styles.platformMenuButton}
          onClick={() => setIsShown(!isShown)}
          style={{
            opacity: isShown ? "0" : "1",
            transition: "all 350ms",
            visibility: isShown ? "hidden" : "visible",
            transform: isShown ? "scale(0)" : "scale(1)",
            transformOrigin: "top right",
          }}
        >
          <img src="platform/platform_menu.svg" style={{ marginRight: "5px" }} />
        </button>
      </div>

      <div
        className={styles.platformMenuModal}
        onScroll={handleScroll}
        ref={containerRef}
        onMouseLeave={() => setIsShown(false)}
        style={{
          opacity: !isShown ? "0" : "1",
          transition: "all 350ms",
          visibility: !isShown ? "hidden" : "visible",
          transform: !isShown ? "scale(0)" : "scale(1)",
          transformOrigin: "top right",
        }}
      >
        <h3>LOOP ECOSYSTEM</h3>

        <div className={styles.platformItems}>
          {menuItems?.map((i: any) => {
            return (
              <div className={styles.item} hidden={i.item_type != "primary"}>
                <p>{i.title}</p>

                <a href={i.link} target="_blank" className={styles.link}>
                  <button>
                    <img className={styles.rocket} src="/platform/rocket.svg" />
                    <img
                      src={
                        process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS + i.icon
                      }
                    />
                    <p>Launch</p>
                  </button>
                </a>
              </div>
            );
          })}
          <div className={styles.divider} />
          <div className={styles.links}>
            {menuItems?.map((i: any) => {
              return (
                <a
                  href={i.link}
                  target="_blank"
                  hidden={i.item_type != "secondary"}
                >
                  {i.title}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformMenu;
