import React, { Component } from 'react';
import { PhotoEditorSDK } from '../../../photoeditorsdk';
// import CreativeEditorSDK from '../../../CreativeEditorSDK';

class ImageEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
    };
  }


  componentDidMount() {
  }

  componentWillUnmount() { }
  render() {
    const config = {
      theme: 'dark',
      assetBaseUrl: '../../../../creativeassets/',
      ui: {
        elements: {
          templates: true,
          navigation: {
            position: 'top',
            action: {
              close: false,
              back: false,
              load: false,
              save: false,
              export: false,
              download: false
            }
          },
          panels: {
            inspector: {
              show: true,
              position: 'right'
            },
            settings: {
              show: true
            }
          },
          libraries: {
            image: true,
            text: true,
            element: true
          },
          blocks: {
            opacity: true,
            transform: true,
            adjustments: true,
            filters: true,
            effects: true,
            blur: true,
            crop: true
          }
        }
      }
    };
    return (
      <PhotoEditorSDK />
      // <CreativeEditorSDK config={config} />
    )
  }
}

export default ImageEditor