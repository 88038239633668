import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Image, Table, Loader, Button, Popup, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as api from '../../home/Api';
import * as total from '../../Api';
import numFormatter from '../../controllers/numFormatter'

import Helmet from 'react-helmet';
import MarketPagination from './marketPagination';
import MarketTable from './marketTable';
import watchlistIcon from '../../assets/watchlist.png'
import portfolioIcon from '../../assets/portfolio.png';
import sendIcon from '../../assets/send-icon.png';
import receiveIcon from '../../assets/receive-icon.png';
import swapIcon from '../../assets/swap-icon.png';
import addwlIcon from '../../assets/addwl-icon.png';

interface IProps {
  marketData?: any;
}

interface IState {
  tokenData?: any;
  trendingData?: any;
  hideForm: boolean;
  show: boolean;
  tokenName: string;
  tableHover: boolean;
  tableIndex: number;
  page: string;
}

class TokensPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { tableIndex: 0, tableHover: false, tokenData: null, hideForm: false, show: false, tokenName: '', page: '/' };
    this.hideModal = this.hideModal.bind(this);
  }

  async componentDidMount() {
    const { tokenData } = this.state;
    const page = new URLSearchParams(window.location.search).get('page');
    const pathname = window.location.pathname.split('/')[2];
    this.setState({page: pathname})
    await api.fetchTokens(pathname, page ? page : 1).then(tokenData => this.setState({ tokenData }));
    await api.fetchTrendingData().then(trendingData => this.setState({ trendingData }));
  }

  async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    const pathname = window.location.pathname.split('/')[2];
    const {page, trendingData} = this.state;
    if (prevState.page != page) {
      await api.fetchTokens(pathname, page ? page : 1).then(tokenData => this.setState({ tokenData }));
    }
  }

  showModal = (name) => {
    this.setState({ show: true, tokenName: name });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  clearTokenData = (page: string) => {
    this.setState({ tokenData: null });
    this.setState({ page });
  };


  MarketHeader = (totalMarket) => {
    const {trendingData} = this.state;
    const {marketData} = this.props;
    const pathname = window.location.pathname;
    const content = [
      {path: '/markets', text: 'Today’s Cosmos Market Cap'},
      {path: '/markets/', text: 'Today’s Cosmos Market Cap'},
      {path: '/markets/trending/', text: 'Trending Cryptocurrencies'},
      {path: '/markets/gainers/', text: 'Top Gainers'},
      {path: '/markets/losers/', text: 'Top Losers'},
      {path: '/markets/recent/', text: 'Recently Added'}
    ];

    const findContent = content.filter(obj => { return obj.path === pathname });
    return (
      <div>
        <div className="token_search" style={{display : 'none'}}>
          <Input
            icon='search'
            iconPosition='left'
            placeholder='Search code, name'
          />
        </div>
        <div className='hd-area'>
          <div>
            {findContent.map(content => <h3>{pathname == content.path ? content.text : "Today's Cosmos Market Cap"}</h3>)}
            {pathname == "/markets/" && (
              <span className="sub">IBC Total market cap is <span>${numFormatter(marketData?.market_cap_cosmos)}</span> and is <span className={marketData.market_change_cosmos > 0.00 ? 'marketPercent__up' : 'marketPercent__down'}>{marketData?.market_change_cosmos}%</span> {marketData.market_change_cosmos > 0.00 ? 'Up' : 'Down'} in last 24hrs</span>
            )}
          </div>
          <div>
            <a href="https://nft-juno.loop.markets/" className="btn btn_trade px-3 mb-2 mb-lg-0">Trade NFTs</a>
            <a href="https://juno.loop.markets/" className="btn btn_trade btn_trade__blue px-3 mb-2 mb-lg-0">Swap Tokens</a>
          </div>
        </div>
      </div>
    )
  };


  MarketFilters = () => {
    const pathname = window.location.pathname;
    const links = [
      {to: '/markets/', text: 'All'},
      {to: '/markets/trending/', text: 'Trending'},
      {to: '/markets/gainers/', text: 'Gainers'},
      {to: '/markets/losers/', text: 'Losers'},
      {to: '/markets/recent/', text: 'Recently Added'}
    ];
    return (
      <div className="market_filters">
        {/*
        <div>
          <button className="btn"><img src={portfolioIcon} style={{verticalAlign: 'sub'}} /> Portfolio</button>
          <button className="btn"><img src={watchlistIcon} style={{verticalAlign: 'sub'}} /> Watchlist</button>
        </div>
        */}
        <div>
          <ul>
            {links.map(link => <li className={pathname == link.to ? "active" : ""}><Link to={link.to} onClick={() => { this.clearTokenData(link.to) }}>{link.text}</Link></li>)}
          </ul>
        </div>
      </div>
    )
  };

  tokenActions = () => {
    return (
      <div className="market_actions">
        <span className="action_button action_button__purple" >Receive <img src={receiveIcon} style={{verticalAlign: 'sub'}} /></span>
        <span className="action_button action_button__cyan">Send <img src={sendIcon} style={{verticalAlign: 'sub'}} /></span>
        <span className="action_button action_button__cyan action_button__swap">Swap <img src={swapIcon} style={{verticalAlign: 'sub'}} /></span>
      </div>
    )
  };

  tokenAction = (symbol) => {
    window.location.href = `${window.location.protocol}//${window.location.host}/token/${symbol.toLowerCase()}`;
  }

  setTableHover = (status, index) => {
    const {tableHover} = this.state;
    this.setState({tableHover: status, tableIndex: index})
  }

  render() {
    const { tokenData, tableHover, tableIndex } = this.state;
    let totalMarket = 0;
    tokenData?.data.map((data: any) => { totalMarket = totalMarket + parseInt(data.market_cap) });

    if (!tokenData) return (
      <div className="airdrop-container tr-power-up">
        <Helmet>
          <title>Loop Finance | Markets</title>
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-KBD87CK"></script>
        </Helmet>
        <div className="power-up__user-details-container">
          <div className="markets-table">
            <Loader active indeterminate inline="centered" />
          </div>
        </div>
      </div>
    );

    return (
        <>
          <div className="markets markets-table token-table-cz">
            {/*=================================*/}
            {/*========= Market Header =========*/}
            {/*=================================*/}
            {this.MarketHeader(totalMarket)}

            {/*=================================*/}
            {/*========= Market Filters ========*/}
            {/*=================================*/}
            {this.MarketFilters()}


            {/*=================================*/}
            {/*========== Market Table =========*/}
            {/*=================================*/}
            <MarketTable data={tokenData} />
            <MarketPagination perPage={tokenData?.meta?.per_page} currentPage={tokenData?.meta?.current_page} totalTokens={tokenData?.meta?.total} totalPages={tokenData?.meta?.last_page} />
          </div>
        </>
    );
  }
}
export default  withRouter(
  connect(
    (state: any) => {
      return {
        isAuthorized: state.user.userIsLogged,
        marketData: state.markets.headerData,
      };
    },
  )(TokensPage)
);
