import React, { useRef, useState } from "react";
import styles from "./platformmenu.module.scss";
import { MenuItems } from "./menu";

const PlatformMenuMobile: React.FC = () => {
  const containerRef = useRef(null);
  const [isShown, setIsShown] = useState(true);
  const menuItems = MenuItems();

  const handleScroll = () => {
    const container = containerRef.current;

    if (container) {
      const scrollPercentage =
        (container.scrollTop /
          (container.scrollHeight - container.clientHeight)) *
        100;

      // Update the custom scrollbar thumb position
      const thumb = document.getElementById("ecosystem-scrollbar");
      if (thumb) {
        thumb.style.top = `${scrollPercentage + 5}%`;
      }
    }
  };

  return (
    <div style={{height: "100%"}}>
      <div className={styles.buttonDivider} />
      <div>
        <button
          className={`${styles.platformMenuButton} ${
            !isShown
              ? styles.platformMenuButtonMobile
              : styles.platformMenuButtonMobileActive
          }`}
          //onClick={() => setIsShown(!isShown)}
        >
          Ecosystem
        </button>
      </div>

      <div
        className={styles.platformMenuModal}
        onScroll={handleScroll}
        ref={containerRef}
        style={{
          display: !isShown ? "none" : "block",
          opacity: !isShown ? "0" : "1",
          transition: "all 350ms",
          visibility: !isShown ? "hidden" : "visible",
          transform: !isShown ? "scale(0)" : "scale(1)",
          transformOrigin: "top center",
        }}
      >
        <div className={styles.platformItems}>
          {menuItems?.map((i: any) => {
            return (
              <div className={styles.item} hidden={i.item_type != "primary"}>
                <a href={i.link} target="_blank" className={styles.link}>
                  <p>
                    {i.title} <img
                      src={
                        process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS + i.icon
                      }
                    />
                  </p>
                </a>
              </div>
            );
          })}
          <div className={styles.divider} />
          <div className={styles.links}>
            {menuItems?.map((i: any) => {
              return (
                <a
                  href={i.link}
                  target="_blank"
                  hidden={i.item_type != "secondary"}
                >
                  {i.title}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformMenuMobile;
