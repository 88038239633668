import axios from 'axios';
import catchAxiosError from '../../util/catchAxiosError';

export async function fetchSearchData(payload) {
  console.log(payload)
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/post/search`,
    payload,
    )
    .catch(catchAxiosError);
  return result.data;
}

export async function fetchAllTitles() {
  console.log("Fetched Tokens")
  const result = await axios
    .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/post/slug`)
    .catch(catchAxiosError);
  return result.data;
}

export async function fetchAllUsers() {
  const result = await axios
    .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/user/allUsers`)
    .catch(catchAxiosError);
  return result.data;
}


