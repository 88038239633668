import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Pagination} from 'semantic-ui-react';

class MarketPagination extends Component {
  render() {
    const page = new URLSearchParams(window.location.search).get('page') || 1;
    const {currentPage, totalTokens, totalPages, perPage} = this.props;

    return (
      <div style={{display: 'inline-flex', width: '100%', justifyContent: 'space-between', marginTop: '40px'}}>
        <div className="page_results">
          <span>{page === 1 ? 1 : ((perPage * page) - perPage) + 1}-{(currentPage * perPage) <= totalTokens? (currentPage * perPage) : totalTokens}</span> of {totalTokens} Results
        </div>
        <Pagination
          defaultActivePage={page ? page : 1}
          totalPages={totalPages}
          firstItem={null}
          lastItem={null}
          onPageChange={(event, data) => {
            window.location.replace(`${window.location.protocol}//${window.location.host}${window.location.pathname}?page=${data.activePage}`);
          }}
        />
        <div className="page_results">
          {}
        </div>
      </div>
    );
  }
}

export default withRouter(MarketPagination);
