import React, { Component } from 'react';
import { Container, Grid, Button, Image, Header, Card, List, Table, Tab, Menu, GridColumn, Segment, Modal, Icon, Divider } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import Helmet from 'react-helmet';
import Feed from '../util/feed/Feed';
import { setPageForGoogleAnalytics } from '../util/helperFunctions';
import * as actions from '../yourtrybe/articles/articlesActions';
import * as api from './homeApi';
import MailchimpFormContainer from './mailChimpSubscribe';
import ReCAPTCHA from 'react-google-recaptcha';
import * as marketApi from '../pages/markets/Api';
import Footer from '../footer/Footer';
//import bannerImg from '../../../public/img/bannerImg.png';

// import 'swiper/css';
//import bannerImg from '../../staticAssets/images/themeImages/bannerImg.png';
import communityImg from '../../staticAssets/images/themeImages/communityImg.png';
import dexImg from '../../staticAssets/images/themeImages/dexImg.png';
import nftImg from '../../staticAssets/images/themeImages/nftImg.png';
import thirdBlock from '../../staticAssets/images/themeImages/thirdBlock.png';
import secondBlock from '../../staticAssets/images/themeImages/secondBlock.png';
import walletImg from '../../staticAssets/images/themeImages/walletImg.png';
import firstBlock from '../../staticAssets/images/themeImages/firstBlock.png';
import DescImg from '../../staticAssets/images/themeImages/DescImg.png';
import Jane from '../../staticAssets/images/themeImages/Jane.png';
import Guy from '../../staticAssets/images/themeImages/Guy.png';
import bitCoin from '../../staticAssets/images/themeImages/bitCoin.png';
import graphImg from '../../staticAssets/images/themeImages/graphImg.png';
import ethereumIcon from '../../staticAssets/images/themeImages/ethereumIcon.png';
import chainIcon from '../../staticAssets/images/themeImages/chainIcon.png';
import xrpIcon from '../../staticAssets/images/themeImages/xrpIcon.png';
import tetherIcon from '../../staticAssets/images/themeImages/tetherIcon.png';
import AccompliceWord from '../../staticAssets/images/themeImages/AccompliceWord.png';
import ColliderLabs from '../../staticAssets/images/themeImages/ColliderLabs.png';
import TerraIcon from '../../staticAssets/images/themeImages/terra.png';
import IconiumLogo from '../../staticAssets/images/themeImages/IconiumLogo.png';
import bannerWhite from '../../staticAssets/images/themeImages/bannerWhite.png';
import angelProtocol from '../../staticAssets/images/themeImages/angelProtocol.png';
import LoopImg from '../../staticAssets/images/themeImages/LoopImgN.png';

class Home extends Component {
  constructor(props) {
    SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
    super(props);
    this.updateCategoriesQuery = Home.updateCategoriesQuery;
    this.state = {
      acfData: null,
      getPromos: '',
      currentAuthorQuery: [],
      currentCategoryQuery: [],
      accordinValue: 'accordionLoop',
      accordinValue1: 'mint',
      email: '',
      show: false,
      articlerecaptcha: false,
      jobLink: '',
      activeClass: 'normal',
      walletModal: false
    };
  }

  async componentDidMount() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'homepage_view',
      'event_category': 'page',
      'event_action': 'homepage_view'
    });
    const el = document.querySelector('.app-body');
    el.addEventListener('scroll', this.onScrollTo);


    // Detect request animation frame
    var scroll = window.requestAnimationFrame ||
      // IE Fallback
      function (callback) { window.setTimeout(callback, 1000 / 60) };
    var elementsToShow = document.querySelectorAll('.show-on-scroll');

    function loop() {

      Array.prototype.forEach.call(elementsToShow, function (element) {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });

      scroll(loop);
    }

    // Call the loop for the first time
    loop();

    // Helper function from: http://stackoverflow.com/a/7557433/274826
    function isElementInViewport(el) {
      // special bonus for those using jQuery
      if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
      }
      var rect = el.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }

    //Counter Js Start 

    $('.count').each(function () {
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 3000,
        easing: 'swing',
        step: function (now) {

          $(this).text((Math.ceil(now)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));


        }
      });
    });

    //Counter Js Start 

    //setPageForGoogleAnalytics('home');
    const { getPromos, preChosenCategories } = this.props;
    const promoCache = JSON.parse(localStorage.getItem('trybe_promos'));
    if (!promoCache) {
      await getPromos();
    } else {
      this.setState({ getPromos: promoCache });
      await getPromos();
    }
    await api.getAuthors().then(acfData => this.setState({ acfData }));
    await marketApi.fetchTokenData().then(tokenData => this.setState({ tokenData }));
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { acfData, currentAuthorQuery } = this.state;
    if (acfData?.authors && currentAuthorQuery?.length === 0) {
      this.sortOfficalAuthor();
    }
  }

  onScrollTo = ({ target }) => {
    var items = $(".timeline li"),
    var timelineHeight = $(".timeline ul").height(),
    var greyLine = $('.default-line'),
    var lineToDraw = $('.draw-line');

    if (lineToDraw.length) {
      var redLineHeight = lineToDraw.height();
      var greyLineHeight = greyLine.height();
      var windowDistance = $(window).scrollTop();
      var windowHeight = $(window).height() / 2;
      var timelineDistance = $(".timeline").offset().top;


      if (windowDistance >= timelineDistance - windowHeight) {
        var line = windowDistance - timelineDistance + windowHeight;

        if (line <= greyLineHeight) {

          lineToDraw.css({
            'height': line + 20 + 'px'
          });
        }
      }

      // This takes care of adding the class in-view to the li:before items
      var bottom = lineToDraw.offset().top + lineToDraw.outerHeight(true);

      var czchangecolorWrap = $(".timeline #draw6").offset().top;
      //console.log("timelinelogger",bottom,czchangecolorWrap)
      if (bottom > czchangecolorWrap) {
        lineToDraw.css({
          'background': '#bf20ab'
        });

      } else {
        lineToDraw.css({
          'background': '#20A474'
        });
      }

      items.each(function (index) {
        var circlePosition = $(this).offset();
        var leftID;
        if (bottom > circlePosition.top) {
          leftID = $(this).attr('id');

          $("#left" + leftID).addClass('left-animation-div');
          $("#mobileRight" + leftID).addClass('left-animation-div');
          $("#right" + leftID).addClass('left-animation-div');
          if (bottom < czchangecolorWrap)
            $(this).addClass('in-view');
        } else {
          leftID = $(this).attr('id');
          $("#left" + leftID).removeClass('left-animation-div');
          $("#mobileRight" + leftID).removeClass('left-animation-div');
          $("#right" + leftID).removeClass('left-animation-div');
          $(this).removeClass('in-view');
        }
      });

    }
  };

  sortOfficalAuthor() {
    const { acfData } = this.state;
    const query = acfData.authors.map(({ id }) => [id]);
    this.setState({ currentAuthorQuery: query });
  }

  handleOnChangeEmail = email => {
    this.setState({
      email: email
    })
  }

  handleSendEmail = email => {
    console.log(email)
  }

  showModal = (value, type) => {
    this.setState({ show: true });
    let url = ''
    if (type == 'partner') {
      url = "mailto:partner@loop.markets?subject=Partnership"
      this.setState({ jobLink: url });
    } else if (type == 'job') {
      url = "mailto:jointheteam@loop.do?subject=" + value + "&body=Tell%20us%20why%20you’re%20the%20best,%20and%20how%20you’ll%20complement%20what%20we%20do.%0D%0DPlease%20attach%20your%20Git%20and%20Linkedin%20or%20a%20Resume.%0D%0DGood%20Luck!"
      this.setState({ jobLink: url });
    } else {
      url = "mailto:jointheteam@loop.do?subject=Why%20you%20guys%20need%20me&body=Tell%20us%20how%20you%20can%20take%20Loop%20to%20the%20next%20level"
      this.setState({ jobLink: url });
    }

  };

  onScroll = ({ target }) => {
    let userScrollHeight = window.innerHeight + window.scrollY;
    let windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight && !this.state.scrollStatus) {
      this.setState({ activeClass: "Top" });
    }
  };


  hideModal = () => {
    this.setState({ show: false });
    window.grecaptcha.reset();
    this.setState({ articlerecaptcha: false })
    this.setState({ jobLink: '' });
  };

  openLink = () => {
    const { jobLink } = this.state
    window.open(jobLink, "_blank")
    this.hideModal();
  }

  handleRecaptcha = () => {
    const { articlerecaptcha } = this.state;
    this.setState({ articlerecaptcha: !articlerecaptcha })
    if (!articlerecaptcha) {
      this.openLink()
    }
  }

  static updateCategoriesQuery(chosenCategories) {
    const query = chosenCategories[0]
      ? chosenCategories.map(({ id }) => id).join(',')
      : null;
    return query;
  }

  activeAccordin(value) {
    const { accordinValue } = this.state
    if (accordinValue == value) {
      this.setState({ accordinValue: '' })
    } else {
      this.setState({ accordinValue: value })
    }
  };

  activeAccordin1(value) {
    const { accordinValue1 } = this.state
    if (accordinValue1 == value) {
      this.setState({ accordinValue1: '' })
    } else {
      this.setState({ accordinValue1: value })
    }
  };


  render() {
    const { currentCategoryQuery, currentAuthorQuery, tokenData, getPromos, accordinValue, accordinValue1, show, articlerecaptcha, jobLink, walletModal } = this.state;
    const { promos, history, isAuthorized } = this.props;
    const promoData = getPromos ? getPromos : promos;
    //console.log(tokenData)
    const panes = [
      { menuItem: <Menu.Item><span>1</span>Tokenized Rewards</Menu.Item>, render: () => <Tab.Pane><span>Unlike existing social networks, who keep all the money for themselves – we reward you for your contribution to Loop!</span><span>By creating amazing content on Loop you will be rewarded by the community in the LOOP token - our very own cryptocurrency that can be exchanged for any other currency that you choose.</span><span>The better your content the higher the reward! Start sharing your knowledge and earning today!</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>2</span>Great Articles</Menu.Item>, render: () => <Tab.Pane><span>Find great articles on a whole range of interesting topics and learn something new!</span><span>Keep up to date with topics of interest as our community scours the web for the most recent and relevant information and creates great content about it.</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>3</span>Crypto Exchange</Menu.Item>, render: () => <Tab.Pane><span>Use our decentralised exchange to swap all of your Terra assets.</span><span>Provide liquidity to our exchange and start earning fees from trades and from yield farming.</span><span>Stake your LOOP tokens and start earning staking rewards today!</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>4</span>Great Community</Menu.Item>, render: () => <Tab.Pane><span>Once upon a time, humans shared their knowledge, skills, and resources with their loop - that is what helped them to thrive.</span><span>In this day and age, it's more important than ever to be surrounded by the right people in order to succeed.</span><span>Join our rapidly growing network of forward thinking entrepreneurs, solopreneurs, cryptocurrency investors, ratbags, geniuses, and people who want to build a better life for ourselves and the world around us.</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>5</span>Our Own Economy</Menu.Item>, render: () => <Tab.Pane><span>By incentivizing sharing of knowledge, skills, and resources through cryptocurrency rewards, we are not just building a community but our very own global, peer to peer economy-decentralized, and free from the tyranny and corruption of established governments and corporations.</span></Tab.Pane> },
      // { menuItem: `${6} Build Your Own Trybe`, render: () => <Tab.Pane><span>Would you like to start building your own community using our gamification and incentivized reward systems?</span><span>You choose the rules, and the rewards, based on the actions you want your users to make.</span><span>You can either use points and create reward tiers like a traditional loyalty program, or you can introduce your very own cryptocurrency token into the mix. TRVBE</span><span>Either way - we will help you get set up and started.</span></Tab.Pane> },
    ];
    return (

      <main className="homepage HomePageFull">
        <div className='PopupWrap'></div>
        <Modal
          open={walletModal}
          onClose={() => this.setState((prevState) => ({ walletModal: !prevState.walletModal }))}
          size='small'
        >
          <Modal.Header className="loginModalHeader">

            <p>Work In Progress</p>
            <Button
              floated="right"
              className="cancel-button loginCloseBtn"
              icon
              basic
              onClick={() => this.setState((prevState) => ({ walletModal: !prevState.walletModal }))}>
              <Icon name="close" />
            </Button>
          </Modal.Header>
          <Modal.Content>
            <div className='ComingSoon'>
              <h1>Coming Soon</h1>
            </div>
          </Modal.Content>
          <Divider />
        </Modal>

        <Helmet>
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-KBD87CK"></script>
        </Helmet>
        {/* 
        <a href="https://restore-earth.angelprotocol.io/" target="_blank" style={{textDecoration: 'blink'}}>
          <div className="banner">
            <div className="banner-track">
              <div className="banner-text">
                <div>
                  <span>Donate. Get rewarded. <strong class="banner-text__bold"> Help Restore Earth 🌎</strong></span>
                </div>
              </div>
            </div>
          </div>
        </a>
        */}
        {/* ================   Hero section   =============== */}
        <div className="homebg_section HomeTopBanner">

          <div className="hero_banner_area">
            <Container>
              <Grid className="hero_section homeText">
                <Grid.Row columns={2} className="align-items-center">
                  <Grid.Column>
                    <div className="headerTextUp">
                      <Header as="h1" className="headerContent"><span>Loop</span>Crypto Exchange <span className="subHeading">& Gateway to Terra</span> </Header>
                      <Image className="line" src="img/headingLine.svg" alt="img" />
                      <div className="ui list">
                        <div className="item"><a>Earn</a></div>
                        <div className="item"><a>Exchange</a></div>
                        <div className="item"><a>Create</a></div>
                        <div className="item"><a>DeFi</a></div>
                      </div>
                      <div className="main_action_btn cz-home-set-icons">
                        <div className="headerBtnBox position-relative">
                          {/* <Button className="btn headerBtn px-3 mb-2 mb-lg-0" onClick={() => history.push('/sign-up')}>Start Earning LOOP Tokens</Button> */}
                          <a href="https://dex.loop.markets/swap#Swap" className="btn headerBtn px-3 mb-2 mb-lg-0">Trade Now</a>
                          {!isAuthorized ? (
                            <a onClick={() => history.push('/sign-up/')} target="_blank" className="btn headerBtn px-3 mb-2 mb-lg-0">Start Earning LOOP Tokens</a>
                          ) : (
                            <a onClick={() => history.push('/community/')} className="btn headerBtn px-3 mb-2 mb-lg-0">Browse The Community</a>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid.Column>

                  <Grid.Column>
                    <div className="main_action_btn cz-home-set-icons">
                      {/* Icons box start */}
                      <div className="topIcons">
                        <div className="iconsInner">
                          <div className="d-flex align-items-center justify-content-between">  <h3>Top 5</h3> <p className="updateData"><span></span>Live Prices</p></div>
                          <div className="iconProgress">
                          {tokenData?.length > 0 && tokenData && (
                            <>
                              {tokenData.slice(0, 1).map((data) => {
                                // Return the element. Also pass key
                                return (
                                <ul className="activehover">
                                  <li className="iconTxtWrp"><div className="">
                                  <Image src={data?.icon} />
                                    </div>
                                    <div className="imgTxt">
                                      <h5>{data.name}</h5>
                                      <p>{data?.symbal}</p>
                                    </div>
                                    </li>
                                    {/*
                                    <li className="grphImg">
                                    <Image src={graphImg} />
                                    </li>
                                    */}
                                    <li className="rankHigh">
                                      <h5>{data?.price ? data?.price : '?'}</h5>
                                      <p><small>{data?.day > -0.01 ? <span className="marketUp">{data?.day}%</span> : <span className="marketDown">{data?.day}%</span>}</small></p>
                                    </li>
                                  </ul>
                                );
                              })}
                              {tokenData.slice(1, 5).map((data) => {
                                // Return the element. Also pass key
                                return (
                                  <ul>
                                  <li className="iconTxtWrp"><div className="">
                                  <Image src={data?.icon} />
                                    </div>
                                    <div className="imgTxt">
                                      <h5>{data.name}</h5>
                                      <p>{data?.symbal}</p>
                                    </div>
                                    </li>
                                    {/*
                                    <li className="grphImg">
                                    <Image src={graphImg} />
                                    </li>
                                    */}
                                    <li className="rankHigh">
                                      <h5>{data?.price ? data?.price : '?'}</h5>
                                      <p><small>{data?.day > -0.01 ? <span className="marketUp">{data?.day}%</span> : <span className="marketDown">{data?.day}%</span>}</small></p>
                                    </li>
                                  </ul>
                                );
                              })}
                            </>
                          )}
                          </div>
                        </div>
                      </div>
                      {/* Icons box start */}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column className="homeCounter">
                    <div className="counterWrap">
                      <ul>
                        <li><div id="shiva"><span className="count">8</span>M+</div>
                          <p>TRANSACTIONS</p>
                        </li>
                        <li><div id="shiva"><span className="count">200000</span>+</div>
                          <p>USERS</p>
                        </li>
                        <li><div id="shiva"><span className="count">55000</span>+</div>
                          <p>ARTICLES</p>
                        </li>
                        <li><div id="shiva"><span className="count">8</span>+</div>
                          <p>PRODUCTS</p>
                        </li>
                      </ul>

                    </div>
                  </Grid.Column>
                  <Grid.Column></Grid.Column>
                </Grid.Row>
              </Grid>

              <Image className="homebg" src="img/bg_home.svg" alt="homebg" />
            </Container>
          </div>
          {/* Our Partners Section start */}

          <div className="partnerSection">
            <Container>
              <Grid>
                <Grid.Row columns={2} className="align-items-center">
                  <Grid.Column width={4}>
                    <div className="titleWrap">
                      <h2>Our proud partners:</h2>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <Segment>
                            <Image src={AccompliceWord} />
                          </Segment>
                          <Segment>
                            <Image src={TerraIcon} />
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment>
                            <Image src={ColliderLabs} />
                          </Segment>
                          <Segment>
                            <Image src={IconiumLogo} />
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment>
                            <Image src={bannerWhite} />
                          </Segment>
                          <Segment>
                            <Image src={angelProtocol} />
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          {/* Our Partners Section End */}
          {/* Community Box Start */}
          <div className="productSection">
            <Container>
              <div className="text-center inline-photo show-on-scroll">
                <h5>OUR PRODUCTS</h5>
                <h2 className="bottomLine">Get the most out of Terra<br></br>
                  Blockchain with the Loop Ecosystem </h2>
              </div>
              <Grid className="ecoSystem">
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <a href="https://www.loop.markets/community/">
                      <div className="communityBox inline-photo show-on-scroll">
                        <div><h2>Community</h2>
                          <p>Join the biggest community on Terra and earn LOOPR tokens for creating great content, engaging with others,
                            and learning about the Terra ecosystem.</p>
                          <Button className="joinBtn">Join Now <img src="img/learnMore.svg" alt="" class="ui image" /></Button></div>
                        <div className="bottomImg"><Image src={communityImg} /></div>
                      </div>
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a href="https://dex.loop.markets/">
                      <div className="communityBox inline-photo show-on-scroll">
                        <div><h2>Crypto Exchange (DEX)</h2>
                          <p>Trade a range of tokens on our DEX or provide liquidity to earn swap fees and LOOP tokens from our yield farms!</p>
                          <Button className="joinBtn">Trade now <img src="img/learnMore.svg" alt="" class="ui image" /></Button></div>
                        <div className="bottomImg"><Image src={dexImg} /></div>
                      </div>
                    </a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          {/* Community Box End */}

          {/* NFT Marketplace Section Start */}
          <div className="marketSection">
            <Container>
              <Grid className="ecoSystem inline-photo show-on-scroll">
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <a href="https://nft.loop.markets/">
                      <div className="marketInner">
                        <Grid className="ecoSystem">
                          <Grid.Row columns={2}>
                            <Grid.Column className="leftEco">
                              <div className="">
                                <h2>DeFi NFT Marketplace</h2>
                                <p>Mint DeFi NFTs and easily add utility with full control over their smart contracts. Trade DeFi NFTs such
                                  as the Loop Bond NFTs with fixed yield</p>
                                <Button className="joinBtn">Trade & Mint Now <img src="img/learnMore.svg" alt="" class="ui image" /></Button>
                              </div>
                            </Grid.Column>
                            <Grid.Column className="nftImgRight rightEco">
                              <div className="nftImg">
                                <Image src={nftImg} />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          {/* NFT Marketplace Section End */}

          {/* Earn Seed Section Start */}
          {/* <div className="productSection earnSection">
          <Container>
            <Grid className="ecoSystem">
            <Grid.Row columns={2}>
            <Grid.Column>
              <div className="communityBox inline-photo show-on-scroll">
                  <h2>Earn</h2>
                  <p>Loop works hard to reward it’s community. There are currently 3 ways to earn 
                    LOOP + NFTs + Partner tokens. All of Loop’s excluse partnerships will launch 
                    on Loop’s Earn platform</p>
                    <div className="groupBtns"> 
                      <Button className="joinBtn">Bounties <img src="img/learnMore.svg" alt="" class="ui image" /></Button>
                      <Button className="joinBtn quest">NFT Quests <img src="img/learnMore.svg" alt="" class="ui image" /></Button>
                      <Button className="joinBtn learnBtn">Learn <img src="img/learnMore.svg" alt="" class="ui image" /></Button>
                    </div>
                     <div className="bottomImg"><Image src={earnImg} /></div>   
              </div>
            </Grid.Column>
            <Grid.Column>
            <div className="communityBox seedbox inline-photo show-on-scroll">
                <div className="seedInner">
                  <h2>SEED</h2>
                  <p>Trade a range of tokens on our DEX or provide liquidity to start earning swap fees and bonus
                     LOOP tokens from our yield farms!</p>
                     <Button className="joinBtn">Trade now <img src="img/learnMore.svg" alt="" className="ui image" /></Button>
                </div>   
                     <div className="bottomImg"><Image src={seedImg} /></div>
                  
              </div>
               
            </Grid.Column>
            </Grid.Row>
            </Grid>
          </Container>
          </div> */}
          {/* Earn Seed Section  End */}

          {/* App Section Start */}
          <div className="AppSection marketSection">
            <Container>
              <Grid className="ecoSystem inline-photo show-on-scroll">
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <a href="https://onelink.to/loop-beta" target="_blank">
                      {/* <div className="marketInner" onClick={() => this.setState((prevState) => ({walletModal: !prevState.walletModal }))}> */}
                      <div className="marketInner">
                        {/* 
                          <div className='ToolTipOnHover'>
                            <div className='ToolTipOnHoverInner'>
                              <h1>Coming Soon</h1>
                            </div>
                          </div>
                        */}
                        <Grid className="ecoSystem">
                          <Grid.Row columns={2}>
                            <Grid.Column className="leftEco leftEcoSecond">
                              <div className="WalletContent">
                                <h2>Wallets</h2>
                                <p>Powered by Terra Station, the Loop Crypto Wallet adds our dark UI, DeFi NFT Marketplace, Portfolio breakdown, DEX, Community, and more...</p>
                                <Button className="btn headerBtn px-3">Beta Test Now</Button>
                              </div>
                            </Grid.Column>
                            <Grid.Column className="nftImgRight nftImgRightSecond rightEco">
                              <div className="nftImg">
                                <Image src={walletImg} />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          {/* App Section End */}


          {/* Extension Food Section Start */}
          {/*   <div className="productSection extensionSection">
          <Container>
            <Grid className="ecoSystem">
            <Grid.Row columns={2}>
            <Grid.Column>
              <div className="communityBox extensionInner inline-photo show-on-scroll">
                  <h2>Extension</h2>
                  <p>Powered by Terra Station, the Loop Chrome Extension will bring all of Loop’s
                     features in one place   </p>
                     <Button className="btn headerBtn px-3">Coming Soon</Button>
                     <div className="bottomImg"><Image src={extensionImg} /></div>   
              </div>
            </Grid.Column>
            <Grid.Column>
            <div className="communityBox foodBox inline-photo show-on-scroll">
                <div className="seedInner">
                  <h2>Food Marketplace</h2>
                  <p>Currently being rolled out across Melbourne, Australia in partnership with DoorDash.
                     Loop Food brings the high volume of micro transactions in the hospitality industry
                      to the Loop DEX, while providing singificant optimisations and rewards when compared to Uber Eats</p>
                      <Button className="btn headerBtn px-3">Coming Soon</Button>
                </div>   
                     <div className="bottomImg"><Image src={foodImg} /></div>             
              </div>
               
            </Grid.Column>
            </Grid.Row>
            </Grid>
          </Container>
           </div> */}
          {/* Extension Food Section  End */}

          {/* <div class="timeline">
  <ul>
    <span class="default-line"></span>
    <span class="draw-line"></span>
      <li>
        <div>
        </div> 
      </li>
      <li>
        <div>
        </div> 
      </li>
      <li>
        <div>
        </div> 
      </li>
      <li>
        <div>
        </div> 
      </li>
  </ul>
</div> */}




          {/* RoadMap Section Start */}
          <div className={`roadMapOuter ${this.state.activeClass}`}>
            <Container>
              <div className="titleWrap">
                <h2>Roadmap</h2>
                <p>Loop’s Roadmap to becomming the worlds largest Gateway to Decentralised Finance & Crypto</p>
              </div>
              <div className="roadMapWrap roadMapDesktop">
                <div className="roadMapLeft">
                  <div className="dateLeftWrapOuter" id="leftdraw1">
                    <div className="dateLeftWrap select">
                      <div className="dateyearLeft">
                        <h4>Aug</h4>
                        <p>2021</p>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="leftdraw2">
                    <div className="dateLeftWrap select">
                      <div className="dateyearLeft rightDesc darkcolor">
                        <p>Beta launch of Loop DEX</p>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="leftdraw3">
                    <div className="dateLeftWrap  unselect">
                      <div className="dateyearLeft">
                        <h4>Nov</h4>
                        <p>2021</p>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="leftdraw4">
                    <div className="dateLeftWrap unselect">
                      <div className="dateyearLeft rightDesc darkcolor">
                        <ul>
                          <li>Launch Loop Farming</li>
                          <li>Launch Loop Staking</li>
                          <li>Launch Loop BETA iOS & Android Wallet Apps</li>
                          <li>Launch v2 DEX UI Preview</li>
                          <li>Launch Loop NFT Marketplace Preview</li>
                          <li>Upgrade speed and stability of Community</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="leftdraw5">
                    <div className="dateLeftWrap  unselect">
                      <div className="dateyearLeft">
                        <h4>Q1</h4>
                        <p>2022</p>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="leftdraw6">
                    <div className="dateLeftWrap  unselect">
                      <div className="dateyearLeft rightDesc darkcolor">
                        <ul>
                          <li>Launch Loop Chrome Extension</li>
                          <li>Add more functionality & integrations to Loop App</li>
                          <li>Scale up Loop Learn partnerships and content</li>
                          <li>Work with cross chain Launchpads to bring more projects and money to Terra</li>
                          <li>Launch more exclusive projects powered by Loop</li>
                          <li>Launch easy one-click DeFi products</li>
                          <li>List on more top tier CEXs</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="leftdraw7">
                    <div className="dateLeftWrap  unselect">
                      <div className="dateyearLeft">
                        <h4>Q3</h4>
                        <p>2022</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="dateLeftWrapOuter">
                            <div className="dateLeftWrap mb-120 unselect">
                              <div className="dateyearLeft rightDesc darkcolor">
                                  <ul>
                                    <li>Deploy NFT Quests</li>
                                    <li>Launch Loop Learn</li>
                                  </ul>
                              </div>
                            </div> 
                            </div>
                            <div className="dateLeftWrapOuter">  
                            <div className="dateLeftWrap mb-120 unselect">
                              <div className="dateyearLeft">
                                  <h4>Q1</h4>
                                  <p>2022</p>
                              </div>
                            </div>  
                            </div>
                            <div className="dateLeftWrapOuter"> 
                            <div className="dateLeftWrap mb-120 unselect">
                              <div className="dateyearLeft rightDesc darkcolor">
                                  <p>Roll out Loop Food to USA, Europe & Asia</p>
                              </div>
                            </div>  
                            </div>
                            <div className="dateLeftWrapOuter">
                            <div className="dateLeftWrap mb-120 unselect">
                              <div className="dateyearLeft">
                                  <h4>2023</h4>
                                  <p></p>
                              </div>
                            </div>   
                            </div>                                                                                                                                                                                                                                                                     */}
                </div>


                <div className="roadMapCenter">
                  <div className="roadMapLine">
                    <div className="timeline">
                      <ul>
                        <span className="default-line"></span>
                        <span className="default-line2"></span>
                        <span className="draw-line"></span>
                        <li id="draw1">
                          <div>
                          </div>
                        </li>
                        <li id="draw2">
                          <div>
                          </div>
                        </li>
                        <li id="draw3">
                          <div>
                          </div>
                        </li>
                        <li id="draw4">
                          <div>
                          </div>
                        </li>
                        <li id="draw5">
                          <div>
                          </div>
                        </li>
                        <li id="draw6">
                          <div>
                          </div>
                        </li>
                        <li id="draw7">
                          <div>
                          </div>
                        </li>
                        {/* <li>
                                    <div>
                                    </div> 
                                  </li>
                                  <li>
                                    <div>
                                    </div> 
                                  </li>
                                  <li>
                                    <div>
                                    </div> 
                                  </li>
                                  <li>
                                    <div>
                                    </div> 
                                  </li>                                                                     */}
                      </ul>
                      <div className="timelineEndUpcoming">
                        <span>2022</span>
                        <b>2023</b>
                        <i>2024</i>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="roadMapRight">
                  <div className="dateLeftWrapOuter" id="rightdraw1">
                    <div className="descRight">
                      <div className="descMain">
                        <div className="descInner">
                          <p>Official launch of Loop Community</p>
                          {/* <span>40,000+ Accounts</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="rightdraw2">
                    <div className="dateRightWrap">
                      <div className="dateyearRight">
                        <h4>Oct</h4>
                        <p>2021</p>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="rightdraw3">
                    <div className="descRight ">
                      <div className="descMain">
                        <div className="descInner listing">
                          <ul>
                            <li>20+ pairs on DEX including ETH, BTC, Luna pairs</li>
                            <li>Start official TFL Bounties on Loop Community</li>
                            <li>Officially out of Beta</li>
                          </ul>
                        </div>
                        {/* <span className="articleCount">70,000+ Accounts | 40,000+ Articles</span>                             */}
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="rightdraw4">
                    <div className="dateRightWrap">
                      <div className="dateyearRight">
                        <h4>Dec</h4>
                        <p>2021</p>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="rightdraw5">
                    <div className="descRight">
                      <div className="descMain">
                        <div className="descInner listing">
                          <ul>
                            <li>Launch LP Bonds</li>
                            <li>Launch Loop LP NFTs & Utility NFTs</li>
                            <li>Launch v2 DEX UI</li>
                            <li>Launch iOS & Android Wallet Apps come ut of Beta</li>
                            {/*<li>Partner with Olympus DAO</li>*/}
                            <li>Launch NFT Staking</li>
                            <li>Deploy NFT Quests</li>
                            <li>Launch Loop Learn</li>
                            <li>Launch new exclusive projects powered by Loop</li>
                            {/* <li>List on selected top tier CEX’s</li> */}
                            <li>Partner with projects on ETH </li>
                            <li>LOOP & LOOPR holders receive airdrops from Loop Ventures’ partner projects</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="rightdraw6">
                    <div className="dateRightWrap">
                      <div className="dateyearRight">
                        <h4>Q2</h4>
                        <p>2022</p>
                      </div>
                    </div>
                  </div>
                  <div className="dateLeftWrapOuter" id="rightdraw7">
                    <div className="descRight">
                      <div className="descMain">
                        <div className="descInner listing">
                          <ul>
                            <li>Loop App & Chrome Extension to be the #1 wallet on Terra </li>
                            <li>Expand Loop product range to other chains</li>
                            <li>Become the gateway to crypto for the mass market</li>
                            <li>Reach 10m active daily users + 1b TVL</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="dateLeftWrapOuter">
                                <div className="dateRightWrap mb-110">
                                  <div className="dateyearRight">
                                      <h4>Q1</h4>
                                      <p>2022</p>
                                  </div>
                                </div>  
                            </div>
                            <div className="dateLeftWrapOuter">
                                <div className="descRight launch  mb-50">
                                  <div className="descMain">
                                    <div className="descInner listing">
                                      <ul>
                                        <li>Launch new exclusive projects powered by Loop</li>
                                        <li>Roll out Loop Food Australia-wide</li>
                                      </ul>
                                    </div>                      
                                    </div>
                                </div> 
                            </div>
                            <div className="dateLeftWrapOuter">
                            <div className="dateRightWrap qfour mb-110">
                              <div className="dateyearRight">
                                  <h4>Q4</h4>
                                  <p>2022</p>
                              </div>
                            </div>
                            </div>
                            <div className="dateLeftWrapOuter">
                                <div className="descRight mb-60">
                                  <div className="descMain">
                                    <div className="descInner">
                                      <p>XXX</p>
                                      
                                    </div>                             
                                    </div>
                                </div>   
                            </div>                                                                                                                                                                                                                       */}
                </div>

                <div className="RoadMapMView">

                  <div className="roadMapMobile">
                    <div className="roadWrap">
                      <div className="dateTxt" id="mobileRightdraw1">
                        <div className="dateTxtInner">
                          <div className="monthdate">
                            <h4>Aug</h4>
                            <p>2021</p>
                          </div>
                          <div className="monthContent">
                            <p>Official launch of Loop Community</p>
                            {/* <span>40,000+ Accounts</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="process active"></div> */}
                    <div className="roadWrap">
                      <div className="dateTxt" id="mobileRightdraw2">
                        <div className="dateTxtInner">
                          <div className="monthdate">
                            <h4>Oct</h4>
                            <p>2021</p>
                          </div>
                          <div className="monthContent">
                            <p>Beta launch of Loop DEX</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="process active"></div> */}
                    <div className="roadWrap">
                      <div className="dateTxt" id="mobileRightdraw3">
                        <div className="dateTxtInner">
                          <div className="monthdate">
                            <h4>Nov</h4>
                            <p>2021</p>
                          </div>
                          <div className="monthContent">
                            <ul>
                              <li>20+ pairs on DEX including ETH, BTC, Luna pairs</li>
                              <li>Start official TFL Bounties on Loop Community</li>
                              <li>Officially out of Beta</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="process inactive"></div> */}
                    <div className="roadWrap czchangecolorWrap">
                      <div className="dateTxt" id="mobileRightdraw4">
                        <div className="dateTxtInner">
                          <div className="monthdate">
                            <h4>Dec</h4>
                            <p>2021</p>
                          </div>
                          <div className="monthContent">
                            <ul>
                              <li>Launch Loop Farming</li>
                              <li>Launch Loop Staking</li>
                              <li>Launch Loop BETA iOS & Android Wallet Apps</li>
                              <li>Launch v2 DEX UI Preview</li>
                              <li>Launch Loop NFT Marketplace Preview</li>
                              <li>Upgrade speed and stability of Community</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="process inactive"></div> */}
                    <div className="roadWrap">
                      <div className="dateTxt" id="mobileRightdraw5">
                        <div className="dateTxtInner">
                          <div className="monthdate">
                            <h4>Q1</h4>
                            <p>2022</p>
                          </div>
                          <div className="monthContent">
                            <ul>
                              <li>Launch LP Bonds</li>
                              <li>Launch Loop LP NFTs & Utility NFTs</li>
                              <li>Launch v2 DEX UI</li>
                              <li>Launch iOS & Android Wallet Apps come ut of Beta</li>
                              {/*<li>Partner with Olympus DAO</li>*/}
                              <li>Launch NFT Staking</li>
                              <li>Deploy NFT Quests</li>
                              <li>Launch Loop Learn</li>
                              <li>Launch new exclusive projects powered by Loop</li>
                              {/* <li>List on selected top tier CEX’s</li> */}
                              <li>Partner with projects on ETH </li>
                              <li>LOOP & LOOPR holders receive airdrops from Loop Ventures’ partner projects</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="process inactive"></div> */}
                    <div className="roadWrap">
                      <div className="dateTxt" id="mobileRightdraw6">
                        <div className="dateTxtInner">
                          <div className="monthdate">
                            <h4>Q2</h4>
                            <p>2022</p>
                          </div>
                          <div className="monthContent">
                            <ul>
                              <li>Launch Loop Chrome Extension</li>
                              <li>Add more functionality & integrations to Loop App</li>
                              <li>Scale-up Loop Learn partnerships and content</li>
                              <li>Work with cross chain launchpads to bring more projects to Terra</li>
                              <li>Launch additional exclusive projects powered by Loop</li>
                              <li>Launch easy one-click DeFi products</li>
                              <li>List on additional top tier CEXs</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="process inactive"></div> */}
                    <div className="roadWrap">
                      <div className="dateTxt" id="mobileRightdraw7">
                        <div className="dateTxtInner">
                          <div className="monthdate">
                            <h4>Q3</h4>
                            <p>2022</p>
                          </div>
                          <div className="monthContent">
                            <ul>
                              <li>Loop App & Chrome Extension to be the #1 wallet on Terra </li>
                              <li>Expand Loop product range to other chains</li>
                              <li>Become the gateway to crypto for the mass market</li>
                              <li>Reach 10m active daily users + 1b TVL</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="process inactive"></div>
                       <div className="roadWrap">
                          <div className="dateTxt">
                            <div className="dateTxtInner">
                                <div className="monthdate">
                                    <h4>Q1</h4>
                                    <p>2022</p>
                                </div>
                                  <div className="monthContent">
                                    <ul>
                                      <li>Deploy NFT Quests</li>
                                      <li>Launch Loop Learn</li>
                                    </ul>
                                  </div>
                            </div>
                          </div>   
                       </div>
                       <div className="process inactive"></div>
                       <div className="roadWrap">
                          <div className="dateTxt">
                            <div className="dateTxtInner">
                                <div className="monthdate">
                                    <h4>Q1</h4>
                                    <p>2022</p>
                                </div>
                                  <div className="monthContent">
                                    <ul>
                                      <li>Launch new exclusive projects powered by Loop</li>
                                      <li>Roll out Loop Food Australia-wide</li>
                                    </ul>
                                  </div>
                            </div>
                          </div>   
                       </div> 
                       <div className="process inactive"></div>
                       <div className="roadWrap">
                          <div className="dateTxt">
                            <div className="dateTxtInner">
                                <div className="monthdate">
                                    <h4>Q4</h4>
                                    <p>2022</p>
                                </div>
                                  <div className="monthContent">
                                   <p>Roll out Loop Food to USA, Europe & Asia</p>
                                  </div>
                            </div>
                          </div>   
                       </div> 
                       <div className="process inactive"></div>
                       <div className="roadWrap">
                          <div className="dateTxt">
                            <div className="dateTxtInner">
                                <div className="monthdate">
                                    <h4>Q4</h4>
                                    <p>2023</p>
                                </div>
                                  <div className="monthContent">
                                   <p>XXX</p>
                                  </div>
                            </div>
                          </div>   
                       </div>   */}
                    <div className="process inactive d-none"></div>
                  </div>






                </div>


              </div>




              <div className="roadMapMobile">
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Aug</h4>
                        <p>2021</p>
                      </div>
                      <div className="monthContent">
                        <p>Official launch of Loop Community</p>
                        <span>40,000+ Accounts</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process active"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Oct</h4>
                        <p>2021</p>
                      </div>
                      <div className="monthContent">
                        <p>Beta launch of Loop DEX</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process active"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Nov</h4>
                        <p>2021</p>
                      </div>
                      <div className="monthContent">
                        <ul>
                          <li>20+ pairs on DEX including ETH, BTC, Luna pairs</li>
                          <li>Start official TFL Bounties on Loop Community</li>
                          <li>Officially out of Beta</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap czchangecolorWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Dec</h4>
                        <p>2021</p>
                      </div>
                      <div className="monthContent">
                        <ul>
                          <li>Launch auto compounding Loop Farming</li>
                          <li>Launch Loop Staking</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Dec</h4>
                        <p>2021</p>
                      </div>
                      <div className="monthContent">
                        <ul>
                          <li>Launch Loop iOS & Android Wallet Apps</li>
                          <li>Launch v2 DEX UI</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Dec</h4>
                        <p>2021</p>
                      </div>
                      <div className="monthContent">
                        <ul>
                          <li>Launch Loop NFT Marketplace</li>
                          <li>Upgrade speed and stability of Community</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Q1</h4>
                        <p>2022</p>
                      </div>
                      <div className="monthContent">
                        <ul>
                          <li>Launch Loop Utility NFTs</li>
                          <li>Launch NFT Staking</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Q1</h4>
                        <p>2022</p>
                      </div>
                      <div className="monthContent">
                        <ul>
                          <li>Deploy NFT Quests</li>
                          <li>Launch Loop Learn</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Q1</h4>
                        <p>2022</p>
                      </div>
                      <div className="monthContent">
                        <ul>
                          <li>Launch new exclusive projects powered by Loop</li>
                          <li>Roll out Loop Food Australia-wide</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Q4</h4>
                        <p>2022</p>
                      </div>
                      <div className="monthContent">
                        <p>Roll out Loop Food to USA, Europe & Asia</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
                <div className="roadWrap">
                  <div className="dateTxt">
                    <div className="dateTxtInner">
                      <div className="monthdate">
                        <h4>Q4</h4>
                        <p>2023</p>
                      </div>
                      <div className="monthContent">
                        <p>XXX</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process inactive"></div>
              </div>
            </Container>

          </div>
          {/* RoadMap Section End */}
          {/* ================   Hero Section End  =============== */}




        </div>



        {/* <div className="testing_wrap">
          <div className="testing_slider">
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              navigation
              loop
              autoplay:true
              breakpoints={{
                1199: {
                  slidesPerView: 4,
                  spaceBetween: 70,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 70,
                },
                320: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                }
              }}
            >
              <SwiperSlide>
                <img src="/img/people.png" alt="" />
                <h4>Community</h4>
                <p>Join our community of Lunatics and earn LOOPR tokens for creating great content, engaging with others, and learning about the Terra ecosystem at the same time!</p>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/img/wallet-1.png" alt="" />
                <h4>Wallet</h4>
                <p>Our Chrome extension wallet and mobile wallets are coming soon, where you'll be able to see both your tokens and NFTs as well as trade directly on our DEX.</p>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/img/dex-icon.png" alt="" />
                <h4>DEX</h4>
                <p>Trade a range of tokens on our DEX or provide liquidity to start earning swap fees and bonus LOOP tokens from our yield farms!</p>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/img/wallet-1.png" alt="" />
                <h4>Wallet</h4>
                <p>Our Chrome extension wallet and mobile wallets are coming soon, where you'll be able to see both your tokens and NFTs as well as trade directly on our DEX.</p>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/img/wallet-2.png" alt="" />
                <h4>NFTs</h4>
                <p>Mint & trade the most flexible NFTs on the market - all in one place. Stay tuned for utility NFTs to boost your wallet & lower trading fees.</p>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/img/wallet-1.png" alt="" />
                <h4>Wallet</h4>
                <p>Our Chrome extension wallet and mobile wallets are coming soon, where you'll be able to see both your tokens and NFTs as well as trade directly on our DEX.</p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div> */}




        {/* ========================== loop Section ====================== */}

        <div className="bg-gradient-loop feedback_wrap">
          <Grid className="loop-grid">
            <Grid.Row className="loop-section">
              <Grid.Column className="loop-img" computer={8} mobile={16} tablet={12}>
                <Image className="img-fluid" src={LoopImg} alt="" />
              </Grid.Column>
              <Grid.Column computer={8} mobile={16} tablet={16} className="innerboxx">
                <div className="loop-text">
                  <Header as="h6" className="textGradient integrationTabH6">BRINGING HIGH QUALITY PROJECTS TO TERRA</Header>
                  <Header as="h2">We partner with the best</Header>
                  <p>We’re all about improving the Loop Ecosystem and giving back to our loyal Community. </p>
                  <p>Loop helps incubate new projects with seed raises and integration within the Loop Ecosystem, as well as helping partner projects own their own liquidity.</p>
                  <p>If your project can have a positive impact and provide utility to our users, we want to talk!</p>
                  <Button className="joinBtn" onClick={() => this.showModal('Partnership', 'partner')}>Partner <img src="img/learnMore.svg" alt="" class="ui image" /></Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        {/* ========================== Loop Section End ====================== */}

        {/* ================   Join Community Section  =============== */}
        <div className="bg-gradient-loop start-learning">
          <Container className="cz-support cz-support-set">
            <Grid>
              <Grid.Row className="join-community start-learning">
                <Grid.Column className="innerboxx">
                  <h2 class="bottomImg"><span className="ImgBtm Big">Earn tokens</span> for creating</h2>
                  <h2> great content!</h2>
                  <p>Join our community of awesome Lunatic content creators and start earning token just
                    by writing about your favorite projects! Learn more NOW</p>
                  <div className="headerBtnBox position-relative">
                    {!isAuthorized ? (
                      <Link to='/sign-up/' style={{textDecoration: 'none'}}><Button className="btn headerBtn px-3 mb-2 mb-lg-0">Join our community</Button></Link>
                    ) : (
                      <Link to='/community/' style={{textDecoration: 'none'}}><Button className="btn headerBtn px-3 mb-2 mb-lg-0">Browse The Community</Button></Link>
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Column className="start-learning-card" computer={16} mobile={16} tablet={16}>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  loop
                  className="mySwiper"
                  slideToClickedSlide
                  breakpoints={{
                    1200: {
                      freemode: true,
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    768: {
                      freemode: true,
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    640: {
                      freemode: true,
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    320: {
                      freemode: true,
                      slidesPerView: 1,
                      spaceBetween: 20,
                    }
                  }}
                //onSwiper={swiper => console.log(swiper)}
                //onSlideChange={() => console.log('slide change')}
                >
                  {promoData?.map(data => (
                    <div>
                      <SwiperSlide>
                        <Card href={data?.article_link}>
                          <Image src={data?.article_image} wrapped ui={false} />
                          <Card.Content>
                            <Card.Header>
                              <TextTruncate
                                line={2}
                                truncateText="..."
                                className="font-bold"
                                text={data?.article_title}
                              />
                            </Card.Header>
                            <Card.Description>
                              <TextTruncate
                                line={3}
                                truncateText="..."
                                className="font-thin"
                                text={data?.article_description}
                              />
                            </Card.Description>
                          </Card.Content>
                        </Card>
                      </SwiperSlide>
                    </div>
                  ))}
                </Swiper>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        {/* ================   Join Community Section End  =============== */}

        {/*=========================== Earn Token Section start ==================== */}
        {/*
        <div className="bg-gradient-loop EarnToken TokenWrp">
        <Container className="cz-support cz-support-set">
            <Grid>
              <Grid.Row className="join-community start-learning">
                  <Grid.Column className="innerboxx">
                      <h2 class="bottomImg"><span className="ImgBtm Big">Earn tokens</span> for creating</h2>
                      <h2> great content!</h2>
                      <p>Join our community of awesome Lunatic content creators and start earning token just
                         by writing about your favorite projects! Learn more NOW</p>
                         <div className="headerBtnBox position-relative">
                          <Button className="btn headerBtn px-3 mb-2 mb-lg-0">Join our community</Button>
                      </div>
                  </Grid.Column>
              </Grid.Row>  
            </Grid>
            <Grid>
              <Grid.Row columns={3} className="join-community start-learning">
                  <Grid.Column className="innerboxx">
                      <div className="blockWrap">
                          <Image src={firstBlock} />
                          <div className="blockText">
                              <h3>New technology</h3>
                              <p>By providing liquidity, you earn a percentage of the trading fees every time 
                                someone makes a trade, gains will sure...</p>
                              <div className="">
                                  <div className="d-flex">
                                      <Image src={DescImg} />
                                      <h4>Kristin Watson</h4>
                                  </div>
                              </div>
                          </div>  
                      </div>
                  </Grid.Column>
                  <Grid.Column className="innerboxx">
                      <div className="blockWrap middleBlock">
                          <Image src={secondBlock} />
                          <div className="blockText">
                              <h3>Ethereum </h3>
                              <p>By providing liquidity, you earn a percentage of the trading fees every time 
                                someone makes a trade, gains will sure...</p>
                              <div className="">
                                  <div className="d-flex">
                                      <Image src={Guy} />
                                      <h4>Guy Hawkins</h4>
                                  </div>
                              </div>
                          </div>  
                      </div>
                  </Grid.Column>
                  <Grid.Column className="innerboxx">
                      <div className="blockWrap">
                          <Image src={thirdBlock} />
                          <div className="blockText">
                              <h3>Gaming future</h3>
                              <p>By providing liquidity, you earn a percentage of the trading fees every time 
                                someone makes a trade, gains will sure...</p>
                              <div className="">
                                  <div className="d-flex">
                                      <Image src={Jane} />
                                      <h4>Jane Cooper</h4>
                                  </div>
                              </div>
                          </div>  
                      </div>
                  </Grid.Column>                                    
              </Grid.Row>  
            </Grid>            
              </Container>
          </div>
          */}
        {/*=========================== Earn Token Section start ==================== */}

        {/* ========================== Team Section ====================== */}

        <div className="bg-gradient-loop testimonial_slider">
          <Grid>
            <Grid.Row className="appDownload">
              <Grid.Column computer={16} mobile={16} tablet={16} className="appDownload_box">
                <h2>Team Members</h2>
                <p>Loop has a world-class team of over 30 experts across 4 continents</p>
              </Grid.Column>
              <Grid.Column computer={16} mobile={16} tablet={16} className="team-slider">
                <Swiper
                  className="swiper-full-mobile swiper-container-initialized swiper-container-horizontal"
                  spaceBetween={30}
                  slidesPerView={5}
                  navigation
                  loop
                  centeredSlides
                  slideToClickedSlide
                  breakpoints={{
                    768: {
                      freemode: true,
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },
                    640: {
                      freemode: true,
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    320: {
                      freemode: true,
                      slidesPerView: 1,
                      spaceBetween: 20,
                    }
                  }}
                  keyboard={{
                    enabled: true,
                    onlyInViewport: true,
                  }}
                //onSwiper={swiper => console.log(swiper)}
                //onSlideChange={() => console.log('slide change')}
                >
                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="/img/thomas.webp" className="memberImg" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>THOMAS NORWOOD <small>CEO</small></Card.Header>
                        <p>Tom is the head honcho at Loop Finance. He’s a serial entrepreneur with experience across multiple industries. He’s been building software for 20 years and blockchain products for five (and likes to write fiction novels on the side).</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/tom-norwood-64380ab3/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                          {/* <Image src="img/be.png" alt="" />
                          <Image src="img/facebook.png" alt="" />
                          <Image src="img/apple.png" alt="" />
                          <Image src="img/twitter.png" alt="" /> */}
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="img/simon.png" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>SIMON RAHME<small>Head of Partnerships</small></Card.Header>
                        <p>Simon is the co-Founder of Loop. He is a mastermind at bringing the blockchain to the real world. He has helped carry many projects to the finish line in addition to consulting Fortune 100 & FTSE 100 companies.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/smartercryptoapps/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                          {/* <Image src="img/be.png" alt="" />
                          <Image src="img/facebook.png" alt="" />
                          <Image src="img/apple.png" alt="" />
                          <Image src="img/twitter.png" alt="" /> */}
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>

                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="img/marcela.webp" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>MARCELA RODRIGUEZ <small>Portfolio Management</small></Card.Header>
                        <p>Marcela is in-charge of Loop’s financial plan.She has worked as a portfolio manager for government funds in the past and is highly knowledgeable at running businesses in different industries. She’s a natural entrepreneur.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/marcelarodriguezd/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                          {/* <Image src="img/be.png" alt="" />
                          <Image src="img/facebook.png" alt="" />
                          <Image src="img/apple.png" alt="" />
                          <Image src="img/twitter.png" alt="" /> */}
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <Card className="team_member">
                      <Image src="img/marco.png" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>MARCO TEBALDI<small>Product Manager</small></Card.Header>
                        <p>Marco prides himself in bringing a product to the customer that provides real value.He’s responsible for leading the build of Loop Markets; the first AMM DEX on Terra, a fully decentralised exchange on the blockchain.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/mtebaldi/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                  
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide> */}
                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="/img/chad.webp" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>CHAD STEPHENS<small>Head of Growth</small></Card.Header>
                        <p>Chad co-founded 1Form.com and grew it to have more than 90% market share in Aus before exiting to REA Group in 2014. He then co-founded Fillr.com, partnering with some of the largest FinTech companies globally, including Afterpay and Zip, before exiting to Rakuten Inc.in 2020. Chad is also an active angel investor and advisor.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/chadstephens1/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                          {/* <Image src="img/be.png" alt="" />
                          <Image src="img/facebook.png" alt="" />
                          <Image src="img/apple.png" alt="" />
                          <Image src="img/twitter.png" alt="" /> */}
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="/img/eric.png" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>Eric East<small>Lead Developer</small></Card.Header>
                        <p>Eric is a senior full stack blockchain developer. He helps businesses and agencies achieve high-quality websites and services through Blockchain.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/ericeastco" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="/img/alexNew.webp" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>Alex Bezen<small>Lead Mathematician</small></Card.Header>
                        <p>Alex is a brilliant mathematician focused on some of the most complex algorithms behind Loop’s product. He’s a consultant in mathematics, statistics, and IT.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/alex-bezen-700a1b60/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="/img/AndrewNew.webp" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>Andrew Singh Bogan<small>UK Barrister</small></Card.Header>
                        <p>Andrew's career began as a UK Barrister, first in the corporate sphere, then primarily pro bono for worthy causes (including cases cited as new precedent in Law Journals). Andrew has followed crypto ever since 2012 and, in May 2021, he was a contributing writer in the first major book published on DeFi and Yield Farming.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/tristanpollock/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Card className="team_member">
                      <Image src="/img/adamNew.webp" wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>Adam Cheshier<small>Head of Content and Social Media</small></Card.Header>
                        <p>Adam leads the content strategy for Loop’s thriving community of creators and users. He’s a prolific writer (part-time author) focused on impact-driven content for a number of nonprofit organizations.</p>
                        <Card.Meta className="social-menu-image">
                          <a href="https://www.linkedin.com/in/adamcheshier/" target="_blank">
                            <Image src="/linked.webp" alt="" />
                          </a>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </SwiperSlide>
                </Swiper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        {/* ========================== Team Section End ====================== */}

        {/* ========================== rewards Section ====================== */}

        {/* <div className="bg-gradient-loop cz-rewards-hub">
          <Container>
            <Grid className="loop-grid">
              <Grid.Row className="rewards-section cz-rewards">
                <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />
                <Image src="img/reward.png" className="rewa-bg" wrapped ui={false} />
                 <Grid.Column className="rewards-list" computer={7} mobile={16} tablet={7}>
                  <List link inverted>
                    <List.Item as="a"><b>1</b>Tokenized Reward</List.Item>
                    <List.Item as="a"><b>2</b>Great Articles</List.Item>
                    <List.Item as="a"><b>3</b>Crypto Exchange</List.Item>
                    <List.Item as="a"><b>4</b>Great Community</List.Item>
                    <List.Item as="a"><b>5</b>Our Own Economy</List.Item>
                    <List.Item as="a"><b>6</b>Build your own Loop</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column computer={9} mobile={16} tablet={9} className="innerboxx reward-bg">
                  <Image src="img/reward.png" className="rewa-bg" wrapped ui={false} />
                  <div className="rewards-text">
                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequa.</p>
                    <p>You may have heard of DeFi or Decentralized Finance? A DEX like Loop is part of this technology - built totally on the blockchain.</p>
                    <p>You retain control of your cryptocurrencies at all times - they are stored in your wallet (the TerraStation extension) which means you can never get banned or blocked and never lose access to your assets.</p>
                  </div>
                </Grid.Column> 
              </Grid.Row>
            </Grid>
          </Container>
        </div> */}

        {/* ========================== Newsletter Section ====================== */}

        {/* <div className="bg-gradient-loop newsletter-wrap">
          <Container>
            <Grid className="loop-grid">
              <Grid.Row className="newsletter-section">
                <Grid.Column className="newsletter-form" computer={7} mobile={16} tablet={10}>
                  <Header as="h2">Subscribe to our newsletter</Header>
                  <p>Never miss a single update from us and stay updated all the time.</p>
                  <MailchimpFormContainer email={this.state.email} handleOnChangeEmail={this.handleOnChangeEmail} handleSendEmail={this.handleSendEmail} />
                </Grid.Column>
                <Grid.Column computer={9} mobile={16} tablet={6} className="innerboxx newsletter-img">
                  <Image src="img/newsletter.png" wrapped ui={false} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div> */}
        {/* ========================== Exchange Section ====================== */}

        <div className={show ? "modal display-block modal-team " : "modal display-none modal-delete"}>
          <section className="modal-main">
            <span onClick={() => this.hideModal()}>+</span>
            <ReCAPTCHA
              theme="dark"
              ref={e => (this.captcha = e)}
              sitekey="6LfMp8gbAAAAAONc0qQfY6MfmXLPbCLkjd5kfaNJ"
              onChange={() => this.handleRecaptcha()}
            />
          </section>
        </div>

        <div className="bg-gradient-loop team-section">
          <Container className="cz-support">
            <Grid>
              <Grid.Row className="team-section">
                <Grid.Column computer={8} mobile={16} tablet={8} className="innerboxx">
                  <Header as="h6" className="textGradient h6">JOIN THE TEAM</Header>
                  <Header as="h2">We’re Expanding!</Header>
                </Grid.Column>

                <Grid.Column className="view_more" computer={8} mobile={16} tablet={8}>
                  <a onClick={() => this.showModal('Product%20Manager', 'email')}>Email us anyway <Image src="img/go-icon.png" /></a>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid columns={3} divided>
              <Grid.Row className="team-box">
                {/* <a href="mailto:jointheteam@loop.do?subject=Product%20Manager&body=Tell%20us%20why%20you’re%20the%20best,%20and%20how%20you’ll%20complement%20what%20we%20do.%0D%0DPlease%20attach%20your%20Git%20and%20Linkedin%20or%20a%20Resume.%0D%0DGood%20Luck!"> */}
                <Grid.Column className="innerboxx" onClick={() => this.showModal('Product%20Manager', 'job')}>
                  <div className="content">
                    <Header as="h4">Product Manager</Header>
                    <p className="description">We’re listening to the community and constantly adding more exciting products and features into the loop</p>
                    <List>
                      <List.Item>Full time</List.Item>
                    </List>
                  </div>
                </Grid.Column>
                {/* </a> */}

                <Grid.Column className="innerboxx" onClick={() => this.showModal('Pr%20and%20Partnerships', 'job')}>
                  <div className="content">
                    <Header as="h4">PR & Partnerships</Header>
                    <p className="description">Loop strives to partner with amazing projects and people - not reinvent the wheel & copy paste. Let’s work together!</p>
                    <List>
                      <List.Item>Full time</List.Item>
                    </List>
                  </div>
                </Grid.Column>

                <Grid.Column className="innerboxx" onClick={() => this.showModal('NFT%20Specialist', 'job')}>
                  <div className="content">
                    <Header as="h4">Blockchain Designer</Header>
                    <p className="description">Loop aims to push the boundaries of simplicity and function with the most creative UI & UX. What are your ideas?</p>
                    <List>
                      <List.Item>Full time</List.Item>
                    </List>
                  </div>
                </Grid.Column>
                <Grid.Column className="innerboxx" onClick={() => this.showModal('DevOps%20Engineer', 'job')}>
                  <div className="content">
                    <Header as="h4">DevOps Engineer</Header>
                    <p className="description">We’re growing quickly - we cant let our users down by being offline or not overdelivering on what we promise</p>
                    <List>
                      <List.Item>Full time</List.Item>
                      <List.Item>2 Positions</List.Item>
                    </List>
                  </div>
                </Grid.Column>

                <Grid.Column className="innerboxx" onClick={() => this.showModal('Rust%20Developers', 'job')}>
                  <div className="content">
                    <Header as="h4">Rust Developers</Header>
                    <p className="description">We have many ground breaking features on the way & even more incredible projects we help develop</p>
                    <List>
                      <List.Item>Full time</List.Item>
                      <List.Item>3 Positions</List.Item>
                    </List>
                  </div>
                </Grid.Column>

                <Grid.Column className="innerboxx" onClick={() => this.showModal('Full%20Stack%20Developer', 'job')}>
                  <div className="content">
                    <Header as="h4">Full Stack Developer</Header>
                    <p className="description">You’re a rare breed if you have solid Blockchain experience. We’d love to know what you’ve done on Terra</p>
                    <List>
                      <List.Item>Full time</List.Item>
                      <List.Item>2 Positions</List.Item>
                    </List>
                  </div>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Container>
        </div >

        {/* ========================== Footer ====================== */}
        <Footer />
        {/* ========================== Footer End ====================== */}


      </main >
    );
  }
}
export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        promos: state.articles.promos,
        isAuthorized: state.user.userIsLogged,
        userInfo: state.user.userInfo,
      };
    },
    dispatch => ({
      getPromos: () => dispatch(actions.getAllPromos())
    })
  )(Home)
);