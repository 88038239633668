import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Grid, Image } from 'semantic-ui-react';
import { withCookies } from 'react-cookie';
import ContentLoader from "react-content-loader"
import { setPageForGoogleAnalytics } from '../helperFunctions';
import * as feedActions from './feedActions';
import { setPostInfo } from '../../post/postActions';
import PostsList from './components/PostsList';

class Feed extends PureComponent {
  constructor(props) {
    super(props);
    this.defaultClickHandler = this.defaultClickHandler.bind(this);
    this.state = {
      currentPage: 0,
      tabNameSame: '',
      listener: false
    };
  }

  componentDidMount() {
    const { tabName } = this.props;
    setPageForGoogleAnalytics('articles');
    const el = document.querySelector('.app-body');
    el.addEventListener('scroll', this.onScroll);
    this.uploadNewPosts();
    this.setState({ tabNameSame: tabName, listener: true });
  }

  componentDidUpdate(props) {
    const { queryParams, isEnd, loadmore } = this.props;
    const { listener } = this.state;
    if (props.queryParams != queryParams) {
      this.queryParamsChanged();
    }
    if (loadmore === false) {
      const el = document.querySelector('.app-body');
      el.removeEventListener('scroll', this.onScroll);
    }
    if (isEnd && listener) {
      const el = document.querySelector('.app-body');
      el.removeEventListener('scroll', this.onScroll);
    }
    if (!isEnd && !listener) {
      const el = document.querySelector('.app-body');
      el.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    const { clearPosts } = this.props;
    clearPosts();
    const el = document.querySelector('.app-body');
    el.removeEventListener('scroll', this.onScroll);
  }

  onScroll = ({ target }) => {
    const { isLoading } = this.props;
    // localStorage.setItem('communityScroll', target.scrollTop)
    if (
      window.innerHeight + target.scrollTop >= target.scrollHeight - 4000
      && !isLoading
    ) {
      this.uploadNewPosts();
    }
  };

  queryParamsChanged() {
    const { clearPosts } = this.props;
    clearPosts();
    this.uploadNewPosts(1);
  }

  uploadNewPosts(page) {
    const { getPosts, cookies, queryParams, tabName, isEnd, isAuthorized } = this.props;
    const { tabNameSame, currentPage } = this.state;
    const pageName = window.location.pathname;
    const newPage = page ? page : tabNameSame != tabName ? 1 : currentPage + 1;

    isEnd ? this.setState({ listener: false }) : this.setState({ listener: true });

    this.setState({ currentPage: newPage, tabNameSame: tabName });
    //getPosts({ page: newPage, queryParams }, cookies.get('trybe_jwt'));
    getPosts({ page: newPage, queryParams }, cookies.get('trybe_jwt'));

    // const pageName = window.location.pathname;
    // if (pageName == '/community/') {
    //   let feedScroll = localStorage.getItem('communityScroll')
    //   console.log(feedScroll);
    //   console.log('helo wordl')
    //   if (feedScroll) {
    //     document.querySelector(".app-body").scrollTo(0, feedScroll)
    //   }
    // }
  }

  defaultClickHandler(postInfo) {
    const { setPost } = this.props;
    setPost(postInfo);
    //history.push(`/${postInfo.slug}`);
  }

  render() {
    const { postsData, clickHandler, isLoading, isEnd, noAuthor } = this.props;
    const pageName = window.location.pathname;
    //console.log(postsData)
    return (
      <Container className="feed cz-con-new pre_loader_grid">
        {pageName == '/'
          ? (
            <Grid>
              {postsData[0] ?
                (
                  <PostsList
                    className="posts-list"
                    posts={postsData}
                    isLoaderActive={isLoading}
                    noAuthor={noAuthor}
                    clickHandler={
                      clickHandler
                        ? clickHandler
                        : this.defaultClickHandler
                    }
                  />
                ) :
                <Grid.Row className="pre_loader_wrap">
                  <Grid.Column className="pre_loader_box">
                    <div class="ui segment">
                      <div class="ui active transition visible dimmer">
                        <div class="content">
                          <div class="ui loader"></div>
                        </div>
                      </div>
                      <Image src='https://react.semantic-ui.com/images/wireframe/image.png' size='full' />
                      <img src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" class="ui image" />
                      <img src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" class="ui image" />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="pre_loader_box">
                    <div class="ui segment">
                      <div class="ui active transition visible dimmer">
                        <div class="content">
                          <div class="ui loader"></div>
                        </div>
                      </div>
                      <Image src='https://react.semantic-ui.com/images/wireframe/image.png' size='full' />
                      <img src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" class="ui image" />
                      <img src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" class="ui image" />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="pre_loader_box">
                    <div class="ui segment">
                      <div class="ui active transition visible dimmer">
                        <div class="content">
                          <div class="ui loader"></div>
                        </div>
                      </div>
                      <Image src='https://react.semantic-ui.com/images/wireframe/image.png' size='full' />
                      <img src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" class="ui image" />
                      <img src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" class="ui image" />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid>
          )
          : (
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Container>
                    {postsData[0] ? (
                      <PostsList
                        className="posts-list"
                        posts={postsData}
                        isLoaderActive={isLoading}
                        noAuthor={noAuthor}
                        clickHandler={
                          clickHandler
                            ? clickHandler
                            : this.defaultClickHandler
                        }
                      />
                    ) : (
                      !isEnd && (
                        <div class="ui segment my-feeds-loader">
                          <div class="ui active dimmer">
                            <ContentLoader
                              speed={2}
                              style={{ width: '100%' }}
                              viewBox="0 0 476 124"
                              backgroundColor="#1b1b1b"
                              foregroundColor="#2f2f2f"
                            >
                              <rect x="45" y="20" rx="3" ry="3" width="90" height="4" />
                              <rect x="45" y="30" rx="3" ry="3" width="30" height="4" />
                              <rect x="10" y="61" rx="3" ry="3" width="200" height="4" />
                              <rect x="10" y="71" rx="3" ry="3" width="150" height="4" />
                              <rect x="10" y="81" rx="3" ry="3" width="100" height="4" />
                              <rect x="240" y="0" rx="0" ry="0" width="500" height="95" />
                              <circle cx="25" cy="25" r="15" />
                            </ContentLoader>
                          </div>
                        </div>
                      )
                    )}
                    {isLoading && (
                      <div class="ui segment my-feeds-loader">
                        <div class="ui active dimmer">
                          <ContentLoader
                            speed={2}
                            style={{ width: '100%' }}
                            viewBox="0 0 476 124"
                            backgroundColor="#1b1b1b"
                            foregroundColor="#2f2f2f"
                          >
                            <rect x="45" y="20" rx="3" ry="3" width="90" height="4" />
                            <rect x="45" y="30" rx="3" ry="3" width="30" height="4" />
                            <rect x="10" y="61" rx="3" ry="3" width="200" height="4" />
                            <rect x="10" y="71" rx="3" ry="3" width="150" height="4" />
                            <rect x="10" y="81" rx="3" ry="3" width="100" height="4" />
                            <rect x="240" y="0" rx="0" ry="0" width="500" height="95" />
                            <circle cx="25" cy="25" r="15" />
                          </ContentLoader>
                        </div>
                      </div>
                    )}
                    {noAuthor && (
                      <div style={{ textAlign: 'center', color: '#fff', padding: '20px' }}>
                        <h4>Error Loading Feed</h4>
                        <p>Sorry we seem to be having an issue loading your feed right now, please try again in a little while. <br /> If you feel this is a bug, please report the problem here - <a href="https://loopfinance.zendesk.com/hc/en-us/community/topics" target="_blank" style={{ color: "var(--pink)", fontWeight: "bolder" }}>Bugs &amp; Feedback</a></p>
                      </div>
                    )}
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
      </Container>
    );
  }
}
export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => ({
        isLoading: state.bountyFeed.loading,
        isEnd: state.bountyFeed.isEnd,
        noAuthor: state.bountyFeed.noAuthor,
        postsData: state.bountyFeed.posts,
        isAuthorized: state.user.userIsLogged,
        cookies: ownProps.cookies
      }),
      dispatch => ({
        getPosts: (params, token) => {
          dispatch(feedActions.getBountyPostsRoutine({ params, token }));
        },
        clearPosts: () => {
          dispatch(feedActions.clearBountyPostsRoutine());
        },
        setPost: (postInfo) => {
          dispatch(setPostInfo(postInfo));
        }
      })
    )(Feed)
  )
);
