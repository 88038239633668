import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Image,
  Card,
  Loader,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import * as actions from "../../actions/actions";

class CollectionsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenData: null,
      hideForm: false,
      show: false,
      tokenName: "",
    };
  }

  async componentDidMount() {
    const { getFeaturedNfts } = this.props;
    await getFeaturedNfts();
  }

  render() {
    const { featuredNfts, featuredNftsLoaded } = this.props;

    return (
      <>
        <div className="featured_collections">
          <div className="container">
            <h3>Trending Collections</h3>
            {featuredNftsLoaded ? (
              <Swiper
                navigation
                pagination
                loop
                autoplay={{ delay: 3500 }}
                centeredSlides
                slideToClickedSlide
                breakpoints={{
                  1440: {
                    freemode: true,
                    slidesPerView: 3,
                  },
                  1024: {
                    freemode: true,
                    slidesPerView: 3,
                  },
                  768: {
                    freemode: true,
                    slidesPerView: 2,
                  },
                  640: {
                    freemode: true,
                    slidesPerView: 1,
                  },
                  320: {
                    freemode: true,
                    slidesPerView: 1,
                  },
                }}
                keyboard={{
                  enabled: true,
                  onlyInViewport: true,
                }}
                //onSwiper={swiper => console.log(swiper)}
                //onSlideChange={() => console.log('slide change')}
              >
                {featuredNfts?.data.map((element, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Card
                        className="collection"
                        style={{
                          backgroundImage: `url(${process.env
                            .REACT_APP_DIRECTUS_BACKEND_ASSETS +
                            element.collection_image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center"
                        }}
                      >
                        <Card.Content>
                          <Card.Header>
                            <div className="collection_profile">
                              <div className="collection_details">
                                <p className="details">
                                  <Image
                                    className="project_image"
                                    src={
                                      process.env
                                        .REACT_APP_DIRECTUS_BACKEND_ASSETS +
                                      element.artist.artist_image
                                    }
                                    wrapped
                                    ui={false}
                                  />
                                  {element.collection_name}
                                  <br />
                                  <span>@{element.artist.name}</span>
                                </p>
                                <a href={`${element.url}`} target="_blank"><div className="nft_button">View</div></a>
                              </div>
                            </div>
                          </Card.Header>
                        </Card.Content>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <Loader active />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      featuredNftsLoaded: state.home.featuredNftsLoaded,
      featuredNfts: state.home.featuredNfts,
    }),
    (dispatch) => ({
      getFeaturedNfts: () => dispatch(actions.getFeaturedNfts()),
    })
  )(CollectionsSection)
);
