import React, { Component } from 'react';
import { Button, Grid, GridColumn, GridRow, Icon, Loader, Modal, Rating } from 'semantic-ui-react';
import moment from 'moment';
export default class RatingPopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ratings, ratingsLoading, closeRatingModal, ratingModalShow } = this.props;
    //TODO : Maps warning
    return (
      <Modal
        onClose={ratingModalShow}
        open={ratingModalShow}
        className="ratingModalList"
      >
        <Modal.Header>Ratings
        <Button
          floated="right"
          className="cancel-button loginCloseBtn"
          icon
          basic
          onClick={closeRatingModal}>
          <Icon name="close" />
        </Button></Modal.Header>
        <Modal.Content >
          <Modal.Description>
            <Grid columns={3} divided>
              <GridRow>
                {ratingsLoading && (
                  <Loader active intermediate />
                )}
                {ratings.map(item => (
                  <GridColumn>
                    <div className='ratingRepScore'>
                        <div className='userAvatar' style={{ backgroundImage: `url(${item.avatar})`}}></div>
                        <div className='userDetails'>
                          <h6>
                            <span class="username">{item.username}</span>
                            {/*<span className='pointsHightl'>{item.reputation_score}</span>*/}
                            {/* <span className='dots'></span>
                            {item.Loopr_reward} */}
                          </h6>
                          <p><Rating icon='star' defaultRating={item.rating} maxRating={5} disabled /><span className='dots'></span>{item.hours_ago && moment.utc(new Date(item.hours_ago * 1000)).local().fromNow().replace(/\w+[.!?]?$/, '')}</p>
                        </div>
                    </div>
                  </GridColumn>
                ))}
              </GridRow>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}