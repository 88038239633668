import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getFeaturedNfts, getHeaderData } from './api';

export const homeWatches = [getHeaderDataWatch, getFeaturedNftsWatch];

export function* getHeaderDataWatch() {
  yield takeLatest(actions.getHeaderData.TRIGGER, fetchHeader);
}
export function* getFeaturedNftsWatch() {
  yield takeLatest(actions.getFeaturedNfts.TRIGGER, fetchFeaturedNfts);
}

function* fetchHeader({ payload }) {
  const serverResponse = yield getHeaderData({payload});
  if (serverResponse) {
    yield put(actions.getHeaderData.success(serverResponse.data));
  } else {
    yield put(actions.getHeaderData.failure(serverResponse));
  }
}

function* fetchFeaturedNfts() {
  const serverResponse = yield getFeaturedNfts();
  if (serverResponse) {
    yield put(actions.getFeaturedNfts.success(serverResponse.data));
  } else {
    yield put(actions.getFeaturedNfts.failure(serverResponse));
  }
}
