import * as actions from './bountyActions';

const defaultState = {
  bountiesAreLoading: false,
  bounties: [],
  bountyInfo: {},
  bountyLoading: false
};

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {

    case actions.getBountyInfo.TRIGGER: {
      return { ...state, bountyLoading: true };
    }
    case actions.getBountyInfo.SUCCESS: {
      return { ...state, bountyInfo: payload, bountyLoading: false };
    }
    case actions.getBountyInfo.FAILURE: {
      return { ...state, bountyInfo: false, bountyLoading: false };
    }

    case actions.getBountyList.TRIGGER: {
      return { ...state, bountiesAreLoading: true };
    }

    case actions.getBountyList.SUCCESS: {
      //localStorage.setItem('loop_bounties', JSON.stringify(payload?.acf.featured_articles));
      return { ...state, bounties: payload, bountiesAreLoading: false };
    }

    case actions.getBountyList.FAILURE: {
      return { ...state, bountiesAreLoading: false };
    }

    default:
      return state;
  }
}
