import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Loader, Grid, Image, Button } from 'semantic-ui-react';
import moment from 'moment';
import CategoriesAndTags from './components/CategoriesAndTags';
import * as editorActions from '../util/dashboard/EditorActions';

class PostPreview extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if(this.props.location.pathname=='/preview/') {
      this.props.getPreviewArticle();
    }
    document.querySelector('.app-body').scrollTop = 0;
    const el = document.querySelector('.cz-single-article-container');
    el.addEventListener('scroll', this.onScroll);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { prevArticle, cookies, history } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    if (!prevArticle) {
        history.push('/404');
      }
  }

  async componentWillUnmount() {
    localStorage.getItem('previewArticle')
    const { clearPreviewArticle } = this.props;
    await clearPreviewArticle();
  }

  onScroll = ({ target }) => {
    console.log(window.innerHeight)
  };

  articleReadTime(content){
    const wpm = 225;
    const words = content?.trim().split(/\s+/).length;
    return Math.ceil(words / wpm);
  }

  render() {
    const {
      previewArticle,
      cookies,
      prevArticle,
      userInfo
    } = this.props;
    const {
      info,
      userInfo,
      history,
      following,
      cookies,
      previewArticle
    } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    if (!previewArticle) {
      history.push('/404');
      return <Loader active />;
    }
    const info = previewArticle;
    const { title, content, featuredImage, selectedCat, userName, userAvtar } = info;

    return (
      <Grid className="post-container cz-single-article">
          <div className="cz-single-article-container">
          <Grid.Row>
            <Grid.Column>
              {prevArticle ? (
                <Grid>
                    <Grid.Row className="sub-header cz-post-header-top">
                    <Grid.Column width="16">
                      <div className="post-header">
                        <h1>{title}</h1>
                      </div>
                      <Grid.Row>
                        <Grid.Column>
                          <CategoriesAndTags
                            categories={selectedCat}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      
                      <div className="post-date">
                        <div className="post-bar">
                        <div className="post-author">
                          <b style={{ marginRight: '25px', color: '#4DD4B6' }}>
                            <Image
                              src={userAvtar}
                              avatar
                              circular
                            />
                              Published By: {userName}
                          </b>
                          {userInfo.id && (
                            <Button
                              content={<><span className="plusIcon"><i class="fal fa-plus-circle"></i></span><span>{following ? 'Following' : 'Follow'}</span></>}
                              size="tiny"
                              disabled
                              className={following ? 'green-follow-button' : 'greenButton'}
                            />
                          )}
                          <span className="lightShade">{moment
                            .utc()
                            .local()
                            .format('MMMM DD, YYYY')}
                          </span>
                          <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-readtime.svg" alt=""/>
                          <span className="lightShade">{this.articleReadTime(content)} Min
                          </span>
                          <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-comment.svg" alt=""/>
                          <span className="lightShade">{0}</span>
                        </div>
                          <div className="post-author cz-post-mobile-only">
                            <b style={{ marginRight: '25px', color: '#4DD4B6' }}>
                              <Image
                                src={userAvtar}
                                avatar
                                circular
                              />
                                Published By: {userName}
                            </b>
                            {userInfo.id ? (
                              <Button
                                content={<><span className="plusIcon"><i class="fal fa-plus-circle"></i></span><span>{following ? 'Following' : 'Follow'}</span></>}
                                size="tiny"
                                disabled
                                className={following ? 'green-follow-button' : 'green-bordered-button'}
                              />
                            ) : (
                              <Button
                                content={'Join Community'}
                                disabled
                                size="tiny"
                                className="green-button"
                              />
                            )}
                            <span className="lightShade">
                              {moment
                                .utc()
                                .local()
                                .format('MMMM DD, YYYY')}
                            </span>
                            <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-readtime.svg" alt=""/>
                            <span className="lightShade">{this.articleReadTime(content)} Min
                            </span>
                            
                            <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-comment.svg" alt=""/>
                            <span className="lightShade">
                             0
                             </span>
                          </div>
                        </div>
                      </div>
                      </Grid.Column>
                    </Grid.Row>
                    {featuredImage && (
                      <Grid.Row className="cz-post-header-top">
                        <Grid.Column>
                          <div className="post-featured-image">
                            <Image
                              src={`${featuredImage}`}
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      )}
                    <Grid.Row className="cz-set-set">
                      <div
                        className="post"
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    </Grid.Row>
                  </Grid>
                ) : (
                  <Loader active={prevArticle} />
                )}
              </Grid.Column>
            </Grid.Row>
          </div>
        </Grid>
    );
  }
}
export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => {
        return {
          cookies: ownProps.cookies,
          userInfo: state.user.userInfo,
          previewArticle: state.editor.previewArticle,
          prevArticle: state.editor.prevArticle,
          isAuthorized: state.user.userIsLogged,
        };
      },
      dispatch => ({
        getPreviewArticle: () => {
          dispatch(editorActions.getPreviewArticleRoutine());
        },
        clearPreviewArticle: () => {
          dispatch(editorActions.clearPreviewArticleRoutine());
        },
        
      })
    )(PostPreview)
  )
);
