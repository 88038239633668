import axios from 'axios';
import catchAxiosError from '../util/catchAxiosError';

export function getAllCategories(payload) {
  return axios.get(
    `https://${process.env.REACT_APP_TRYBE_WP}/wp-json/wp/v2/categories?exclude=205&per_page=100&page=${payload}`
  );
}

export function getBountyList() {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/bounty/list`
  );
}

export function getTopEntries(id) {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/bounty/entries/${id}`
  );
}

export function getBountyInfo(payload) {
  const { token, id } = payload;
  return token
    ? axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/bounty/get/${id}`,
        { headers: { Authorization: 'Bearer ' + token } }
      )
      .catch(catchAxiosError)
    : axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/bounty/get/${id}`
      )
      .catch(catchAxiosError);
}

export function getBountyInfoBySlug(payload) {
  const { id } = payload;
  return axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/bounty/get/slug`,{id})
    .catch(catchAxiosError);
}


export async function getAllTerms() {
  const result = await axios
    .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v2/bounty/terms`,)
    .catch(catchAxiosError);
  return result.data;
}
