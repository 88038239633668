import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Button, Container, Grid, Button } from "semantic-ui-react";
import market from "../../../../../staticAssets/images/home/market.png";
import dex from "../../../../../staticAssets/images/home/dex.png";
import mp from "../../../../../staticAssets/images/home/icons/mp.svg";
import trade from "../../../../../staticAssets/images/home/icons/trade.svg";

class ServicesSection extends Component {
  render() {
    return (
      <>
        <div className="section section__one">
          <Container>
            <div className="services">
              <div className="service_item">
                <Image className="serviceImage" src={dex} />
                <div className="content">
                  <h4>Trade Tokens on Our Decentralized Exchange</h4>
                  <p hidden>
                    Samet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet.
                  </p>
                </div>
                <a href="https://juno.loop.markets/"><Button><Image className="icon" src={trade} /> Visit LOOP DEX</Button></a>
              </div>
              <div className="service_item">
                <Image className="serviceImage" src={market} />
                <div className="content">
                  <h4>Explore Our Amazing NFT Collections</h4>
                  <p hidden>
                    Samet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet.
                  </p>
                </div>
                <a href="https://nft-juno.loop.markets/"><Button><Image className="icon" src={mp} /> Visit Marketplace</Button></a>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(ServicesSection);
