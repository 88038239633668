import * as actions from './actions';

const defaultState = {
  headerData: [],
  featuredNfts: [],
  featuredNftsLoaded: false,
};

export default function reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case actions.getHeaderData.TRIGGER: {
      return {...state};
    }
    case actions.getHeaderData.SUCCESS: {
      return {...state, headerData: {...payload}};
    }

    case actions.getFeaturedNfts.TRIGGER: {
      return {...state};
    }
    case actions.getFeaturedNfts.SUCCESS: {
      return {...state, featuredNfts: {...payload}, featuredNftsLoaded: true};
    }

    default: {
      return state;
    }
  }
}
