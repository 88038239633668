import React, { Component } from 'react';
import { Container, Grid, Button, Image, Header, Card, List, Table, Tab, Menu, GridColumn, Segment } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import './Nft.scss';
import { setPageForGoogleAnalytics } from '../util/helperFunctions';

import * as actions from '../yourtrybe/articles/articlesActions';
import ReCAPTCHA from 'react-google-recaptcha';
import * as marketApi from '../pages/markets/Api';
import Footer from '../footer/Footer';
//import bannerImg from '../../../public/img/bannerImg.png';
import chromeImg from '../../staticAssets/images/themeImages/chromeImg.png';
import exchimg from '../../staticAssets/images/themeImages/marketPlaceImg.png';
import mousimg from '../../staticAssets/images/themeImages/mouse.png';
import graphImg from '../../staticAssets/images/themeImages/graphImg.png';
import bitCoin from '../../staticAssets/images/themeImages/bitCoin.png';
import ethereumIcon from '../../staticAssets/images/themeImages/ethereumIcon.png';
import chainIcon from '../../staticAssets/images/themeImages/chainIcon.png';
import xrpIcon from '../../staticAssets/images/themeImages/xrpIcon.png';
import tetherIcon from '../../staticAssets/images/themeImages/tetherIcon.png';
import LineBottom from '../../staticAssets/images/themeImages/LineBottom.png';
import IconsImage from '../../staticAssets/images/themeImages/icons.png';
import learnMore from '../../staticAssets/images/themeImages/learnMore.svg';
import exchangeImg from '../../staticAssets/images/themeImages/exchangeImg.png';
import bottomLine from '../../staticAssets/images/themeImages/bottomLine.png';
import thirdBlock from '../../staticAssets/images/themeImages/thirdBlock.png';
import secondBlock from '../../staticAssets/images/themeImages/secondBlock.png';
import firstBlock from '../../staticAssets/images/themeImages/firstBlock.png';
import DescImg from '../../staticAssets/images/themeImages/DescImg.png';
import bondImg from '../../staticAssets/images/themeImages/bondImg.png';
import accordianLine from '../../staticAssets/images/themeImages/accordianLine.svg';

class Nft extends Component {
  constructor(props) {
    SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
    super(props);
   // this.updateCategoriesQuery = Home.updateCategoriesQuery;
    this.state = {
      acfData: null,
      getPromos: '',
      currentAuthorQuery: [],
      currentCategoryQuery: [],
      accordinValue: 'accordionLoop',
      accordinValue1: 'mint',
      email: '',
      show: false,
      articlerecaptcha: false,
      jobLink: ''
    };
  }

  async componentDidMount() {

// Detect request animation frame
var scroll = window.requestAnimationFrame ||
             // IE Fallback
             function(callback){ window.setTimeout(callback, 1000/60)};
var elementsToShow = document.querySelectorAll('.show-on-scroll'); 

function loop() {

    Array.prototype.forEach.call(elementsToShow, function(element){
      if (isElementInViewport(element)) {
        element.classList.add('is-visible');
      } else {
        element.classList.remove('is-visible');
      }
    });

    scroll(loop);
}

// Call the loop for the first time
loop();

// Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
  // special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return (
    (rect.top <= 0
      && rect.bottom >= 0)
    ||
    (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight))
    ||
    (rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
  );
}

//Counter Js Start 

$('.count').each(function () {
  $(this).prop('Counter',0).animate({
      Counter: $(this).text()
  }, {
      duration: 9000,
      easing: 'swing',
      step: function (now) {
        $(this).text((Math.ceil(now)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      }
  });
});

$('.liquidityCount').each(function () {
  $(this).prop('Counter',0).animate({
      Counter: $(this).text()
  }, {
      duration: 9000,
      easing: 'swing',
      step: function (now) {
        $(this).text(now.toFixed(1));
      }
  });
});



//Counter Js Start 

    setPageForGoogleAnalytics('home');
    const { getPromos, preChosenCategories } = this.props;
    const promoCache = JSON.parse(localStorage.getItem('trybe_promos'));
    if (!promoCache) {
      await getPromos();
    } else {
      this.setState({ getPromos: promoCache });
      await getPromos();
    }
    //await api.getAuthors().then(acfData => this.setState({ acfData }));
    await marketApi.fetchTokenData().then(tokenData => this.setState({ tokenData }));
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { acfData, currentAuthorQuery } = this.state;
    if (acfData?.authors && currentAuthorQuery?.length === 0) {
      this.sortOfficalAuthor();
    }
  }

  sortOfficalAuthor() {
    const { acfData } = this.state;
    const query = acfData.authors.map(({ id }) => [id]);
    this.setState({ currentAuthorQuery: query });
  }

  handleOnChangeEmail = email => {
    this.setState({
      email: email
    })
  }

  handleSendEmail = email => {
    console.log(email)
  }

  showModal = (value, type) => {
    this.setState({ show: true });
    let url = ''
    if (type == 'job') {
      url = "mailto:jointheteam@loop.do?subject=" + value + "&body=Tell%20us%20why%20you’re%20the%20best,%20and%20how%20you’ll%20complement%20what%20we%20do.%0D%0DPlease%20attach%20your%20Git%20and%20Linkedin%20or%20a%20Resume.%0D%0DGood%20Luck!"
      this.setState({ jobLink: url });
    } else {
      url = "mailto:jointheteam@loop.do?subject=Why%20you%20guys%20need%20me&body=Tell%20us%20how%20you%20can%20take%20Loop%20to%20the%20next%20level"
      this.setState({ jobLink: url });
    }

  };

  hideModal = () => {
    this.setState({ show: false });
    window.grecaptcha.reset();
    this.setState({ articlerecaptcha: false })
    this.setState({ jobLink: '' });
  };

  openLink = () => {
    const { jobLink } = this.state
    window.open(jobLink, "_blank")
    this.hideModal();
  }

  handleRecaptcha = () => {
    const { articlerecaptcha } = this.state;
    this.setState({ articlerecaptcha: !articlerecaptcha })
    if (!articlerecaptcha) {
      this.openLink()
    }
  }

  static updateCategoriesQuery(chosenCategories) {
    const query = chosenCategories[0]
      ? chosenCategories.map(({ id }) => id).join(',')
      : null;
    return query;
  }

  activeAccordin(value) {
    const { accordinValue } = this.state
    if (accordinValue == value) {
      this.setState({ accordinValue: '' })
    } else {
      this.setState({ accordinValue: value })
    }
  };

  activeAccordin1(value) {
    const { accordinValue1 } = this.state
    if (accordinValue1 == value) {
      this.setState({ accordinValue1: '' })
    } else {
      this.setState({ accordinValue1: value })
    }
  };


  render() {
    const { currentCategoryQuery, currentAuthorQuery, tokenData, getPromos, accordinValue, accordinValue1, show, articlerecaptcha, jobLink } = this.state;
    const { promos, history } = this.props;
    const promoData = getPromos ? getPromos : promos;
    const panes = [
      { menuItem: <Menu.Item><span>1</span>Tokenized Rewards</Menu.Item>, render: () => <Tab.Pane><span>Unlike existing social networks, who keep all the money for themselves – we reward you for your contribution to Loop!</span><span>By creating amazing content on Loop you will be rewarded by the community in the LOOP token - our very own cryptocurrency that can be exchanged for any other currency that you choose.</span><span>The better your content the higher the reward! Start sharing your knowledge and earning today!</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>2</span>Great Articles</Menu.Item>, render: () => <Tab.Pane><span>Find great articles on a whole range of interesting topics and learn something new!</span><span>Keep up to date with topics of interest as our community scours the web for the most recent and relevant information and creates great content about it.</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>3</span>Crypto Exchange</Menu.Item>, render: () => <Tab.Pane><span>Use our decentralised exchange to swap all of your Terra assets.</span><span>Provide liquidity to our exchange and start earning fees from trades and from yield farming.</span><span>Stake your LOOP tokens and start earning staking rewards today!</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>4</span>Great Community</Menu.Item>, render: () => <Tab.Pane><span>Once upon a time, humans shared their knowledge, skills, and resources with their loop - that is what helped them to thrive.</span><span>In this day and age, it's more important than ever to be surrounded by the right people in order to succeed.</span><span>Join our rapidly growing network of forward thinking entrepreneurs, solopreneurs, cryptocurrency investors, ratbags, geniuses, and people who want to build a better life for ourselves and the world around us.</span></Tab.Pane> },
      { menuItem: <Menu.Item><span>5</span>Our Own Economy</Menu.Item>, render: () => <Tab.Pane><span>By incentivizing sharing of knowledge, skills, and resources through cryptocurrency rewards, we are not just building a community but our very own global, peer to peer economy-decentralized, and free from the tyranny and corruption of established governments and corporations.</span></Tab.Pane> },
      // { menuItem: `${6} Build Your Own Trybe`, render: () => <Tab.Pane><span>Would you like to start building your own community using our gamification and incentivized reward systems?</span><span>You choose the rules, and the rewards, based on the actions you want your users to make.</span><span>You can either use points and create reward tiers like a traditional loyalty program, or you can introduce your very own cryptocurrency token into the mix. TRVBE</span><span>Either way - we will help you get set up and started.</span></Tab.Pane> },
    ];
    return (

      <main className="homepage dexpage NftPage">
        <Helmet>
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-KBD87CK"></script>
        </Helmet>
        
      {/* ================   Hero section   =============== */}
<div className="homebg_section">

<div className="hero_banner_area">
  <Container>
    <Grid className="hero_section">
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as="h1" className="headerContent"> <span>LOOP</span> <span>The only LP NFT</span> Bonds in the world</Header>
          <Image className="line" src="img/headingLine.svg" alt="img" />
          <div className="ui list">
            <div className="item"><a>Earn</a></div>
            <div className="item"><a>Exchange</a></div>
            <div className="item"><a>Create</a></div>
            <div className="item"><a>DeFi</a></div>
          </div>
           <div className="main_action_btn cz-home-set-icons">
            <div className="headerBtnBox position-relative trade-btns-cus">
              <Button className="btn headerBtn loopBtn px-3 mb-2 mb-lg-0" onClick={() => history.push('/sign-up')}>Start Earning LOOP Tokens</Button>
              {/* <Button className="joinBtn">Learn More <img src="img/learnMore.svg" alt="" class="ui image" /></Button> */}
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

</Container>
     
</div> 
  {/* RoadMap Section End */}  
{/* ================   Hero Section End  =============== */}

<div className="dexcounter-sec nftCounter">
<Container>
  <Grid.Row columns={1}>
        <Grid.Column>
            <div className="counterWrap dex-counter">
                <ul>
                  <li><div id="shiva"><span><span className="count">800</span>M</span></div>
                    <p>TRANSACTIONS</p>
                  </li>
                  <li><div id="shiva"><span><span className="liquidityCount">1.3</span>B</span></div>
                    <p>LIQUIDITY</p>
                  </li>
                  <li><div id="shiva"><span className="count">24</span></div>
                    <p>TRADING PAIRS</p>
                  </li>
                  <li><div id="shiva"><span className="count">75000</span>+</div>
                    <p>USERS</p>
                  </li>
                </ul>

            </div>
    </Grid.Column>
  </Grid.Row> 
</Container>
</div>



{/* */}




        <div className="bg-gradient-loop  community_Tab partners community_dex decentralised-sec" id="DEX">
          <Container>
            <Grid>
              <Grid.Row className="our_partner">
                <Grid.Column className="yield NftmarketWrap" computer={7} mobile={16} tablet={9}>
                  <Header as="h2" className="Farming bottomLine"><span className="ImgBtm">NFT</span> Marketplace</Header>
                  <p>Mint & trade your favourite collectables</p>
                  <div className="accordion position-relative" id="accordionLoop">
                    <div className={accordinValue == 'accordionLoop' ? 'accordion-itemActive accordion-item' : 'accordion-item'}>
                      <h2 className="accordion-header" id="headingOne">
                        <button className="" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { this.activeAccordin('accordionLoop'); }}>
                          <span>1</span> Browse
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionLoop">
                        <div className="accordion-body">
                            New NFTs are auctioned and existing NFTs are traded all on the Loop NFT Marketpalce
                        </div>
                      </div>
                    </div>
                    <div className={accordinValue == 'headingTwo' ? 'accordion-itemActive accordion-item' : 'accordion-item'}>
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { this.activeAccordin('headingTwo'); }}>
                          <span>2</span> Earn Yield
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionLoop">
                        <div className="accordion-body">
                          Farm your favourite pairs
                        </div>
                      </div>
                    </div>
                    <div className={accordinValue == 'headingThree' ? 'accordion-itemActive accordion-item' : 'accordion-item'}>
                      <h2 className="accordion-header" id="headingThree">
                        <button className="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { this.activeAccordin('headingThree'); }}>
                          <span>3</span> Auction
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionLoop">
                        <div className="accordion-body">
                          No lock in periods, withdraw anytime
                        </div>
                      </div>
                    </div>
                    <div className={accordinValue == 'headingFour' ? 'accordion-itemActive accordion-item' : 'accordion-item'}>
                      <h2 className="accordion-header" id="headingFour">
                        <button className="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { this.activeAccordin('headingFour'); }}>
                          <span>4</span> Mint
                        </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionLoop">
                        <div className="accordion-body">
                          We have deep liquidity pools, add to them and start earning
                        </div>
                      </div>
                    </div>
                    <img className="accordianLine" src={accordianLine} alt="" />
                  </div>
                  <a href="https://dex.loop.markets" className="mb-0 d-flex align-items-center learnMore"> Buy Now <Image src={learnMore} alt="" /></a>
                </Grid.Column>
                <Grid.Column computer={9} mobile={16} tablet={7} className="">
                  {/* <Image className="img-fluid mob_show" src="/dex_img_mob.png" alt="" /> */}
                  <Image className="img-fluid mob_show" src={exchimg} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>


{/* */}



{/* NFT Marketplace Section Start */}
{/* <div className="marketSection decentralised-sec">
        <Container-fuild>
              <Grid className="ecoSystem inline-photo show-on-scroll">
              <Grid.Row columns={1}>
                  <Grid.Column>
                      <div className="marketInner">
                      <Grid className="ecoSystem">
                      <Grid.Row columns={2}>
                  <Grid.Column>
                      <div className="NftmarketWrap">
                          <h2 class="bottomLine">NFT Marketplace</h2>
                          <p>Mint & trade your favourite collectables</p>  
                          <div class="earn-wrap">
                              <div class="earn-img">
                                  <Image src={mousimg}/>
                              </div>
                              <div class="earn-maincont">
                                <div class="earn-right-cont">
                                    <div class="number"><p>1</p></div>
                                    <div class="point-des">
                                      <h3>Browse</h3>
                                      <p>New NFTs are auctioned and existing NFTs are traded all on the Loop NFT Marketpalce</p>
                                    </div>
                                </div>
                                <div class="earn-right-cont-close">
                                    <div class="number"><p>2</p></div>
                                    <div class="point-des">
                                      <h3>Earn Yield</h3>
                                    </div>
                                </div>
                                <div class="earn-right-cont-close">
                                    <div class="number"><p>3</p></div>
                                    <div class="point-des">
                                      <h3>Auction</h3>
                                    </div>
                                </div>
                                <div class="earn-right-cont-close">
                                    <div class="number"><p>4</p></div>
                                    <div class="point-des">
                                      <h3>Mint</h3>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <button class="ui button joinBtn" role="button">Buy Now <img src={learnMore} alt="" class="ui image"/></button>
                      </div>
                  </Grid.Column>
                  <Grid.Column className="exchimgRight">
                    <div className="exchimg">
                      <Image src={exchimg} />
                    </div>
                  </Grid.Column>                  
                    </Grid.Row>   
                    </Grid>                     
                      </div>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
      </Container-fuild>
    </div> */}
  {/* NFT Marketplace Section End */} 
{/* WORLD FIRST Section Start */}         
<div className="ourPartnerSection">
  <Container>
    <Grid className="hero_section">
      <Grid.Row columns={2} className="align-items-center">
        <Grid.Column>
            <div className="partnerText WorldFirstText">
              <h5>WORLD FIRST</h5>
              <div className="HeadingWrap">
              <h2>LP NFT Bonds</h2>
              <Image className="line" src={bottomLine} alt="img" />
              </div>
              <p>Loop is continually innovating in all spaces. We have given more utility and security to NFTs by turning them into
                 annuities/bonds with fixed yield over a specific timeframe.</p>
              <p>Loop offers this product to new projects looking to launch their liquidity the smart way and already has several
                 projects lined up.</p>
                {/* <Button className="joinBtn">List your token <img src={learnMore} alt="" class="ui image" /></Button> */}
            </div>
        </Grid.Column>
        <Grid.Column>
            <div className="ImgWrap">
              <Image src={IconsImage} alt="img" />
            </div>
        </Grid.Column>
       </Grid.Row> 
    </Grid>
  </Container>
</div>   
 {/* WORLD FIRST Section End */} 

        {/*=========================== Earn Token Section start ==================== */}
        <div className="bg-gradient-loop EarnToken LatestBlocks">
        <Container className="cz-support cz-support-set">
            <Grid>
              <Grid.Row className="join-community start-learning">
                  <Grid.Column className="innerboxx">
                      <h2 class="bottomImg">Latest LP NFTs</h2>
                      <p>Join our community of awesome Lunatic content creators and start earning token just by writing
                         about your favorite projects! Learn more NOW</p>
                         <div className="headerBtnBox position-relative">
                          <Button className="btn headerBtn px-3 mb-2 mb-lg-0">Join our community</Button>
                      </div>
                  </Grid.Column>
              </Grid.Row>  
            </Grid>
            <Grid>
              <Grid.Row columns={3} className="join-community start-learning">
                  <Grid.Column className="innerboxx">
                      <div className="blockWrap">
                          <Image src={firstBlock} />
                          <div className="blockText">
                              <h3>New technology</h3>
                              <p>By providing liquidity, you earn a percentage of the trading fees every time 
                                someone makes a trade, gains will sure...</p>
                              <div className="">
                                  <div className="d-flex">
                                      <Image src={DescImg} />
                                      <h4>Kristin Watson</h4>
                                  </div>
                              </div>
                          </div>  
                      </div>
                  </Grid.Column>
                  <Grid.Column className="innerboxx">
                      <div className="blockWrap middleBlock">
                          <Image src={secondBlock} />
                          <div className="blockText">
                              <h3>Ethereum </h3>
                              <p>By providing liquidity, you earn a percentage of the trading fees every time 
                                someone makes a trade, gains will sure...</p>
                              <div className="">
                                  <div className="d-flex">
                                      <Image src={DescImg} />
                                      <h4>Kristin Watson</h4>
                                  </div>
                              </div>
                          </div>  
                      </div>
                  </Grid.Column>
                  <Grid.Column className="innerboxx">
                      <div className="blockWrap">
                          <Image src={thirdBlock} />
                          <div className="blockText">
                              <h3>Gaming future</h3>
                              <p>By providing liquidity, you earn a percentage of the trading fees every time 
                                someone makes a trade, gains will sure...</p>
                              <div className="">
                                  <div className="d-flex">
                                      <Image src={DescImg} />
                                      <h4>Kristin Watson</h4>
                                  </div>
                              </div>
                          </div>  
                      </div>
                  </Grid.Column>                                    
              </Grid.Row>  
            </Grid>            
              </Container>
          </div>

          {/*=========================== Earn Token Section start ==================== */}

{/* Trade Terra Section Start */}
      {/* <div className="hero_banner_area tradeTerraSection">
            <Container>
              <Grid className="hero_section">
                <Grid.Row columns={2} className="align-items-center">
                  <Grid.Column>
                    <div className="main_action_btn cz-home-set-icons"> 
                    
                        <div className="topIcons">
                            <div className="iconsInner">
                              <h3>Top 5</h3>
                              <div className="iconProgress">
                                    <ul className="activehover">
                                        <li className="iconTxtWrp"><div className="">
                                        <Image src={bitCoin} />
                                          </div>
                                          <div className="imgTxt">
                                            <h5>bitCoin</h5>
                                            <p>LP</p>
                                          </div>
                                          </li>
                                          <li className="grphImg">
                                           <Image src={graphImg} />
                                          </li>
                                          <li className="rankHigh">
                                            <h5>$00.10</h5>
                                            <p><i class="fa fa-caret-up" aria-hidden="true"></i> 100%</p>
                                          </li>
                                    </ul>
                                    <ul>
                                        <li className="iconTxtWrp"><div className="">
                                        <Image src={ethereumIcon} />
                                          </div>
                                          <div className="imgTxt">
                                            <h5>Ehtereum</h5>
                                            <p>ETH</p>
                                          </div>
                                          </li>
                                          <li className="grphImg">
                                           <Image src={graphImg} />
                                          </li>
                                          <li className="rankHigh">
                                            <h5>$00.10</h5>
                                            <p><i class="fa fa-caret-up" aria-hidden="true"></i> 100%</p>
                                          </li>
                                    </ul>
                                    <ul>
                                        <li className="iconTxtWrp"><div className="">
                                        <Image src={chainIcon} />
                                          </div>
                                          <div className="imgTxt">
                                            <h5>Chainlink</h5>
                                            <p>LINK</p>
                                          </div>
                                          </li>
                                          <li className="grphImg">
                                           <Image src={graphImg} />
                                          </li>
                                          <li className="rankHigh">
                                            <h5>$00.10</h5>
                                            <p><i class="fa fa-caret-up" aria-hidden="true"></i> 100%</p>
                                          </li>
                                    </ul>
                                    <ul>
                                        <li className="iconTxtWrp"><div className="">
                                        <Image src={xrpIcon} />
                                          </div>
                                          <div className="imgTxt">
                                            <h5>XRP</h5>
                                            <p>XRP</p>
                                          </div>
                                          </li>
                                          <li className="grphImg">
                                           <Image src={graphImg} />
                                          </li>
                                          <li className="rankHigh">
                                            <h5>$00.10</h5>
                                            <p><i class="fa fa-caret-up" aria-hidden="true"></i> 100%</p>
                                          </li>
                                    </ul>    
                                    <ul>
                                        <li className="iconTxtWrp"><div className="">
                                        <Image src={tetherIcon} />
                                          </div>
                                          <div className="imgTxt">
                                            <h5>Tether</h5>
                                            <p>USDT</p>
                                          </div>
                                          </li>
                                          <li className="grphImg">
                                           <Image src={graphImg} />
                                          </li>
                                          <li className="rankHigh">
                                            <h5>$00.10</h5>
                                            <p><i class="fa fa-caret-up" aria-hidden="true"></i> 100%</p>
                                          </li>
                                    </ul>                                                                                                                                              
                                </div>
                            </div>
                         </div>
                        
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="tradeTerra">
                      <h5>TOP PERFORMERS</h5>
                      <div className="HeadingWrap">
                        <Header as="h1" className="headerContent">Trade all the top assets on Terra</Header>
                        <Image className="line" src={LineBottom} alt="img" />
                      </div>
                      <p>We have deep liquidiity across Terra's top assets to bring you the best prices, even for larger traders</p>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

            </Container>
        </div> */}
{/* Trade Terra Section ENd */}
    
 {/* Chrome Extension Section Start */}         
<div className="ourPartnerSection ExtensionSection">
  <Container>
    <Grid className="hero_section">
      <Grid.Row columns={2} className="align-items-center">
      <Grid.Column>
            <div className="ImgWrap">
              <Image src={chromeImg} alt="img" />
            </div>
        </Grid.Column>        
        <Grid.Column>
            <div className="partnerText">
              <div className="HeadingWrap wallet">
              <h2>Chrome Extension & Mobile App <span className="ImgBtm">Wallets</span></h2>
            
              </div>
              <p>We heard what you asked for - 1 place to easily manage your coins and NFTs on Terra and beyond...</p>
              <ul>
                  <li>Summarise your wealth</li> 
                  <li>View Loop community articles</li>
                  <li>Buy & trade NFTs?</li>
                  <li>Access DEX</li>
                  <li>All your wallets in 1 place</li>
                  <li>One touch DeFi products</li>
                  <li>Access to early projects</li>
                </ul>
                <p>DeFi is disrupting the banking industry</p>
                {/* <Button className="joinBtn">Discover <img src={learnMore} alt="" class="ui image" /></Button> */}
            </div>
        </Grid.Column>
       </Grid.Row> 
    </Grid>
  </Container>
</div>   
 {/* Chrome Extension Section End */}    

 {/* Exchange Section Start */}         
 {/* <div className="marketSection exchangeSection">
              <Container>
              <Grid className="ecoSystem inline-photo show-on-scroll">
              <Grid.Row columns={1}>
                  <Grid.Column>
                      <div className="marketInner">
                      <Grid className="ecoSystem">
                      <Grid.Row columns={2}>
                  <Grid.Column>
                      <div className="exchangeContent">
                          <h2>Why a Decentralized Exchange?</h2>
                          <Image className="bottomLine" src={bottomLine} alt="img" />
                          <p>Decentralized exchanges (DEX's) like Loop work totally differently from a centralized exchange like Coinbase or Binance.</p>  
                          <p>You may have heard of "DeFi" or Decentralized Finance? A DEX like Loop is part of this technology - built totally on the blockchain.</p>
                          <p>You retain control of your cryptocurrencies at all times - they're stored in your wallet (the TerraStation extension) which means you can never get banned or blocked and never lose access to your assets.</p>
                          <p>Learn more about why and how to create your own crypto wallet like Terra Station here.</p>
                          <Button className="btn headerBtn px-3 mb-2 mb-lg-0">Tutorials</Button>
                      </div>
                  </Grid.Column>
                  <Grid.Column className="nftImgRight">
                    <div className="nftImg">
                      <Image src={exchangeImg} />
                    </div>
                  </Grid.Column>                  
                    </Grid.Row>   
                    </Grid>                     
                      </div>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
              </Container>
            </div>    */}
 {/* Exchange Section End */}   


{/* NFT Marketplace Section Start */}
<div className="marketSection decentralised-sec BondSection">
  <Container-fuild>
              <Grid className="ecoSystem inline-photo show-on-scroll">
              <Grid.Row columns={1}>
                  <Grid.Column>
                      <div className="marketInner">
                      <Grid className="ecoSystem">
                      <Grid.Row columns={2}>
                  <Grid.Column className="leftSideCol">
                      <div className="partnerText WorldFirstText">
                          <h5>NFT BONDS</h5>
                          <h2>Mint NFT Bonds</h2>
                          <p>Loop is continually innovating in all spaces. We have given more utility and security to NFTs by
                             turning them into annuities/bonds with fixed yield over a specific timeframe.</p>
                           <p>Loop offers this product to new projects looking to launch their liquidity the smart way and 
                             already has several projects lined up.</p>
                      </div>
                  </Grid.Column>
                  <Grid.Column className="exchimgRight rightSideCol">
                    <div className="exchimg">
                      <Image src={bondImg} />
                    </div>
                  </Grid.Column>                  
                    </Grid.Row>   
                    </Grid>
                      </div>
                  </Grid.Column>
                </Grid.Row>
                </Grid>   
  </Container-fuild>
</div>
  {/* NFT Marketplace Section End */} 




</div>



        {/* ========================== We’re Expanding Section ====================== */}

        <div className={show ? "modal display-block modal-team " : "modal display-none modal-delete"}>
          <section className="modal-main">
            <span onClick={() => this.hideModal()}>+</span>
            <ReCAPTCHA
              theme="dark"
              ref={e => (this.captcha = e)}
              sitekey="6LfMp8gbAAAAAONc0qQfY6MfmXLPbCLkjd5kfaNJ"
              onChange={() => this.handleRecaptcha()}
            />
          </section>
        </div>

        <div className="bg-gradient-loop team-section ExpandSection">
          <Container className="cz-support">
            <Grid>
              <Grid.Row className="team-section">
                <Grid.Column computer={8} mobile={16} tablet={8} className="innerboxx">
                  <Header as="h6" className="textGradient h6">JOIN THE TEAM</Header>
                  <Header as="h2">We’re Expanding!</Header>
                </Grid.Column>

                <Grid.Column className="view_more" computer={8} mobile={16} tablet={8}>
                  <a onClick={() => this.showModal('Product%20Manager', 'email')}>Email us anyway <Image src="/img/go-icon.png" /></a>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid columns={3} divided>
              <Grid.Row className="team-box">
                {/* <a href="mailto:jointheteam@loop.do?subject=Product%20Manager&body=Tell%20us%20why%20you’re%20the%20best,%20and%20how%20you’ll%20complement%20what%20we%20do.%0D%0DPlease%20attach%20your%20Git%20and%20Linkedin%20or%20a%20Resume.%0D%0DGood%20Luck!"> */}
                <Grid.Column className="innerboxx" onClick={() => this.showModal('Product%20Manager', 'job')}>
                  <div className="content">
                    <Header as="h4">Product Manager</Header>
                    <p className="description">We’re listening to the community and constantly adding more exciting products and features into the loop</p>
                    <List>
                      <List.Item>Full time</List.Item>
                    </List>
                  </div>
                </Grid.Column>
                {/* </a> */}

                <Grid.Column className="innerboxx" onClick={() => this.showModal('Pr%20and%20Partnerships', 'job')}>
                  <div className="content">
                    <Header as="h4">PR & Partnerships</Header>
                    <p className="description">Loop strives to partner with amazing projects and people - not reinvent the wheel & copy paste.Let’s work together!</p>
                    <List>
                      <List.Item>Full time</List.Item>
                    </List>
                  </div>
                </Grid.Column>

                <Grid.Column className="innerboxx" onClick={() => this.showModal('NFT%20Specialist', 'job')}>
                  <div className="content">
                    <Header as="h4">Blockchain Designer</Header>
                    <p className="description">Loop aims to push the boundaries of simplicity and function with the most creative UI & UX. What are your ideas?</p>
                    <List>
                      <List.Item>Full time</List.Item>
                    </List>
                  </div>
                </Grid.Column>

                <Grid.Column className="innerboxx" onClick={() => this.showModal('DevOps%20Engineer', 'job')}>
                  <div className="content">
                    <Header as="h4">DevOps Engineer</Header>
                    <p className="description">We’re growing quickly - we cant let our users down by being offline or not overdelivering on what we promise</p>
                    <List>
                      <List.Item>Full time</List.Item>
                      <List.Item>2 Positions</List.Item>
                    </List>
                  </div>
                </Grid.Column>

                <Grid.Column className="innerboxx" onClick={() => this.showModal('Rust%20Developers', 'job')}>
                  <div className="content">
                    <Header as="h4">Rust Developers</Header>
                    <p className="description">We have many ground breaking features on the way & even more incredible projects we help develop</p>
                    <List>
                      <List.Item>Full time</List.Item>
                      <List.Item>3 Positions</List.Item>
                    </List>
                  </div>
                </Grid.Column>

                <Grid.Column className="innerboxx" onClick={() => this.showModal('Full%20Stack%20Developer', 'job')}>
                  <div className="content">
                    <Header as="h4">Full Stack Developer</Header>
                    <p className="description">You’re a rare breed if you have solid Blockchain experience.We’d love to know what you’ve done on Terra</p>
                    <List>
                      <List.Item>Full time</List.Item>
                      <List.Item>2 Positions</List.Item>
                    </List>
                  </div>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Container>
        </div >





        {/* ========================== Footer ====================== */}

        <div className="bg-gradient-loop footer_wrap">
          <Container>
            <Grid className="loop-grid">
              <Grid.Row className="footer-section">
                <Grid.Column className="about-company" computer={5} mobile={16} tablet={7}>
                  <Image src="/img/logo-header.png" wrapped ui={false} alt="loop-Logo" className="footerLogo" />
                  <p>The only AMM DEX on Terra with incentivised liquidity pools!</p>
                  <small>© Loop 2022 All Rights Reserved</small>
                </Grid.Column>
                {/* <Grid.Column className="link-design" computer={3} mobile={16} tablet={7}>
                  <Header as="h2">Quick Links</Header>
                  <List link inverted>
                    <List.Item as="a">Privacy Policy</List.Item>
                    <List.Item as="a">Features</List.Item>
                    <List.Item as="a">How it works</List.Item>
                  </List>
                </Grid.Column> */}
                <Grid.Column className="address-design" computer={3} mobile={16} tablet={7}>
                  <Header as="h2">Contact Info</Header>
                  <p><span><Image src="/img/mail.svg" wrapped ui={false} className="mail-icon" /></span><a href="mailto: info@loop.do">info@loop.do</a></p>
                  <p><span><Image src="/img/loc.svg" wrapped ui={false} className="loc-icon" /></span><a href="https://www.google.com/maps/place/Atrium+Tower/@8.9846348,-79.5237687,17z/data=!3m1!4b1!4m5!3m4!1s0x8faca8fb537b46a7:0xed03d8f6c25c5b22!8m2!3d8.9846348!4d-79.52158" target="_blank">27th floor, Atrium Tower, Obarrio, Panama City,<br /> Republic of Panama</a></p>
                </Grid.Column>


                 <Grid.Column computer={5} mobile={16} tablet={9} className="innerboxx newsletter-img">
                  <Swiper pagination className="mySwiper">
                    <SwiperSlide>
                      <div className="tesi-design">
                        <p><Image src="/img/q.svg" wrapped ui={false} className="q-icon" /><Image src="/img/q.svg" wrapped ui={false} className="q-icon q-icon1" /><span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</span></p>
                        <Image src="/img/twitter.svg" wrapped ui={false} className="twitter-icon" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="tesi-design">
                        <p><Image src="/img/q.svg" wrapped ui={false} className="q-icon" /><Image src="/img/q.svg" wrapped ui={false} className="q-icon q-icon1" /><span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</span></p>
                        <Image src="/img/twitter.svg" wrapped ui={false} className="twitter-icon" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="tesi-design">
                        <p><Image src="/img/q.svg" wrapped ui={false} className="q-icon" /><Image src="/img/q.svg" wrapped ui={false} className="q-icon q-icon1" /><span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</span></p>
                        <Image src="/img/twitter.svg" wrapped ui={false} className="twitter-icon" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="tesi-design">
                        <p><Image src="/img/q.svg" wrapped ui={false} className="q-icon" /><Image src="/img/q.svg" wrapped ui={false} className="q-icon q-icon1" /><span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</span></p>
                        <Image src="/img/twitter.svg" wrapped ui={false} className="twitter-icon" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Grid.Column> 
              </Grid.Row>
            </Grid>
          </Container>
        </div>

        {/* ========================== Footer End ====================== */}









      </main >
    );
  }
}
export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        promos: state.articles.promos,
      };
    },
    dispatch => ({
      getPromos: () => dispatch(actions.getAllPromos())
    })
  )(Nft)
);