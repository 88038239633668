import React, { PureComponent, useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Loader, Container, Grid, Image, Rating, Tab, Divider, Icon, Button, Header, Pagination, Confirm, Popup, GridRow, Modal, TextArea } from 'semantic-ui-react';
import he from 'he';
import moment from 'moment';
import Helmet from 'react-helmet';
import { Twitter, Facebook, Whatsapp, Telegram, Linkedin, Reddit, Mail } from 'react-social-sharing';
import * as api from '../user-review/userReviewActions';
import PostsList from '../util/feed/components/PostsList';
import * as actions from './postActions';
import * as feedActions from '../util/feed/feedActions';
import { follow, unfollow } from '../yourtrybe/following/FollowingActions';
import CategoriesAndTags from './components/CategoriesAndTags';
import CommentsList from './components/CommentsList';
import AddComment from './components/AddComment';
import PostEngagement from './postEngagement';
import RatingPopup from '../util/feed/components/RatingPopup';

const trybeIcon = '/img/loop-subtract.svg';

let interval;

class Post extends PureComponent {
  constructor(props) {
    super(props);
    this.publishReply = this.publishReply.bind(this);
    this.editComment = this.editComment.bind(this);
    this.reportComment = this.reportComment.bind(this);
    this.upVoteComment = this.upVoteComment.bind(this);
    this.reportSpamComment = this.reportSpamComment.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
    this.defaultClickHandler = this.defaultClickHandler.bind(this);
    this.showReportCommentDialog = this.showReportCommentDialog.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCuratePost = this.handleCuratePost.bind(this);
    this.closeRatingModal = this.closeRatingModal.bind(this);
    this.state = {
      activePage: 1,
      tipAmount: 500,
      tipHidden: true,
      show: false,
      open: false,
      openVoteConfirm: false,
      reportedCommentId: [],
      reportCommentJson: {
        commentId: null,
        reportCommentMessage: null,
      },
      startTime: null,
      saveReadTime: false,
      ratingTimer: 30,
      windowTextSelection: '',
      highlightText: '',
      report_reason: '',
      ratingModalShow: false
    };
    this.addCommentRef = React.createRef()
  }

  componentDidMount() {
    const { ratingTimer } = this.state;
    interval = setInterval(() => {
      this.setState({ ratingTimer: --ratingTimer })
    }, 1000);
    this.fetchData();
    document.querySelector('.app-body').scrollTop = 0;
    const el = document.querySelector('.cz-single-article-container');
    el.addEventListener('scroll', this.onScroll);
  }

  fetchData = async () => {
    const { getPostInfo, getPostComments, location, info, cookies, resetCommentSuccess } = this.props;
    const { activePage } = this.state;
    const token = cookies.get('trybe_jwt', { path: '/' });
    resetCommentSuccess();
    if (!info.id) {
      const id = location.pathname;
      await getPostInfo({ id });
    } else {
      const id = location.pathname;
      id && await getPostInfo({ id });
      id && await this.getRating(id);
      id && await getPostComments({ post: info.id, page: activePage, token });
    }
    
  }

  async componentDidUpdate(prevProps, prevState) {
    const { subscriptionSuccessfull, info, getPostComments, history, ratingSending, getAuthorPost, userInfo, amIFollowing, cookies, getRatings } = this.props;
    const { id, title, _embedded, content, date_gmt, author, role, bounty_slug } = info;
    const { activePage, ratingTimer } = this.state;
    const token = cookies.get('trybe_jwt', { path: '/' });
    //console.log(ratingTimer)
    if (ratingTimer <= 0) {
      clearInterval(interval);
    }
    if (prevProps.info !== info && Object.keys(info).length > 0) {
      //Maybe it's not the best choice
      if (!info) {
        history.push('/404');
      } else {
        if (info.author) {
          await amIFollowing({ userId: userInfo.id, id: info.author });
          await this.getRating(info.id);
          await getAuthorPost({ post: info.id, author_id: info.author });
          await getPostComments({ post: info.id, page: activePage, token });
          await getRatings(info.id);
        }
      }
    }
    if (prevProps.ratingSending === true && ratingSending === false) {
      await this.getRating(info.id);
    }

    if (prevState.activePage != activePage) {
      await this.getPagedComments();
    }
    if (
      (prevProps.subscriptionSuccessfull === false
        && subscriptionSuccessfull === true)
    ) {
      await amIFollowing({ userId: userInfo.id, id: info.author });
    }
  }

  async componentWillUnmount() {
    const { clearPostInfo } = this.props;
    await clearPostInfo();
  }

  onScroll = ({ target }) => {
    console.log(window.innerHeight)
  };

  async getPagedComments() {
    const { info, getPostComments, cookies } = this.props;
    const { activePage } = this.state;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await getPostComments({ post: info.id, page: activePage, token });
  }

  async publishComment(text) {
    const { sendComment, cookies, info, userInfo } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await sendComment({ id: info.id, text, token, authorId: info.author, userId: userInfo.id, target: info.slug, articleName: info.title.rendered });
  }

  async getRating(id) {
    const { userInfo, getRating } = this.props;
    const userId = userInfo.id;
    await getRating({ id, userId });
  }

  handleRate = async (e, { rating }) => {
    const { cookies, sendPostRating, postRating, info, userInfo } = this.props;
    console.log(postRating);
    const token = cookies.get('trybe_jwt', { path: '/' });
    await sendPostRating({ token, rating, id: info.id, authorId: info.author, userId: userInfo.id, target: info.slug, authorName: info._embedded.author[0].name, articleName: info.title.rendered });
    // let ratingValue = cookies.get('ratingCount')
    // let ratingTime = cookies.get('ratingTime')
    // let currentTime = new Date().getTime()
    // console.log(currentTime);
    // console.log(ratingTime);
    // console.log(ratingValue);
    // if (ratingValue == undefined && ratingTime == undefined) {
    //   let d = new Date();
    //   d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    //   cookies.set("ratingCount", 1, { path: '/', expires: d });
    //   cookies.set('ratingTime', d, { path: '/' })
    //   sendPostRating({ token, rating, id: info.id, authorId: info.author, userId: userInfo.id, target: info.slug });
    // } else if (ratingValue <= 5) {
    //   ratingValue = Number(ratingValue) + 1
    //   cookies.set("ratingCount", ratingValue, { path: '/' });
    //   sendPostRating({ token, rating, id: info.id, authorId: info.author, userId: userInfo.id, target: info.slug });
    // } else {
    //   ratingValue = Number(ratingValue) + 1;
    //   cookies.set("ratingCount", ratingValue, { path: '/' });
    // }
  };

  publishReply = async ({ text, replyId, parent }) => {
    const { cookies, sendReply, info, userInfo } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await sendReply({ token, id: info.id, parent, text, authorId: info.author, userId: userInfo.id, replyId, target: info.slug, articleName: info.title.rendered });
    window.scrollTo(0, document.body.scrollHeight);
  };


  async editComment({ text, commentId, authorId }) {
    const { sendUpdateComment, cookies, info, userInfo } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await sendUpdateComment({ token, post:info.id, commentId, text, authorId: authorId, userId: userInfo.id, target: info.slug, articleName: info.title.rendered });
    window.scrollTo(0, document.body.scrollHeight);
  }

  markup = (content) => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = content ? content : '';
    return divContainer.textContent || divContainer.innerText || "";
  }

  showReportCommentDialog({ commentId, message }) {
    this.setState({
      open: true,
      reportCommentJson: {
        commentId: commentId,
        reportCommentMessage: message
      }
    })
  }

  async reportComment({ commentId }) {
    const { sendReportComment, cookies, info, userInfo } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await sendReportComment({ token, post:info.id, commentId, userId: userInfo.id, target: info.slug, articleName: info.title.rendered });
    this.setState({
      open: false,
      reportCommentJson: {
        commentId: null,
        reportCommentMessage: null
      },
      reportedCommentId: [...this.state.reportedCommentId, commentId],
    })
  }

  async upVoteComment({ commentId, comment_user_id }) {
    const { sendUpVoteComment, cookies, info, userInfo } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await sendUpVoteComment({ token, post:info.id, commentId, userId: userInfo.id, target: info.slug, comment_user_id, articleName: info.title.rendered });
  }

  async reportSpamComment({ commentId, commentUserId }) {
    const { sendReportSpamComment, cookies, info, userInfo, getPostComments } = this.props;
    const { activePage } = this.state;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await sendReportSpamComment({ token, post:info.id, commentId, userId: userInfo.id, target: info.slug, commentUserId, articleName: info.title.rendered });
    if (!info) {
      history.push('/404');
    } else {
      if (info.author) {
        await getPostComments({ post: info.id, page: activePage, token });
      }
    }
  }

  onUserClick(userData, userId) {
    const { history, setUserInfo } = this.props;
    //sometimes WP puts "sorry, you are not allowed to list users" message to embedded data of comment
    if (userData.id) {
      setUserInfo(userData);
      history.push(`/user/${userData.id}/`);
    } else {
      history.push(`/user/${userId}/`);
    }
  }

  renderTokens(centered) {
    const { postRating } = this.props;
    const style = {
      justifyContent: centered ? 'center' : 'flex-end',
      margin: centered ? '0 auto' : 0,
      marginTop: '10px'
    };
    return (
      <div className="tokens" style={style}>
        {centered ? `${postRating.tokens ? postRating.tokens : 0}` : postRating.tokens}
        <Image src={trybeIcon} />
      </div>
    );
  }

  handlePaginationUp() {
    const { activePage } = this.state;
    this.setState({ activePage: activePage + 1 });
  }

  handlePaginationDown() {
    const { activePage } = this.state;
    this.setState({ activePage: activePage - 1 });
  }

  tipAmountChange = value => this.setState({ tipAmount: value });

  handleClick = async () => {
    const { userInfo, followUser, unfollowUser, info, following, amIFollowing, cookies } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    const authorName = info._embedded.author[0].name;
    if (!following) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'follow-btn',
        'event_category': 'community',
        'event_action': 'follow-btn',
        'event_label': authorName //the article’s author
      });
      await followUser({ userId: userInfo.id, targetId: info.author, token, authorName });
      await amIFollowing({ userId: userInfo.id, id: info.author });
    }
  };

  defaultClickHandler = async (postInfo) => {
    const { history, clearPostInfo, getPostInfo } = this.props;
    await history.push(`/${postInfo.slug}`);
    await clearPostInfo();
    await getPostInfo({ id: `/${postInfo.slug}` });
    //window.location.reload();
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage });
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    const { dismissReport } = this.props;
    this.setState({ show: false });
    dismissReport()
  };

  handleConfirm = () => {
    this.setState({ result: 'confirmed', open: false })
  }
  handleCancel = () => {
    this.setState({ result: 'cancelled', open: false })
  }

  highlightTextTrigger = (highlight) => {
    setTimeout(() => {
      document.querySelectorAll('.cz-set-selection-text').forEach(function (a) {
        a.remove()
      })
      const elements = document.getElementsByClassName("highlight");
      while (elements.length > 0) {
        elements[0].classList.remove('highlight');
      }
      var sel, range, node;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = window.getSelection().getRangeAt(0);
          var docFragment = range.cloneContents();
          if (docFragment.querySelectorAll('p, ul, ol, li, h1, h2, h3, h4, h5, h6').length > 0) return;
          this.setState({ windowTextSelection: window.getSelection().toString() })
          if (sel.toString().trim() !== '') {
            var html = ''
            if (highlight) {
              html = '<span class="highlightComment">' + this.highlightShare() + range + '</span>'
            } else {
              html = '<span class="highlight">' + this.highlightShare() + range + '</span>'
            }
            range.deleteContents();

            var el = document.createElement("span");
            el.innerHTML = html;
            var frag = document.createDocumentFragment(), node, lastNode;
            while ((node = el.firstChild)) {
              node.style.backgroundColor = 'red !important'
              lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);
            setTimeout(() => {
              this.clickEvents();
            }, 200);
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.collapse(false);
        range.pasteHTML(html);
      }
      this.clickEvents();
    }, 200);
  }


  highlightTextTriggerr = (highlight) => {
    var range = window.getSelection().getRangeAt(0);
    var docFragment = range.cloneContents();

    if (docFragment.querySelector('p')) return;

    var span = document.createElement('span');
    span.className = 'highlightComment';
    range.surroundContents(span);
  }


  highlightBrush = () => {
    this.highlightTextTrigger(true);
  }

  commentBrush = () => {
    this.setState({ highlightText: this.state.windowTextSelection })
    this.executeScroll();
  }

  clickEvents() {
    if (document.getElementById("commentBrush")) {
      document.getElementById("commentBrush").addEventListener("click", this.commentBrush);
    }
    if (document.getElementById("highlightBrush")) {
      document.getElementById("highlightBrush").addEventListener("click", this.highlightBrush);
    }
  }

  highlightShare = () => {
    const { windowTextSelection } = this.state;
    return (`<span class="cz-set-selection-text">
        <span class="spanul">
          <span class="spanli">
            <a id="highlightBrush"><img src="/img/brush-share-icon.png" alt="brush-share-icon.png" /></a>
          </span>
          <span class="spanli">
            <a id="commentBrush"><img src="/img/comment-share-icon.png" alt="comment-share-icon.png" /></a>
          </span>
          <span class="spanli">
            <a href="https://twitter.com/intent/tweet?text=${windowTextSelection}" target="_blank"><img src="/img/twitter-share-icon.png" alt="twitter-share-icon.png" /></a>
          </span>
        </span>
      </span>`)
  }

  executeScroll = () => {
    this.addCommentRef && this.addCommentRef.current && this.addCommentRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  async handleCuratePost() {
    const { cookies, info, setCurate, userInfo } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    await setCurate({ token, post:info.id, authorId: info.author, userId: userInfo.id, target: info.slug, articleName: info.title.rendered });
  }

  handleChange = (e, { value }) => this.setState({ report_reason: value });
  ratingDisplayTrigger(rating){
    if(rating > 0){
      this.setState({
        ratingModalShow: true
      })
    }
  }

  closeRatingModal(){
    this.setState({
      ratingModalShow: false
    })
  }

  render() {
    const {
      activePage,
      tipAmount,
      tipHidden,
      show,
      open,
      openVoteConfirm,
      reportCommentJson,
      ratingTimer,
      highlightText,
      report_reason,
      ratingModalShow
    } = this.state;
    const {
      info,
      userInfo,
      postRating,
      ratingLoading,
      history,
      isCommentsLoading,
      isAuthorized,
      comments,
      isPostLoading,
      articlesInfo,
      moreArticlesLoading,
      following,
      isLoadingSubscription,
      gettingUserReviewData,
      clickHandler,
      commentPages,
      reportArticle,
      reportMessage,
      cookies,
      isAdmin,
      userRole,
      curateLoading,
      ratings,
      ratingsLoading,
    } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    if (!info) {
      history.push('/404');
      return <Loader active />;
    }
    const { id, title, _embedded, content, date_gmt, author, role, bounty_slug, read_time, role, acf } = info;

    const panes = [
      {
        menuItem: { key: 'chat', icon: 'chat', content: 'Discussion' },
        render: () => (
          <Tab.Pane>
            {isAuthorized && <AddComment addPredefinedComment={highlightText} />}
            {/* <Pagination
              boundaryRange={0}
              activePage={activePage}
              defaultActivePage={1}
              siblingRange={1}
              onPageChange={this.handlePaginationChange}
              totalPages={commentPages}
            /> */}
            {isCommentsLoading ? <Loader active intermediate /> : commentPages > 0 ? (
              <>
                <div className="commentHandle">
                  <h4>Comments:</h4>
                  <CommentsList comments={comments} userInfo={userInfo} onReply={this.publishReply} onEditComment={this.editComment} onReportComment={this.showReportCommentDialog} onUpVoteComment={this.upVoteComment} onReportSpamComment={this.reportSpamComment} reportedCommentId={this.state.reportedCommentId} onUserClick={this.onUserClick} isAuthorized={isAuthorized} isAdmin={isAdmin?.administrator ? true : false} />
                </div>
              </>
            ) : <h3>Be the first to start a discussion.</h3>}
            <Pagination
              firstItem={false}
              lastItem={false}
              nextItem={<a type="nextItem" class="item"><img src="/img/arrow-right.svg" alt="" /></a>}
              prevItem={<a type="prevItem" class="item"><img src="/img/arrow-left.svg" alt="" /></a>}
              boundaryRange={0}
              activePage={activePage}
              defaultActivePage={1}
              siblingRange={1}
              onPageChange={this.handlePaginationChange}
              totalPages={commentPages}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: { key: 'user', icon: 'user', content: 'About Author' },
        render: () => (
          <Tab.Pane className="article-bar">
            <div className="post-author">
              Published By
              <b style={{ marginRight: '5px', color: '#4DD4B6' }}>
                <Image
                  src={_embedded.author[0].avatar_urls[96]}
                  avatar
                  circular
                  as={Link}
                  to={`/user/${_embedded.author[0].id}/`}
                  onClick={() => this.onUserClick(_embedded.author[0])}
                />
                <Link
                  to={`/user/${_embedded.author[0].id}/`}
                  onClick={() => this.onUserClick(_embedded.author[0])}
                >
                  {_embedded.author[0].name}
                </Link>
              </b>
              {' | '}
              {moment
                .utc(date_gmt)
                .local()
                .format('DD MMMM YYYY')}

              <h4>BIO</h4>
              <Divider />
              <div className="extraBioContent">
                <b style={{ marginRight: '5px' }}>
                  {_embedded.author[0].description ? _embedded.author[0].description : '.....'}
                </b>
              </div>
            </div>
          </Tab.Pane>
        )
      }
    ];

    const ratingPopover = '<span>Tooltip</span>';

    return (
      <Grid className="post-container cz-single-article">
        {id && (<PostEngagement />)}
        {show && (
          <div className={show ? "modal display-block modal-delete" : "modal display-none modal-delete"}>
            <section className="modal-main">
              <h5>Report Article/User</h5>
              <br />
              {reportMessage ? (
                <div>
                  <p>{reportMessage}</p>
                  <Button variant="secondary" onClick={() => this.hideModal()}>
                    Close
                  </Button>
                </div>
              ) : (
                <div>
                  <p>If you suspect this user is fake or that they are posting fake content, please report them below and our team will follow up.</p>
                  <p>Please DO NOT report accounts/content that isn't fake nor seem fake or your own account may be suspended.</p>
                  <h7>Reason For Reporting?</h7>
                  <TextArea
                    className="report_reason"
                    rows='3'
                    placeholder="Reason for reporting"
                    onChange={this.handleChange}
                    value={report_reason}
                  />
                  <Button variant="secondary" onClick={() => this.hideModal()}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!report_reason} onClick={() => { reportArticle({ token, id, reason: report_reason, source: window.location.href }) }}>
                    Report
                  </Button>
                </div>
              )}
            </section>
          </div>
        )}
        {id && userInfo.id && (
          <Container className="cz-back-article">
            <div className="cz-grid-center">
              <Button className="backarticle" onClick={() => history.push('/community/')}>Back to All Articles</Button>
              {bounty_slug?.slug.length > 0 && (<Button className="backarticle" onClick={() => history.push(`/bounty/${bounty_slug.slug}/`)}>Bounties</Button>)}
              <span className="reportButton" size="mini" style={{ float: 'right' }} onClick={() => this.showModal()}><i class="fal fa-flag"></i></span>
            </div>
          </Container>
        )}
        {id && !userInfo.id && (
          <Container className="cz-back-article">
            <div className="cz-grid-center">
              <Button className="backarticle" onClick={() => history.push('/community/')}>Back to All Articles</Button>
              {bounty_slug?.slug.length > 0 && (<Button className="backarticle" onClick={() => history.push(`/bounty/${bounty_slug.slug}/`)}>Bounties</Button>)}
            </div>
          </Container>
        )}
        <div className="cz-single-article-container">
          <Grid.Row>
            <Grid.Column>
              {id ? (
                <Grid>
                  <Helmet>
                    <title>{title ? 'Loop Finance: ' + he.decode(title.rendered) : 'Loop Finance'}</title>
                    <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
                    {role == 'subscriber' && (<meta name="robots" content="noindex" />)}
                    <meta name="title" content={he.decode(title.rendered).replace(/(<([^>]+)>)/ig, '')} />
                    <meta name="description" content={(info.excerpt.rendered).replace(/(<([^>]+)>)/ig, '')} />
                    <meta property="og:site_name" content="Loop" />
                    <meta property="og:url" content={'https://loop.markets/' + info.slug} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={he.decode(title.rendered).replace(/(<([^>]+)>)/ig, '')} />
                    <meta property="og:description" content={(info.excerpt.rendered).replace(/(<([^>]+)>)/ig, '')} />
                    <meta property="og:image" content={_embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'][0].source_url} />
                    <meta property="og:updated_time" content={info.modified} />
                    <meta property="article:published_time" content={info.date} />
                    {/* Twitter Card Data */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content={`https://loop.markets/${info.slug}`} />
                    <meta name="twitter:title" content={he.decode(title.rendered).replace(/(<([^>]+)>)/ig, '')} />
                    <meta name="twitter:description" content={(info.excerpt.rendered).replace(/(<([^>]+)>)/ig, '')} />
                    <meta name="twitter:image" content={_embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'][0].source_url} />
                    {/* Start of loopfinance Zendesk Widget script */}
                    <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=6c0c34a0-c54f-4cfa-94d8-480e9d57cc24" />
                    {/* End of loopfinance Zendesk Widget script */}
                    <script> window.prerenderReady = true </script>
                    {acf?.canonical_link && <link rel="canonical" href={acf?.canonical_link}></link>}
                  </Helmet>
                  <Grid.Row className="sub-header cz-post-header-top">
                    <Grid.Column width="16">
                      <div className="post-header">
                        <h1>{he.decode(title.rendered)}</h1>
                      </div>
                      <Grid.Row>
                        <Grid.Column>
                          <CategoriesAndTags
                            tags={_embedded['wp:term'] && _embedded['wp:term'][1]}
                            categories={_embedded['wp:term'] && _embedded['wp:term'][0]}
                            showCurate={true}
                            role={role}
                            acf={acf}
                            onCuratePostClick={this.handleCuratePost}
                            isAuthorized={isAuthorized}
                            isAdmin={isAdmin?.administrator ? true : false}
                            curateLoading={curateLoading}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <div className="post-date">
                        <div className="post-bar">
                          <div className="post-author">
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={10}>

                                  <b style={{ marginRight: '6px', color: '#4DD4B6' }}>
                                    <Image
                                      src={_embedded.author[0].avatar_urls[96]}
                                      avatar
                                      circular
                                      as={Link}
                                      to={`/user/${_embedded.author[0].id}/`}
                                      onClick={() => this.onUserClick(_embedded.author[0])}
                                    />
                                    <Link
                                      to={`/user/${_embedded.author[0].id}/`}
                                      onClick={() => this.onUserClick(_embedded.author[0])}
                                    >
                                      Published By: {_embedded.author[0].name}
                                    </Link>
                                  </b>
                            
                                </Grid.Column>
                                <Grid.Column width={6} className="post_date_detail_colum">
                                  <div className="post_date_details">
                                    <span className="lightShade">{moment
                                      .utc(date_gmt)
                                      .local()
                                      .format('MMMM DD, YYYY')}
                                    </span>
                                    <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-readtime.svg" alt="" />
                                    <span className="lightShade">{read_time} Min
                                    </span>
                                    <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-comment.svg" alt="" />
                                    <span className="lightShade">{info.details.post_comments_number}</span>
                                    {userInfo.id === author && (' | ')}
                                    <span>
                                    {userInfo.id === author && (
                                      <a href={'/post/editor/edit/' + id}>
                                        <Button classNmae="post-category curate-category">
                                        Edit Article
                                        </Button>
                                      </a>
                                    )}
                                    </span>
                                    {/*}
                                    <Popup
                                      disabled
                                      content='Bookmark'
                                      position='bottom center'
                                      trigger={<span className='bookmark-button'><Icon name='bookmark outline' /></span>} />
                                    */}
                                  </div>
                                  {userInfo.id ? (
                                    <Button
                                      hidden={following}
                                      content={<><span className="plusIcon"><i class="fal fa-plus-circle"></i></span><span>Follow</span></>}
                                      size="tiny"
                                      hidden={userInfo.id == info.author}
                                      loading={isLoadingSubscription || gettingUserReviewData}
                                      className='green-follow-button'
                                      onClick={this.handleClick}
                                    />
                                  ) : (
                                    <Button
                                      content={'Join Community'}
                                      size="tiny"
                                      className="green-button"
                                      onClick={() => history.push('../sign-up')}
                                    />
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </div>
                          <div className="post-author cz-post-mobile-only">
                            <b style={{ marginRight: '25px', color: '#4DD4B6' }}>
                              <Image
                                src={_embedded.author[0].avatar_urls[96]}
                                avatar
                                circular
                                as={Link}
                                to={`/user/${_embedded.author[0].id}/`}
                                onClick={() => this.onUserClick(_embedded.author[0])}
                              />
                              <Link
                                to={`/user/${_embedded.author[0].id}/`}
                                onClick={() => this.onUserClick(_embedded.author[0])}
                              >
                                Published By: {_embedded.author[0].name}
                              </Link>
                            </b>
                            {userInfo.id ? (
                              <Button
                                hidden={following}
                                content={<><span className="plusIcon"><i class="fal fa-plus-circle"></i></span><span>Follow</span></>}
                                size="tiny"
                                disabled={userInfo.id == info.author}
                                loading={isLoadingSubscription || gettingUserReviewData}
                                className='green-follow-button'
                                onClick={this.handleClick}
                              />
                            ) : (
                              <Button
                                content={'Join Community'}
                                size="tiny"
                                className="green-button"
                                onClick={() => history.push('../sign-up')}
                              />
                            )}
                            <span className="lightShade">
                              {moment
                                .utc(date_gmt)
                                .local()
                                .format('MMMM DD, YYYY')}
                            </span>
                            <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-readtime.svg" alt="" />
                            <span className="lightShade">{read_time} Min
                            </span>

                            <img style={{ marginLeft: '25px', marginRight: '5px' }} src="/img/icon-comment.svg" alt="" />
                            <span className="lightShade">
                              {info.details.post_comments_number}
                            </span>
                            {userInfo.id === author && (' | ')}
                            {userInfo.id === author && (
                              <a href={'/post/editor/edit/' + id}>
                                <i className="fal fa-edit" />
                              </a>
                            )}
                            {/*
                            <Popup
                              content='Bookmark'
                              position='bottom center'
                              trigger={<span className='bookmark-button'><Icon name='bookmark outline' /></span>} />
                            */}

                          </div>
                          {/*this.renderTokens(false)*/}
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  {_embedded['wp:featuredmedia'] && (
                    <Grid.Row className="cz-post-header-top">
                      <Grid.Column>
                        <div className="post-featured-image">
                          <Image
                            src={`${_embedded['wp:featuredmedia'][0].source_url
                              }`}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row className="cz-set-set">
                    <div
                      className="post"
                      onMouseUp={() => this.highlightTextTrigger(false)}
                      dangerouslySetInnerHTML={{ __html: content?.rendered }}
                    />
                  </Grid.Row>
                  <Grid.Row className="cz-set-set">
                    <div className="social-buttons-container">
                      <Facebook link={window.location.href} />
                      <Telegram link={window.location.href} />
                      <Twitter link={window.location.href} />
                      <Linkedin label="LinkedIn" link={window.location.href} />
                      <Whatsapp link={window.location.href} />
                      <Reddit link={window.location.href} />
                      <Mail link={window.location.href} />
                    </div>
                  </Grid.Row>
                  <Grid.Row className="cz-set-set">
                    <div className="ratings">
                      {/*
                      {!ratingLoading ? (
                        <React.Fragment>
                          {ratingTimer <= 0 ? (
                            <Rating className="ratingTag" icon="star" disabled={postRating.user_rated || !isAuthorized} onRate={this.handleRate} defaultRating={postRating.user_rated} maxRating={5} />
                          ) : (
                            <p style={{ color: 'var(--pink)' }}>You must read article to rate</p>
                          )}
                        </React.Fragment>
                      ) : (
                        <Loader active />
                      )}
                  
                      <div className="ratingSystem toolTipPopover hoverClick" onClick={() => this.ratingDisplayTrigger(postRating.post_rating_average)}>
                        {`Total Ratings: ${!postRating.total_rates ? 0 : postRating.total_rates}`} <span className="dot"></span> {`Overall Rating: ${!postRating.post_rating_average ? 0 : postRating.post_rating_average}`}
                        {ratings.length>0 && (
                          <div className='ratingPopoverHolder'>
                            <h4>Votes</h4>
                            {
                              ratings.map((itemRate, indexRate) =>(
                              (indexRate<15) ? (
                                <div key={indexRate} className="ratingPopoverHolderDiv" tabIndex="-1">
                                  <span><span className='user'>{itemRate.username}</span>: {itemRate.reputation_score}</span>
                                </div>
                              ): (indexRate<16) ? (
                                <div key={indexRate} className="ratingPopoverHolderDiv" tabIndex="-1">
                                  <span className='hoverClick' onClick={() => this.ratingDisplayTrigger(postRating.post_rating_average)}>and {ratings.length-indexRate} More</span>
                                </div>
                                ):('')
                              ))
                            }
                          </div>
                        )}

                      </div>
                      */}
                      {/*
                        bounty_slug?.slug.length > 0 && (
                          <div className="voteSection">
                            <Button disabled variant="secondary" className="voteButton" onClick={() => {
                              this.setState({ openVoteConfirm: true })
                            }}>
                              Bounty Vote
                            </Button>
                            <p>3 votes remaining</p>
                          </div>
                        )

                      <div className="ratingSystem">
                        {this.renderTokens(true)}
                      </div>
                      
                      */}
                    </div>
                    {!isAuthorized && (
                      <div className="joinUsToday">
                        <div className="joinUs">
                          <img src="/img/joinUsToday.svg" />
                        </div>
                        <div className="registerWithUs">
                          <h6>Register today and become part of an awesome community!</h6>
                          <div className="joinFreeHold">
                            <div className="joinFree">
                              <a href="https://www.loop.markets/sign-up/" target="blank">
                                JOIN FREE!
                              </a>
                            </div>
                            <div className="joinFreeText">
                              <p>Comments, following, and dozen of other functions will become available after registration</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {tipHidden != true && (
                      <div className="ratings">
                        <div style={{ marginTop: '50px' }} className="tip">
                          <p>
                            <Grid columns="equal" divided>
                              <Grid.Row stretched>
                                <Grid.Column>
                                  <Button.Group className="amount">
                                    <Button onClick={() => { this.tipAmountChange(100); }} active={tipAmount == 100}>100 LOOP</Button>
                                    <Button onClick={() => { this.tipAmountChange(500); }} active={tipAmount == 500}>500 LOOP</Button>
                                    <Button onClick={() => { this.tipAmountChange(1000); }} active={tipAmount == 1000}>1000 LOOP</Button>
                                    <Button onClick={() => { this.tipAmountChange(2500); }} active={tipAmount == 2500}>2500 LOOP</Button>
                                    <Button onClick={() => { this.tipAmountChange(5000); }} active={tipAmount == 5000}>5000 LOOP</Button>
                                  </Button.Group>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            <Grid columns="equal" divided>
                              <Grid.Row>
                                <Grid.Column>
                                  <Button
                                    icon="send"
                                    content={`TIP ${tipAmount} LOOP`}
                                    size="massive"
                                    color="green"
                                    fluid
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </p>
                        </div>
                      </div>
                    )}

                  </Grid.Row>
                  {/* {!isAuthorized && (
                      <Grid.Row className="loopCard tokenCard">
                        <p className="title">Why Join Loop? </p>
                        <br />
                        <span className="text-light" style={{ margin: '20px;' }}>Join Loop and immediately start earning LOOPR tokens for your participation in our community! Write articles, rate articles, or comment on other people's articles! Everything you do will help you to earn more of our tokens!
                          <br />
                          <br />
                          By owning LOOPR tokens you can earn LOOP tokens - the core token powering our DEX (Decentralized Exchange). With LOOP tokens, you can earn a share of the revenue from every transaction that is made on our platform!
                          <br />
                          <br />
                          Become a member TODAY and start EARNING!
                        </span>
                        <button className="btn headerBtn px-3 mb-2 mb-lg-0 joinBtn" type="button" onClick={() => history.push('/sign-up')}>Join</button>
                        <Divider />
                      </Grid.Row>
                    )} */}
                  <Grid.Row className="cz-post-comments" id="postEnd">
                    <Grid.Row className="cz-post-header-top-enter">
                      <div
                        className="post"
                      >
                        {!moreArticlesLoading && articlesInfo[0] && (
                          <div>
                            <Header
                              content="You might be also interested in:"
                            />
                            <div className="author-post">
                              <PostsList
                                className="posts-list"
                                posts={articlesInfo}
                                isLoaderActive={false}
                                clickHandler={
                                  clickHandler
                                    ? clickHandler
                                    : this.defaultClickHandler
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid.Row>
                    <div className="comments-container" ref={this.addCommentRef}>
                      <Tab menu={{ attached: true }} panes={panes} />
                    </div>
                  </Grid.Row>

                </Grid>
              ) : (
                <Loader active={isPostLoading} />
              )}
            </Grid.Column>
          </Grid.Row>
        </div>
        <Confirm
          header="Are you sure you want to report this comment?"
          content={this.markup(reportCommentJson.reportCommentMessage)}
          size="tiny"
          open={open}
          cancelButton="No"
          confirmButton="Yes"
          onCancel={() => this.setState({ open: false })}
          onConfirm={() => this.reportComment({ commentId: reportCommentJson.commentId })}
        />

        <Confirm
          header="Are you sure you want to vote for this Article?"
          content="You are about to use one of your three votes for this bounty contest. Are you sure you are ready to vote?"
          size="tiny"
          open={openVoteConfirm}
          cancelButton="Cancel"
          confirmButton="Submit Vote"
          onCancel={() => { this.setState({ openVoteConfirm: false }) }}
          onConfirm={() => { }}
        />

        <RatingPopup ratings={ratings} closeRatingModal={this.closeRatingModal} ratingModalShow={ratingModalShow} ratingsLoading={ratingsLoading}></RatingPopup>
      </Grid>
    );
  }
}
export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => {
        return {
          cookies: ownProps.cookies,
          userInfo: state.user.userInfo,
          postRating: state.post.postRating,
          ratingLoading: state.post.ratingLoading,
          isAuthorized: state.user.userIsLogged,
          comments: state.post.postComments,
          commentPages: state.post.commentPages,
          isCommentsLoading: state.post.commentsLoading,
          isPostLoading: state.post.postLoading,
          moreArticlesLoading: state.post.moreArticlesLoading,
          info: state.post.postInfo,
          articlesInfo: state.post.moreArticles,
          ratingSending: state.post.ratingSending,
          following: state.userReview.amIFollowing,
          isLoadingSubscription: state.following.loadingSubscribe,
          gettingUserReviewData: state.userReview.loading,
          subscriptionSuccessfull: state.following.subscriptionSuccessfull,
          reportMessage: state.post.reportMessage,
          isAdmin: state.user.userInfo.extra_capabilities,
          userRole: state.user.userInfo.roles,
          curateLoading: state.post.curateLoading,
          ratings: state.feed.ratings,
          ratingsLoading: state.feed.ratingsLoading
        };
      },
      dispatch => ({
        getPostInfo: (params) => {
          dispatch(actions.getPostInfo(params));
        },
        resetCommentSuccess: () => {
          dispatch(actions.resetCommentSuccess());
        },
        reportArticle: (params) => {
          dispatch(actions.reportArticleRoutine(params));
        },
        dismissReport: () => {
          dispatch(actions.dismissReportRoutine());
        },
        getAuthorPost: (params) => {
          dispatch(actions.getAuthorPost(params));
        },
        getPostComments: (params) => {
          dispatch(actions.getPostComments(params));
        },
        sendPostRating: (params) => {
          dispatch(actions.sendPostRating(params));
        },
        clearPostInfo: () => {
          dispatch(actions.clearPostInfo());
        },
        sendReply: (params) => {
          dispatch(actions.replyToPostComment(params));
        },
        sendUpdateComment: (params) => {
          dispatch(actions.updateToPostComment(params));
        },
        sendReportComment: (params) => {
          dispatch(actions.reportToPostComment(params));
        },
        sendUpVoteComment: (params) => {
          dispatch(actions.upVoteToPostComment(params));
        },
        sendReportSpamComment: (params) => {
          dispatch(actions.reportSpamComment(params));
        },
        getRating: (params) => {
          dispatch(actions.getPostRating(params));
        },
        ratePost: (params) => {
          dispatch(actions.ratePost(params));
        },
        amIFollowing: (params) => {
          dispatch(api.amIFollowing(params));
        },
        followUser: (params) => {
          dispatch(follow(params));
        },
        unfollowUser: (params) => {
          dispatch(unfollow(params));
        },
        clearPosts: () => {
          dispatch(feedActions.clearPostsRoutine());
        },
        setPost: (postInfo) => {
          dispatch(actions.setPostInfo(postInfo));
        },
        setCurate: (params) => {
          dispatch(actions.setCuratePost(params));
        },
        getRatings: (postId) => {
          dispatch(feedActions.getRatingsRoutine(postId));
        },
      })
    )(Post)
  )
);
