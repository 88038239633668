import { put, takeLatest } from 'redux-saga/effects';
import * as editorActions from './EditorActions';
import * as api from '../../post/postApi';
import {
  createArticle,
  uploadFeaturedImage,
  updateArticleCall,
  deleteArticle,
  getMentionSuggestions
} from './EditorApi';

export const editorWatchers = [
  postArticleWatch,
  uploadFeaturedImageWatch,
  updateArticleWatch,
  getMentionSuggestionsWatcher,
  deleteArticleWatch,
  previewArticleWatch,
  getPreviewArticleWatch,
];

export function* postArticleWatch() {
  yield takeLatest(editorActions.createArticleRoutine.TRIGGER, newArticle);
}

export function* uploadFeaturedImageWatch() {
  yield takeLatest(
    editorActions.uploadFeaturedImageRoutine.TRIGGER,
    uploadImage
  );
}

export function* updateArticleWatch() {
  yield takeLatest(editorActions.updateArticleRoutine.TRIGGER, updateArticle);
}

export function* deleteArticleWatch() {
  yield takeLatest(editorActions.deleteArticleRoutine.TRIGGER, deleterticle);
}

function* uploadImage(data) {
  console.log('fired');
  const { slug, ...imageData } = data;
  const serverResponse = yield uploadFeaturedImage(imageData);

  if (serverResponse.data) {
    yield put(
      editorActions.uploadFeaturedImageRoutine.success(serverResponse.data)
    );
    if (slug) {
      yield api.sendPrerender(`https://www.loop.markets/${slug}`)
    }
  } else {
    yield put(editorActions.uploadFeaturedImageRoutine.failure(serverResponse));
  }
}
function* newArticle(data) {
  try {
    const serverResponse = yield createArticle(data);
    if (serverResponse.status === 201) {
      if (serverResponse.data.status == "publish" && serverResponse.data.slug) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'publish-article',
          'event_category': 'community',
          'event_action': 'publish-article',
          'event_label': serverResponse.data.title.rendered //the title of the article
        });

        yield api.sendPrerender(`https://www.loop.markets/${serverResponse.data.slug}`)
      }

      if (serverResponse.data.status == "draft") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'save-article',
          'event_category': 'community',
          'event_action': 'save-article',
          'event_label': serverResponse.data.title.rendered //the title of the article
        });
      }
      yield put(editorActions.createArticleRoutine.success(serverResponse.data));
    } else {
      yield put(editorActions.createArticleRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(editorActions.createArticleRoutine.failure(error));
  }

}

function* updateArticle(data) {
  try {
    const serverResponse = yield updateArticleCall(data);
    if (serverResponse?.status === 200) {
      yield put(editorActions.updateArticleRoutine.success(serverResponse.data));
    } else {
      yield put(editorActions.updateArticleRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(editorActions.updateArticleRoutine.failure(error));
  }
}

function* deleterticle(data) {
  try {
    const serverResponse = yield deleteArticle(data);
    if (serverResponse.status === 200) {
      yield put(editorActions.deleteArticleRoutine.success(serverResponse.data));
    } else {
      yield put(editorActions.deleteArticleRoutine.failure(serverResponse));
    }
  } catch (error) {
    yield put(editorActions.deleteArticleRoutine.failure(error));
  }
}

function* getMentionSuggestionsWatcher() {
  yield takeLatest(
    editorActions.getMentionSuggestions.TRIGGER,
    getMentionSuggestionsWorker
  );
}
function* getMentionSuggestionsWorker({ payload }) {
  const serverResponse = yield getMentionSuggestions(payload.userId);
  if (serverResponse.status === 200) {
    yield put(editorActions.getMentionSuggestions.success(serverResponse.data));
  } else {
    yield put(editorActions.getMentionSuggestions.failure(serverResponse));
  }
}


export function* previewArticleWatch() {
  yield takeLatest(editorActions.previewArticleRoutine.TRIGGER, prevArticle);
}

function* prevArticle(data) {
  try {
    yield put(editorActions.previewArticleRoutine.success(data));
  } catch (error) {
    yield put(editorActions.previewArticleRoutine.failure(error));
  }
}


function* getPreviewArticleWatch() {
  yield takeLatest(
    editorActions.getPreviewArticleRoutine.TRIGGER,
    getPreviewArticleWorker
  );
}
function* getPreviewArticleWorker({ payload }) {
  yield put(editorActions.getPreviewArticleRoutine.success(payload));
}