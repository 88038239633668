import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Container, Button } from "semantic-ui-react";
import qr from "../../../../../staticAssets/appqr.png";
import appstore from "../../../../../staticAssets/images/home/appstore.svg";
import gplay from "../../../../../staticAssets/images/home/gplay.svg";
import loopapp from "../../../../../staticAssets/images/home/loopapp.png";
import styles from "../../index.module.scss";

class BannerSection extends Component {
  render() {
    return (
      <>
        <div className={styles.section}>
          <Container>
            <div className={styles.banner}>
              <div className={styles.left}>
                <Image className={styles.qrcode} src={qr} />
                <div className={styles.content}>
                  <h4>
                    Download <br /> the Loop App
                  </h4>
                  <p>Manage, buy and explore NFTs on the go</p>
                </div>
                <div>
                  <div className={styles.buttons}>
                    <a href="https://onelink.to/loop-beta" target="_blank">
                      <Image className={styles.appstore} src={appstore} />
                    </a>
                    <a href="https://onelink.to/loop-beta" target="_blank">
                      <Image className={styles.gplay} src={gplay} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.right}>
                <Image className={styles.loopapp} src="/assets/images/mobileapp_01.png" />
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(BannerSection);
