import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Container, Button } from "semantic-ui-react";
import qr from "../../../../../staticAssets/appqr.png";
import appstore from "../../../../../staticAssets/images/home/appstore.svg";
import gplay from "../../../../../staticAssets/images/home/gplay.svg";
import loopapp from "../../../../../staticAssets/images/home/loopapp.png";

class BannerSection extends Component {
  render() {
    return (
      <>
        <div className="section">
          <Container>
            <div className="banner">
              <div className="left">
                <Image className="qrcode" src={qr} />
                <div className="content">
                  <h4>
                    Download <br /> the Loop App
                  </h4>
                  <p>Manage, buy and explore NFTs on the go</p>
                </div>
                <div>
                  <div className="buttons">
                    <a href="https://onelink.to/loop-beta" target="_blank"><Image className="appstore" src={appstore} /></a>
                    <a href="https://onelink.to/loop-beta" target="_blank"><Image className="gplay" src={gplay} /></a>
                  </div>
                </div>
              </div>
              <div className="right">
                <Image className="loopapp" src={loopapp} />
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(BannerSection);
