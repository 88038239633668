import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import axios from 'axios';
import PageVisibility from 'react-page-visibility';
import * as api from './postApi';

let timer = null;

class PostEngagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: 0,
      reportSent: false,
      readTime: false,
      user_ip: null,
      engagement: null,
      minute_ms: 20000,
      timeOnPage: 0,
      isVisible: false
    };
  }

  async componentDidMount() {
    await this.fetchIP();
  }

  componentWillUnmount() {
    this.clearTimerOnPage()
  }

  handleVisibilityChange = isVisible => {
    //console.log(isVisible)
    this.setState({isVisible: !isVisible});
    isVisible && this.startTimerOnPage();
    !isVisible && this.clearWrapper(timer);
  }

  // Load ip address from the API
  fetchIP = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    //console.log(res.data);
    this.setState({user_ip: res.data.IPv4})
  }

  startTimerOnPage = () => {
    // Initial
    timer = this.runTimer();
    // Event listeners to stop timers
    window.onfocus = (e) => {
      // Conditional execution are for cross browser compatibility on focus event
      timer = timer ? timer : this.runTimer();
    };
    window.onblur = (e) => {
      timer = this.clearWrapper(timer);
    };
  };

  runTimer = () => {
    const { timeOnPage, minute_ms } = this.state;
    return setInterval(() => {
      this.setState({timeOnPage: ++timeOnPage});
      this.checkEngagement();
    }, minute_ms);
  };

  clearWrapper = (runner) => {
    clearInterval(runner);
    return false;
  };

  clearTimerOnPage = () => {
    if (timer) {
      timer = this.clearWrapper(timer);
    }
  };

  checkEngagement() {
    const { user_ip, reportSent } = this.state;
    const { userInfo } = this.props;
    if (user_ip != null || userInfo.id) {
      this.getEngagementTime().then((result) => {
        if (result.timeOnPage <= result.readTime && !reportSent) {
          //console.log(result)
          this.setState({engagement: result});
          this.sendEngagementReport(result);
        } else {
          this.clearTimerOnPage();
        }
      });
    }
  }

  async sendEngagementReport(data, done) {
    const { cookies } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    //console.log("ReadTime Engagement Sent");
    //console.log(engagement);
    if ((data.timeOnPage >= data.readTime)) {
      this.setState({reportSent: true});
      //console.log("ReadTime Complete.")
      this.clearTimerOnPage();
    }
    await api.sendEngagementReport({token, data});
  }

  async getEngagementTime() {
    const { timeOnPage, user_ip } = this.state;
    const { info } = this.props;
    const { id, read_time } = info;
    //Time To Read === Start
    /* Subtract the end time with start time, since endTime has greater value. */
    //let seconds = read_time * 60;
    return {readTime: read_time, timeOnPage: timeOnPage/3, post_id: id, ipv4: user_ip};
  }

  render() {
    return (
      <PageVisibility onChange={this.handleVisibilityChange} />
    );
  }
}

export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => ({
        cookies: ownProps.cookies,
        userInfo: state.user.userInfo,
        info: state.post.postInfo,
        commentErrorCode: state.post.commentErrorCode,
        commentErrorMessage: state.post.commentErrorMessage,
        commentSuccessMessage: state.post.commentSuccessMessage
      })
    )(PostEngagement)
  )
);
