import React, {useState} from 'react';
import {
  Button,
  Modal,
} from "semantic-ui-react";
import PlayBtn from "../../../../staticAssets/images/play_btn.png";

function VideoElement  (props) {

  const [modalStatus, setModalStatus] = useState(Boolean);
  return(<>
  <div className='video-thumbnail'>
    <div className='video-background' style={{backgroundImage: `url(https://img.youtube.com/vi/${props.id}/0.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center', border: 'solid 5px #090817'}}></div>
    <Modal
      onClose={() => setModalStatus(false)}
      onOpen={() => setModalStatus(true)}
      open={modalStatus}
      trigger={<img src={PlayBtn} alt="" />}
      className="support_vids"
    >
      <iframe id="loop_player" src={`https://www.youtube.com/embed/${props.id}?autoplay=true&controls=0&showinfo=0&modestbranding=1`} title="Loop Helpful Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture modestbranding;" allowfullscreen></iframe>
    </Modal>
  </div>
  </>);
}


export default VideoElement;