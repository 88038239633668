import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SideControl from '../yourtrybe/components/SideControl'
import Helmet from 'react-helmet';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { withCookies } from 'react-cookie';
import {
  Grid,
  Image
} from 'semantic-ui-react';
class previewApproveCompetition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {}
    }
  }

  componentDidMount() {
    let content = JSON.parse(localStorage.getItem('boutiesArticle'))
    this.setState({ data: content })
  }

  render() {
    const { history, userInfo } = this.props
    const { data } = this.state
    return (
      <Grid className="promo">
        <Helmet>
          <title>Loop Finance | Community</title>
        </Helmet>
        <Grid className="page-wrapper">
          <SideControl />
          <div className="content-wrapper cz-bountyPageWrap">
            <div className="createCompetitionArticle">
              <div className="approveHeading bountyHeading">
                <div className="approveHeading_inner">
                  <span onClick={() => history.push('/competitionsCategory/')}><img src="/../wt-back-arrow.png" /> Back to Category selection</span>
                  <h1><span>LOOP </span> Bounty competitions</h1>
                </div>
              </div>


              <Grid.Column className="post-header">
                <div className="post-header__visual">
                  <h3>{data.title}</h3>
                </div>
                <div className="btn_box">
                  <ul>
                    <li><a className="ppl">loop</a></li>
                    <li><a className="gry">Top Rated</a></li>
                  </ul>
                </div>
                <div class="post-date">
                  <div class="post-author">
                    <div class="img-author">
                      <img src="https://old.trybe.one/wp-content/uploads/avatars/14/5d108eaaedc19-bpfull.jpg" class="ui avatar circular image" />
                    </div>
                    <div class="text-author">
                      <p><div className="user_name"> Published By: {userInfo.name}<span className="dot">.</span></div>
                        <span class="time-txt">{moment().format('MMMM DD, YYYY')}</span>
                        {/* <span className="dot">.</span> */}
                        {/* <span><img src="/cmnt_icn.png" />26</span>  */}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid.Column>



              <Grid.Row className="cz-post-header-top">
                <Grid.Column>
                  <div className="post-featured-image">
                    <Image
                      src={`${data.imageUrl
                        }`}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>


              <Grid.Row className="cz-set-set">
                <div
                  className="post"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </Grid.Row>


              <Grid.Row className="btnWrap">
                <Grid.Column>
                  <Link className="bountyEditArticle" to="/createCompetitions/"><button className="bountySubmitArticle"><img className="left-btn" src="../right_arrow.png"></img>Edit</button></Link>
                  <Link to=""><button className="bountySubmitArticle">Submit<img src="../right_arrow.png"></img></button></Link>
                </Grid.Column>
              </Grid.Row>


            </div>
          </div >
        </Grid >
      </Grid >
    )
  }
}

export default previewApproveCompetition


export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => ({
        isAdmin: state.user.userInfo.extra_capabilities,
        userInfo: state.user.userInfo,
      }),
      dispatch => ({
      })
    )(previewApproveCompetition)
  )
);