import React, { Component } from 'react';
import { Comment, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TextArea from '../../textarea/Textarea';

export default class CustomComment extends Component {
  state = {
    openReplyField: false,
    openEditField: false,
    upCount: false,
    reportedCommentIdList: []
  };

  render() {
    const {
      level,
      id,
      userInfo,
      onUserClick,
      commentInfo,
      onReply,
      onEditComment,
      onReportComment,
      reportedCommentId,
      onUpVoteComment,
      avatarURL,
      authorID,
      authorInfo,
      isAuthorized,
      upvoted,
      upvotes
    } = this.props;

    const {name} = authorInfo;
    const authorId = authorInfo.id || authorID;
    // console.log(authorInfo.avatar_urls[96]);
    // const avatarURL = authorInfo.avatar_urls[96];
    const { date, content } = commentInfo;
    const { openReplyField, openEditField, upCount } = this.state;
    return (
      <React.Fragment>
        <Comment className={'comment-lvl-' + level}>
          <Comment.Avatar
            as={Link}
            to={`/user/${authorId}/`}
            src={avatarURL}
            onClick={() => onUserClick(authorInfo, authorId)}
          />
          <Comment.Content>
              {isAuthorized && (
                <div className="dangerReporthold">
                  {/*<span>{(upvotes > 0) && `+${upvotes}`}</span>*/}
                    {userInfo.id!==authorID && (
                      upCount || upvoted ? (
                        <span className="dangerReport">
                          <Icon name="thumbs up" size="massive" />
                        </span>
                      ):(
                        <span className="dangerReport"
                          onClick={()=> { this.setState({upCount:true}); onUpVoteComment({ commentId: id, comment_user_id: authorID });} }
                        >
                          <Icon name="thumbs up outline" size="massive" />
                        </span>
                      )
                    )}
                    {userInfo.id!==authorID && (
                      reportedCommentId.includes(id) ? (
                        <span className="dangerReport">
                          <Icon name="check" size="massive" />
                        </span>
                      ):(
                        <span className="dangerReport"
                        onClick={()=> { onReportComment({ commentId: id, message: content });} }
                        >
                          <Icon name="flag" size="massive" />
                        </span>
                      )
                  )}
                </div>
              )}
            <Comment.Author
              as={Link}
              to={`/user/${authorId}/`}
              onClick={() => onUserClick(authorInfo, authorId)}
            >
              {name}
            </Comment.Author>
            <Comment.Metadata>
              <div>
                {moment
                  .utc(date)
                  .local()
                  .format('DD MMMM YYYY')}
              </div>
            </Comment.Metadata>
            {openEditField ? (
              <Comment.Content>
                <TextArea
                  value={content}
                  openEditField={openEditField}
                  handler={text => onEditComment({ text, commentId: id, authorId: authorInfo.id })}
                  placeholder={`Type/Edit your comment here...`}
                  rows="3"
                />
              </Comment.Content>
              ) : 
              (
              <Comment.Text dangerouslySetInnerHTML={{ __html: content }} />
              )
            }
            <Comment.Actions>
              {userInfo.id && (
                <>
                  <Comment.Action
                    onClick={() => this.setState({
                      openReplyField: !openReplyField,
                      openEditField: false
                    })
                    }
                  >
                    <img src="/img/icon-reply.svg" alt=""/> {'  '}Reply
                  </Comment.Action>
                  {userInfo.id===authorID && (
                    <Comment.Action
                      onClick={() => this.setState({
                        openEditField: !openEditField,
                        openReplyField: false
                      })
                      }
                    >
                      <Icon name="pencil" size="massive" /> {'  '}Edit
                    </Comment.Action>
                  )}
              </>
              )}
            </Comment.Actions>
          </Comment.Content>
        </Comment>
        {openReplyField && userInfo.id && (
          <Comment className={'comment-lvl-' + (parseInt(level) + 1)}>
            <Comment.Avatar as="a" src={userInfo.avatar_urls[96]} />
            <Comment.Content>
              <Comment.Author>{userInfo.name}</Comment.Author>
              <Comment.Metadata>
                <div>
                  {moment
                    .utc()
                    .local()
                    .format('DD MMMM YYYY')}
                </div>
              </Comment.Metadata>
              <TextArea
                handler={text => onReply({ text, parent: id, replyId: authorID })}
                placeholder={`Type your reply to ${name} here...`}
                rows="3"
              />
            </Comment.Content>
          </Comment>
        )}
      </React.Fragment>
    );
  }
}
