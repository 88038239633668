import * as editorActions from './EditorActions';

const defaultState = {
  articleIsCreated: false,
  sendingArticle: false,
  featuredImage: null,
  mentionSuggestions: [],
  articleDeleted: false,
  articleData: [],
  errorMessage: false,
  isError: false,
  previewArticle: {},
  prevArticle: null,
  is_autosave: false,
};

export default function reducer(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case editorActions.clearErrorRoutine.TRIGGER: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', true);
      return { ...state, isError: false, errorMessage: false };
    }
    case editorActions.createArticleRoutine.TRIGGER: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', true);
      state.is_autosave = payload?.data?.is_autosave ? payload?.data?.is_autosave : false;
      return { ...state, articleData: {is_autosave: state?.is_autosave}, articleIsCreated: false, sendingArticle: true };
    }
    case editorActions.createArticleRoutine.SUCCESS: {
      // const newState = state
      //   .set('articleIsCreated', true)
      //   .set('sendingArticle', false);
      payload.is_autosave = state?.is_autosave;
      return { ...state, articleData: payload, articleIsCreated: true, sendingArticle: false };
    }
    case editorActions.createArticleRoutine.FAILURE: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', false);
      return { ...state, articleIsCreated: false, sendingArticle: false, errorMessage: payload, isError:true };
    }

    case editorActions.updateArticleRoutine.TRIGGER: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', true);
      state.is_autosave = payload?.data?.is_autosave ? payload?.data?.is_autosave : false;
      return { ...state, articleIsCreated: false, sendingArticle: true };
    }
    case editorActions.updateArticleRoutine.SUCCESS: {
      // const newState = state
      //   .set('articleIsCreated', true)
      //   .set('sendingArticle', false);
      var passIsArticleCreated = state?.is_autosave  ? false : true;
      state.is_autosave = false;
      return { ...state, articleIsCreated: passIsArticleCreated, sendingArticle: false };
    }
    case editorActions.updateArticleRoutine.FAILURE: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', false);
      return { ...state, articleIsCreated: false, sendingArticle: false, errorMessage: payload, isError:true };
    }

    case editorActions.deleteArticleRoutine.TRIGGER: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', true);
      return { ...state, articleDeleted: false };
    }
    case editorActions.deleteArticleRoutine.SUCCESS: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', true);
      return { ...state, articleDeleted: true };
    }
    case editorActions.deleteArticleRoutine.FAILURE: {
      // const newState = state
      //   .set('articleIsCreated', false)
      //   .set('sendingArticle', true);
      return { ...state, articleDeleted: false, errorMessage: payload, isError:true };
    }

    case editorActions.uploadFeaturedImageRoutine.TRIGGER: {
      // const newState = state.set('feauturedImageLoading', true);
      return { ...state, feauturedImageLoading: true };
    }
    case editorActions.uploadFeaturedImageRoutine.SUCCESS: {
      // const newState = state
      //   .set('feauturedImageLoading', false)
      //   .set('featuredImage', payload);
      return { ...state, feauturedImageLoading: false, featuredImage: payload };
    }
    case editorActions.uploadFeaturedImageRoutine.FAILURE: {
      // const newState = state.set('feauturedImageLoading', false);
      return { ...state, feauturedImageLoading: true };
    }
    case editorActions.articleIsCreatedRoutine.TRIGGER: {
      // const newState = state.set('articleIsCreated', false);
      return { ...state, articleIsCreated: false, articleDeleted: false };
    }

    case editorActions.getMentionSuggestions.SUCCESS: {
      // const newState = state.set('mentionSuggestions', payload);
      return { ...state, mentionSuggestions: payload };
    }

    case editorActions.previewArticleRoutine.TRIGGER: {
      return { ...state, previewArticle: payload, prevArticle: null };
    }

    case editorActions.previewArticleRoutine.SUCCESS: {
      localStorage.setItem('previewArticle', JSON.stringify(payload.payload));
      return { ...state, previewArticle: payload.payload, prevArticle: true };
    }

    case editorActions.previewArticleRoutine.FAILURE: {
      return { ...state, previewArticle: payload, prevArticle: false };
    }

    case editorActions.getPreviewArticleRoutine.TRIGGER: {
      return { ...state, previewArticle: {}, prevArticle: null };
    }

    case editorActions.getPreviewArticleRoutine.SUCCESS: {
      var previewData = localStorage.getItem('previewArticle');
      return { ...state, previewArticle: previewData ? JSON.parse(previewData) : {}, prevArticle : previewData ? true : false };
    }

    case editorActions.getPreviewArticleRoutine.FAILURE: {
      return { ...state, previewArticle: {}, prevArticle: false };
    }

    case editorActions.clearPreviewArticleRoutine.TRIGGER: {
      console.log("Got it reached");
      localStorage.removeItem('previewArticle');
      return { ...state, previewArticle: {},  prevArticle: null };
    }

    default:
      return state;
  }
}
