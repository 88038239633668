import { Grid, Divider, Image } from 'semantic-ui-react';
import SocialControls from './SocialControls';

const PersonSummary = ({ id, avatarURL, name, slug, details, description, isAuthorized, following, followers, isAdmin, twitter, youtube, discord }) => {
  const { posts, comments, terra_address, rank } = details;
  const style = { backgroundImage: `url(${avatarURL})` };
  return (
    <div>
      <Grid.Row className="summary">
        <Grid.Column width="7" className="person">
          <Grid>
            <Grid.Row>
              <Grid.Column width="6" className="avatar">
                <div className="circle-border">
                  <div
                    className="circle"
                    style={style}
                  />
                  <div className="online-mark" />
                </div>
              </Grid.Column>
              <Grid.Column width="6" className="description">
                <span className="name">{name}</span>
                <span className="nick">@{slug}</span>
                <Divider />
                <span>{description}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>

        <div className="cz-folow-counts">
          <div className="cz-set-p cz-moni-button-hub"><div className="cz-follow-part"><b><Image src="/following.svg" />{following} Following</b> <b><Image src="/followers.svg" />{followers} Followers</b></div>
            <div className="cz-moni-button">{isAuthorized && <SocialControls />}</div>
          </div>
          <p><span>{description}</span></p>
        </div>
        <Grid.Column width="16" floated="right" className="stats">
          <Grid>
            <Grid.Row>
              <Grid.Column width="3" hidden>
                <Grid.Row>
                  <span className="title">Articles:</span>
                </Grid.Row>
                <Grid.Row>
                  <span className="value">{posts ? posts : 0}</span>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width="3" className="cz-dek-top-button">
                {isAuthorized && <SocialControls />}
              </Grid.Column>
            </Grid.Row>
              <Grid.Row>
                {twitter.length>0 && (
                  <Grid.Column className="copyLinkProfileCol">
                  <div className="copyLinkProfile" style={{ textAlign: 'left'}}>
                      <a href={twitter} target="_blank">
                        <img src="/img/f3.png" alt="twitter_social" />
                      </a>
                  </div>
                  </Grid.Column>
                  )}
                {youtube.length>0 && (
                  <Grid.Column className="copyLinkProfileCol">
                    <div className="copyLinkProfile"  style={{ textAlign: 'left'}}>
                        <a href={youtube} target="_blank">
                          <img src="/img/youtube.png" alt="youtube_social" />
                        </a>
                    </div>
                  </Grid.Column>
                  )}
                  {discord.length>0 && (
                    <Grid.Column className="copyLinkProfileCol">
                      <div className="copyLinkProfile"  style={{ textAlign: 'left'}}>
                          <a href={discord} target="_blank">
                            <img src="/img/discord.png" alt="discord_social" style={{width: '32px', marginLeft: '7px'}} />
                          </a>
                      </div>
                    </Grid.Column>
                    )}
              </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      {/** Admin View */}
      
      {isAdmin && (isAdmin.administrator) && (
        <Grid columns={1}>
          <Grid.Row className="summary-admin" style={{color: '#fff', padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff17 !important'}}>
            <span className="title" style={{color: 'var(--white)', textAlign: 'center', padding: '15px'}}><b>Admin View</b></span>
            <Grid.Column>
              <span className="title" style={{color: 'var(--pink)'}}><b>Registration Date:</b></span>
              <span className="value" style={{paddingLeft: '10px', color: '#999'}}>{details.registration}</span>
            </Grid.Column>
            <Divider />
            <Grid.Column style={{paddingTop: '10px'}}>
              <span className="title" style={{color: 'var(--pink)'}}><b>User ID:</b></span>
              <span className="value" style={{paddingLeft: '10px', color: '#999'}}>{id}</span>
            </Grid.Column>
            <Divider />
            <Grid.Column style={{paddingTop: '10px'}}>
              <span className="title" style={{color: 'var(--pink)'}}><b>Terra Address:</b></span>
              <span className="value" style={{paddingLeft: '10px', color: '#999'}}>{terra_address}</span>
            </Grid.Column>
            <Divider />
            <Grid.Column style={{paddingTop: '10px'}}>
              <span className="title" style={{color: 'var(--pink)'}}><b>Last Payout: </b></span>
              <span className="value" style={{paddingLeft: '10px', color: '#999'}}>Nov-19-21</span>
            </Grid.Column>
            <Divider />
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};
export default PersonSummary;
