import React, { Component } from "react";
import styles from "../../index.module.scss";
import { withRouter } from "react-router-dom";
import { Container, Grid, Button, Image } from "semantic-ui-react";
import mp from "../../../../../staticAssets/images/home/icons/mp.svg";

class FeaturedCollections extends Component {
  render() {
    return (
      <>
        <Grid columns={1}>
          <div className={styles.hero}>
            <video autoPlay muted loop className={styles.heroVideo}>
              <source src="/assets/media/fansbg.mp4" type="video/mp4" />
            </video>
            <Container>
              <div className={styles.heading}>
                <Grid.Column>
                  <h3>
                    The NFT
                    <span>Music <img src="/assets/wavesound.svg" /> Hub</span>for Artists
                  </h3>
                  <a className={styles.button} href="https://nft-juno.loop.markets/"><Image className="icon" src="/assets/icons/music.svg" /> Browse NFTs</a>
                </Grid.Column>
              </div>
            </Container>
          </div>
        </Grid>
      </>
    );
  }
}
export default withRouter(FeaturedCollections);
