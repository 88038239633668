import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import feedReducer from '../util/feed/feedReducer';
import bountyFeedReducer from '../util/bountyFeed/feedReducer';
import userReducer from '../user/userReducer';
import postReducer from '../post/postReducer';
import editorReducer from '../util/dashboard/EditorReducer';
import imageReducer from '../util/articleEditor/imageAdd/ImageAddReducer';
import sidebarReducer from '../sidebar/SidebarReducer';
import articlesReducer from '../yourtrybe/articles/articlesReducer';
import SocialFeedReducer from '../yourtrybe/social/SocialFeedReducer';
import FollowingReducer from '../yourtrybe/following/FollowingReducer';
import userReviewReducer from '../user-review/userReviewReducer';
import NotificationsReducer from '../sidebar/notifications/NotificationsReducer';
import FriendsReducer from '../yourtrybe/friends/FriendsReducer';
import ChatReducer from '../chat/ChatReducer';
import SignUpReducer from '../sign-up/signUpReducer';
import settingsReducer from '../yourtrybe/settings/settingsReducer';
import bountyReducer from '../bounties/bountyReducer';
import homeReducer from '../pages/home/actions/reducer';
import marketReducer from '../pages/markets/actions/reducer';

export default combineReducers({
  form: formReducer,
  feed: feedReducer,
  bountyFeed: bountyFeedReducer,
  user: userReducer,
  post: postReducer,
  editor: editorReducer,
  image: imageReducer,
  articles: articlesReducer,
  sidebar: sidebarReducer,
  socialFeed: SocialFeedReducer,
  userReview: userReviewReducer,
  following: FollowingReducer,
  friends: FriendsReducer,
  notifications: NotificationsReducer,
  chat: ChatReducer,
  signUp: SignUpReducer,
  settings: settingsReducer,
  bounties: bountyReducer,
  home: homeReducer,
  markets: marketReducer
});
