import axios from 'axios';
import catchAxiosError from '../../util/catchAxiosError';

const BEARER_TOKEN = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImRhdGEiOnsidXNlcm5hbWUiOiJhdG9tbGF1bmNoIiwiZW1haWwiOiJlcmljQGxpbDJnb29kLmNvbSIsImNyZWF0ZWRfYXQiOiIyMDIxLTA4LTI0IDA3OjU5OjQzIiwidXBkYXRlZF9hdCI6IjIwMjEtMDgtMjQgMDc6NTk6NDMiLCJpZCI6MX0sImlhdCI6MTYyOTgxNzE4M30.4H1VCotSIHHBg2yImuv3DDXDTQkZatkvp2r0rChL1es`

export async function fetchTokenData() {
  //console.log("Fetched Tokens")
  const result = await axios
    .get(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v2/tokens/trending`,
      {headers: {Authorization: BEARER_TOKEN}})
    .catch(catchAxiosError);
  return result.data;
}

export async function addToken(payload) {
  //console.log(payload)
  const result = await axios
    .post(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v1/add/token`,
      payload,
      {headers: {Authorization: BEARER_TOKEN}})
    .catch(catchAxiosError);
  return result.data;
}

export async function removeToken(payload) {
  //console.log(payload)
  const result = await axios
    .post(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v1/delete/token`,
      payload,
      {headers: {Authorization: BEARER_TOKEN}})
    .catch(catchAxiosError);
  return result.data;
}

export async function fetchHeaderData() {
  const result = await axios
    .get(`${process.env.REACT_APP__MARKETS_BACKEND_API}/v2/header`,
      {headers: {Authorization: BEARER_TOKEN}});
  return result.data[0];
}
