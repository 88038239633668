import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, Grid } from "semantic-ui-react";
import { Swiper, SwiperSlide } from "swiper/react";
import influ from "../../../../../staticAssets/images/home/influ.png";
import artist from "../../../../../staticAssets/images/home/artist.png";
import music from "../../../../../staticAssets/images/home/music.png";

class Community extends Component {
  render() {
    return (
      <>
        <div className="community">
          <div className="container">
            <h3>
              <span>Community</span>
              Engage your Community <br className="break" />
              with Crypto-native NFTs
            </h3>
          </div>
          <div className="bg">
            <div className="container">
              <Swiper
                spaceBetween={80}
                navigation
                //pagination
                loop
                autoplay={{ delay: 3500 }}
                centeredSlides
                slideToClickedSlide
                breakpoints={{
                  1440: {
                    freemode: true,
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  1024: {
                    freemode: true,
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  768: {
                    freemode: true,
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  640: {
                    freemode: true,
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  320: {
                    freemode: true,
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
                keyboard={{
                  enabled: true,
                  onlyInViewport: true,
                }}
                //onSwiper={swiper => console.log(swiper)}
                //onSlideChange={() => console.log('slide change')}
              >
                <SwiperSlide>
                  <Card className="collection collection__influencer"
                    style={{
                      backgroundImage: `url(${influ})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}>
                    <div>
                      <Card.Content>
                        <Card.Header>
                          <div className="collection_profile">
                            <div className="collection_details">
                              <p className="details">Influencer</p>
                              <div hidden className="nft_button">Learn More</div>
                            </div>
                          </div>
                        </Card.Header>
                      </Card.Content>
                    </div>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    className="collection collection__artist"
                    style={{
                      backgroundImage: `url(${artist})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Card.Content>
                      <Card.Header>
                        <div className="collection_profile">
                          <div className="collection_details">
                            <p className="details">Artist</p>
                            <div hidden className="nft_button">Learn More</div>
                          </div>
                        </div>
                      </Card.Header>
                    </Card.Content>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    className="collection collection__music"
                    style={{
                      backgroundImage: `url(${music})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Card.Content>
                      <Card.Header>
                        <div className="collection_profile">
                          <div className="collection_details">
                            <p className="details">Musician</p>
                            <div hidden className="nft_button">Learn More</div>
                          </div>
                        </div>
                      </Card.Header>
                    </Card.Content>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="container communityInfo">
          <Grid columns={3} padded>
            <Grid.Column>
              <h4>Drive Engagement</h4>
              <p>Create your own community and connect with fans</p>
            </Grid.Column>
            <Grid.Column>
              <h4>Monetize Audience</h4>
              <p>Share NFTs with your Community to Monetize and Engage</p>
            </Grid.Column>
            <Grid.Column>
              <h4>Rewards for all</h4>
              <p>Stakeable NFTs with Royalties and Rewards</p>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
}
export default withRouter(Community);
