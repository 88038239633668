import React, { Component, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Grid,
  Container,
} from "semantic-ui-react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { setPageForGoogleAnalytics } from "../../util/helperFunctions";
import * as api from "./Api";
import Footer from "../../footer/Footer";

import VideoElement from "./components/video_element";
import "./support.scss";

import Looplogo from "../../../staticAssets/images/LOOPlogo_bage2.svg";
import MsgIcon from "../../../staticAssets/images/msg_icon.png";
import facebook from "../../../staticAssets/images/LinkLogo/facebook.png";
import youtube from "../../../staticAssets/images/LinkLogo/youtube.png";
import instagram from "../../../staticAssets/images/LinkLogo/instagram.png";
import discord from "../../../staticAssets/images/LinkLogo/discord.png";
import telegram from "../../../staticAssets/images/LinkLogo/telegram.png";
import linkedin from "../../../staticAssets/images/LinkLogo/linkedin.png";
import twitter from "../../../staticAssets/images/LinkLogo/twitter.png";

// import chainceLogo from '../../staticAssets/images/chaince-logo.png';
// import worbliLogo from '../../staticAssets/images/worbli-logo.jpg';
// import eosphereLogo from '../../staticAssets/images/eosphere-logo.png';
// import triangles from '../../staticAssets/images/triangles.png';
// import blockchain from '../../staticAssets/images/blockchain.png';
// import arrowRight from '../../staticAssets/images/arrow-right.svg';
// import playImage from '../../staticAssets/images/play-circle.svg';
// import teamImage from '../../staticAssets/images/team.png';

const INTERCOM_APP_ID = 'ziiaz8xc';

class Support extends Component {
  constructor(props){
      super(props);
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        // other settings you'd like to use to initialize Intercom
      });
  }

  render() {
    return (
      <Grid className="page-wrapper supportBG">
        <Helmet>
          <title>Loop Finance | Support</title>
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-KBD87CK"></script>
        </Helmet>
        <Container className="support">
          <div className="board">
            <div className="board-title">Most Helpful Tutorials</div>
            <div className="board-body">
              <div className="board-tutorial-body">
                <div className="tutorial-left">
                  <div className="video-item-row">
                    <div className="video-screen">
                      <VideoElement id="4uqbjGpivC8" />
                    </div>
                    <div className="video-details">
                      <h3>How to setup your KEPLR wallet?</h3>
                      <p>
                        If you are new to the Cosmos ecosystem, you will surely want to learn how to create a Keplr Wallet. <a href="https://www.loop.markets/how-to-create-a-keplr-wallet/">Read Article...</a>{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tutorial-right">
                  <div className="video-item-row">
                    <div className="video-screen">
                      <VideoElement id="XDgGIQEy-tc" />
                    </div>
                    <div className="video-details">
                      <h3 className="video-title">
                        Fund using Kraken
                      </h3>
                      <p>
                        In this video we are showing step by step how to buy $JUNO tokens on Kraken exchange and then transfer them to KEPLR wallet.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="video-item-row">
                    <div className="video-screen">
                      <VideoElement id="4GZYSmQMtDA" />
                    </div>
                    <div className="video-details">
                      <h3 className="video-title">
                        Fund using Binance
                      </h3>
                      <p>
                        In this video we are going to show you step by step, how to fund your KEPLR wallet using Binance.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="video-item-row">
                    <div className="video-screen">
                      <VideoElement id="Xbtfgfd6-Yw" />
                    </div>
                    <div className="video-details">
                      <h3 className="video-title">
                        How to transfer funds
                      </h3>
                      <p>
                        Hey Loopers! In this video we are going to show you how to transfer funds from other chains onto the Juno Network.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="video-item-row">
                    <div className="video-screen">
                      <VideoElement id="Pd2wjKdNC20" />
                    </div>
                    <div className="video-details">
                      <h3 className="video-title">What is a DeFi NFT Marketplace?</h3>
                      <p>
                        What is a DeFi NFT Marketplace and what innovation do DeFi NFTs bring to crypto space? <a href="https://www.loop.markets/using-reward-nfts-for-user-engagement-sustainable-token-distribution-and-more/">Read Article...</a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="video-item-row">
                    <div className="video-screen">
                      <VideoElement id="iLs7UyFMurQ" />
                    </div>
                    <div className="video-details">
                      <h3 className="video-title">How to mint a Loop NFT?</h3>
                      <p>
                        Hey Loopers! In this video we are showing you how to unbox/mint the beta tester Apes on the Loop NFT Marketplace! {" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="board">
            <div className="board-title more-title">
              More Tutorials
              <a className="board-title-subtitle" href="https://www.youtube.com/channel/UCecsvrIVwT-bgy6-lDIEBTQ">
                Visit
                <div>
                  <img src={Looplogo} alt="logo" />
                  LOOP LEARN
                </div>
                to learn more
              </a>
            </div>
            <div className="board-body">
              <div className="board-tutorial-more-body">
                <div className="video-item-col">
                  <div className="video-screen">
                    <VideoElement id="4Gkm_P7y7EE" />
                  </div>
                  <div className="video-details">
                    <h3 className="video-title">How do Liquidity Pools Work?</h3>
                    <p>
                      A liquidity pool is a crowdsourced pool of cryptocurrencies or tokens locked in a smart contract on a decentralized exchange (DEX). . .{" "}
                    </p>
                  </div>
                </div>
                <div className="video-item-col">
                  <div className="video-screen">
                    <VideoElement id="3ZQjK_PesKo" />
                  </div>
                  <div className="video-details">
                    <h3 className="video-title">What is Impermanent Loss?</h3>
                    <p>
                      Impermanent loss is a temporary loss of funds occurring when providing liquidity. More on this misunderstood concept in this video. . .{" "}
                    </p>
                  </div>
                </div>
                <div className="video-item-col">
                  <div className="video-screen">
                    <VideoElement id="lMCs9PDu4UQ" />
                  </div>
                  <div className="video-details">
                    <h3 className="video-title">How to Use Loop DEX Pools</h3>
                    <p>
                      The Loop Decentralized Exchange has a variety of trading pools. Here's how to get involved and earn high APRs from your favorite cryptocurrency pools. . .{" "}
                    </p>
                  </div>
                </div>
                <div className="video-item-col">
                  <div className="video-screen">
                    <VideoElement id="z6oZdlOEdSo" />
                  </div>
                  <div className="video-details">
                    <h3 className="video-title">What is Liquidity Farming?</h3>
                    <p>
                      Liquidity mining, also known as yield farming, is a strategy used by decentralized finance investors to earn impressive yields on their capital. . .{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div hidden className="board-foot">
              <div className="board-tutorial-more-foot">
                <button>
                  <img src={MsgIcon} alt="" />
                  Ask any query
                </button>
              </div>
            </div>
          </div>

          <div className="board">
            <div className="board-cta-support">
              <img src={MsgIcon} alt="" />
              <h4>Still having issues?</h4>
              <p>Leave us a message below and we will get back to you ASAP!</p>
            </div>
          </div>

          <div className="board">
            <div className="board-support-link">
              <h2>
                Follow us <br /> to stay updated
              </h2>
              <div className="link-line">
                <a href="https://twitter.com/loop_markets" target="_blank">
                  <img className="link-item" src={twitter} />
                </a>
                <a href="https://t.co/F8M5MpkDgP" target="_blank">
                  <img className="link-item" src={telegram} />
                </a>
                <a href="https://t.co/IxkmQgjkqD" target="_blank">
                  <img className="link-item" src={discord} />
                </a>
                <a href="https://www.youtube.com/channel/UCecsvrIVwT-bgy6-lDIEBTQ" target="_blank">
                  <img className="link-item" src={youtube} />
                </a>
                <a href="https://www.linkedin.com/company/loop-markets" target="_blank">
                  <img className="link-item" src={linkedin} />
                </a>
                <a href="https://www.instagram.com/loop_finance/" target="_blank">
                  <img className="link-item" src={instagram} />
                </a>
              </div>
            </div>
          </div>
        </Container>

        {/* ===== Footer ===== */}
        <Footer />
        {/* ===== Footer End ===== */}
      </Grid>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      isAdmin: state.user.userInfo.extra_capabilities,
    }),
    // state => ({
    //   isAuthorized: state.user.userIsLogged
    // }),
    null
  )(Support)
);
