import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Table, Divider, Card } from 'semantic-ui-react';
import ModalExampleShorthand from '../../modals/tokenAction.modal';
import sendIcon from '../../assets/send-icon.png';
import receiveIcon from '../../assets/receive-icon.png';
import swapIcon from '../../assets/swap-icon.png';
import addwlIcon from '../../assets/addwl-icon.png';
const DefaultIcon = require('../../assets/cosmos-token.svg');

const MarketTable = (props) => {
  //Formatter
  const numFormatter = (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(0) + "B"
      // Six Zeroes for Millions
      : Math.abs(Number(labelValue)) >= 1.0e+6
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(0) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(0) + "K"
          : Math.abs(Number(labelValue));
  }

  const [tableIndex, setIndex] = useState(Number);
  const [tableHover, setHover] = useState(Boolean);
  const [modalStatus, setModalStatus] = useState(Boolean);
  const [modalType, setModalType] = useState(Boolean);
  const [tokenSelected, setokenSelected] = useState('');

  const { data, isAuthorized } = props;

  let totalMarket = 0;
  data?.data.map((data: any) => { totalMarket = totalMarket + parseInt(data.market_cap) });

  const tokenAction = (type: any, asset: string) => {
    // Action Modal
    if (type == 'close') {
      setModalStatus(!modalStatus)
      setModalType(null);
    } else {
      setModalStatus(!modalStatus);
      setModalType(type);
      setokenSelected(asset);
    }
  }

  const tokenActions = (asset: string) => {
    return (
      <div className="market_actions">
        <span className="action_button action_button__purple" onClick={() => tokenAction('Receive', asset)}>Receive <img src={receiveIcon} style={{verticalAlign: 'sub'}} /></span>
        <span className="action_button action_button__cyan" onClick={() => tokenAction('Send', asset)}>Send <img src={sendIcon} style={{verticalAlign: 'sub'}} /></span>
        <span className="action_button action_button__cyan action_button__swap" onClick={() => tokenAction('Swap', asset)}>Swap <img src={swapIcon} style={{verticalAlign: 'sub'}} /></span>
      </div>
    )
  };

  const linkAction = (symbol: String) => {
    window.location.href = `${window.location.protocol}//${window.location.host}/token/${symbol.toLowerCase()}`;
  }

  const setTableHover = (status: boolean, index: number, symbol: string) => {
    const blacklist = ['BTC']
    if (!blacklist.includes(symbol)) {
      setHover(status);
      setIndex(index)
    }
  }

  const printCurrentPrice = (current_price: any) => {
    let price;
    if(current_price >= 1){
      price = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits:2}).format(parseFloat(current_price));
    } else {
      price = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits:6}).format(parseFloat(current_price));
    }
    return price;
  }
  return (
        <>
          <ModalExampleShorthand open={modalStatus} closeAction={() => tokenAction('close', null)} modalType={modalType} asset={tokenSelected} />
          {/*=================================*/}
          {/*========== Market Table =========*/}
          {/*=================================*/}

          <Divider />
          <table className="ui sortable table table_header tablet desktop_view">
            <Table.Header>
              <Table.Row className="portfolio-table-row">
                <Table.Cell style={{width: '2.25%'}}>#</Table.Cell>
                <Table.Cell width="2">Name</Table.Cell>
                <Table.Cell width="2">Price</Table.Cell>
                <Table.Cell width="2" className="change">24H</Table.Cell>
                <Table.Cell width="2" className="change">7D</Table.Cell>
                <Table.Cell width="2" className="change_last">30D</Table.Cell>
                <Table.Cell width="2">Market Cap</Table.Cell>
                <Table.Cell width="2">Volume</Table.Cell>
                {/*
                  <Table.Cell width="2">Circ Supply</Table.Cell>
                */}
              </Table.Row>
            </Table.Header>
          </table>
          <table className="ui sortable table tablet desktop_view">
            {data && (
              <Table.Body>
                {data?.data.map((data: any, index: any) => {
                  // Return the element. Also pass key
                  return (
                    <Table.Row key={data.symbol} onMouseEnter={() => setTableHover(true, index, data.symbol)} onMouseLeave={() => setTableHover(false, index, data.symbol)}>
                      <Table.Cell style={{width: '2.25%'}}>
                        {data?.rank}
                      </Table.Cell>
                      <Table.Cell width="2" /*onClick={() => linkAction(data.symbol)} style={{cursor: 'pointer'}}*/>
                      {/*<Table.Cell width="2">*/}
                        <div className="icon-table">
                          <img
                            style={{ width: '40px', borderRadius: '25px' }}
                            src={data.icon}
                            alt=""
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src=DefaultIcon;
                            }}
                          />
                          <p style={{ display: 'block', marginLeft: '10px' }}> {data.name}
                            <br />
                            <small>{data?.symbol}</small>
                          </p>
                        </div>
                      </Table.Cell>
                      <Table.Cell width="2">
                        <p style={{ display: 'block', marginLeft: '10px' }}>
                          {printCurrentPrice(data.current_price)}
                          <br />
                          <small style={{display: 'none'}}>Bal: <span>$111,111</span></small>
                        </p>
                      </Table.Cell>
                      <Table.Cell width="2" className="change">
                        {data?.price_change_percentage_24h > -0.01 ? <span className="marketUp">{data?.price_change_percentage_24h}%</span> : <span className="marketDown">{data?.price_change_percentage_24h}%</span>}
                      </Table.Cell>
                      <Table.Cell width="2" className="change">
                        {data?.price_change_percentage_7d > -0.01 ? <span className="marketUp">{data?.price_change_percentage_7d}%</span> : <span className="marketDown"> {data?.price_change_percentage_7d}%</span>}
                      </Table.Cell>
                      <Table.Cell width="2" className="change_last">
                        {data?.price_change_percentage_30d > -0.01 ? <span className="marketUp">{data?.price_change_percentage_30d}%</span> : <span className="marketDown"> {data?.price_change_percentage_30d}%</span>}
                      </Table.Cell>
                      <Table.Cell width="2">
                        {data?.market_cap ? `$${data?.market_cap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '?'}
                      </Table.Cell>
                      {false && tableIndex == index && tableHover ? (
                        <Table.Cell width="2">
                          {tokenActions(data.symbol)}
                        </Table.Cell>
                      ) : (
                        <>
                        <Table.Cell width="2">
                          {data?.market_volume ? `$${numFormatter(data?.market_volume)}` : '?'}
                          <br />
                          <small>{data?.market_volume && data?.current_price ? new Intl.NumberFormat('en-US', {currency: 'USD', maximumFractionDigits:0}).format((data?.market_volume / data.current_price)) : '?'}</small>
                        </Table.Cell>
                        {/*
                          <Table.Cell width="2">
                            {data?.circulating_supply ? `${data?.circulating_supply.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${data.symbol}` : '?'}
                            <br />
                            <small>{(100 * (data?.circulating_supply / data?.max_supply)).toFixed(2)}%</small>
                          </Table.Cell>
                        */}
                        </>
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            )}
          </table>
          <div className="mobile_view" style={{marginTop: '25px'}}>
            {data?.data.map((data: any, index: any) => {
              // Return the element. Also pass key
              return (
                <>
                  <Card>
                    <Card.Header>
                      <div className="rank">#{data?.rank}</div>
                      <div className="_header">
                        <div className="icon-table">
                          <img
                            style={{ width: '40px', borderRadius: '25px' }}
                            src={data.icon}
                            alt=""
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src=DefaultIcon;
                            }}
                          />
                          <p style={{ display: 'block', marginLeft: '10px' }}> {data.name}
                            <br />
                            <small>{data?.symbol}</small>
                          </p>
                        </div>
                        <p className="fr"><span>{printCurrentPrice(data.current_price)}</span></p>
                      </div>
                    </Card.Header>
                    <Card.Description>
                      <div className="desc mt-20">
                        <div>
                          <p className="fs">Market: <span>{data?.market_cap ? `$${numFormatter(data?.market_cap)}` : '?'}</span></p>
                          <p className="fs">Volume: <span>{data?.market_volume ? numFormatter(data?.market_volume) : '?'}</span></p>
                        </div>
                        <div>
                          <p className="fs">24h: <span>{data?.price_change_percentage_24h > -0.01 ? <span className="marketUp">{data?.price_change_percentage_24h}%</span> : <span className="marketDown">{data?.price_change_percentage_24h}%</span>}</span></p>
                          <p className="fs">7d: <span>{data?.price_change_percentage_7d > -0.01 ? <span className="marketUp">{data?.price_change_percentage_7d}%</span> : <span className="marketDown">{data?.price_change_percentage_7d}%</span>}</span></p>
                        </div>
                      </div>
                    </Card.Description>
                  </Card>
                </>
              )
            })}
          </div>
        </>
    );
  }

  export default  withRouter(
    connect(
      (state: any) => {
        return {
          isAuthorized: state.user.userIsLogged,
        };
      },
    )(MarketTable)
  );
