import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Button, Container, Grid, Button } from "semantic-ui-react";
import styles from "../../index.module.scss";
import market from "../../../../../staticAssets/images/home/market.png";
import dex from "../../../../../staticAssets/images/home/dex.png";
import mp from "../../../../../staticAssets/images/home/icons/mp.svg";
import trade from "../../../../../staticAssets/images/home/icons/trade.svg";

class ServicesSection extends Component {
  render() {
    return (
      <>
        <div className={styles.section}>
          <Container>
            <b>Benefits</b>
            <h3>Earn & Monetize your Art</h3>
            <div className={styles.services}>
              <div className={styles.service_item}>
                <Image
                  className={styles.serviceImage}
                  src="/assets/icons/artist.png"
                />
                <div className={styles.content}>
                  <h4>100+ Artists </h4>
                  <p>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit.
                  </p>
                </div>
              </div>
              <div className={styles.service_item}>
                <Image
                  className={styles.serviceImage}
                  src="/assets/icons/ownmusic.png"
                />
                <div className={styles.content}>
                  <h4>Own your music</h4>
                  <p>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit.
                  </p>
                </div>
              </div>
              <div className={styles.service_item}>
                <Image
                  className={styles.serviceImage}
                  src="/assets/icons/future.png"
                />
                <div className={styles.content}>
                  <h4>Be the Future</h4>
                  <p>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(ServicesSection);
