import React, { Component } from 'react';
import { Container, Grid, Card } from 'semantic-ui-react';
import { Container, Grid, Button, Image, Header, Card, List, Table, Tab, Menu, GridColumn, Segment, Modal, Icon, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../../footer/Footer';

class Wallets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acfData: null
    };
  }

  componentDidMount() {
    // setPageForGoogleAnalytics('download');
  }

  render() {

    return (
      <><div className="downloadPage">
        <div className="downloadPageContent">
          <h6>Setting Up a Wallet on the Terra Blockchain</h6>
          <p>Blockchain Wallets are used to store your tokens and connect to and interact with the blockchain.</p>
          <p>A wallet can be either a mobile app or a Chrome extension. Anyone can download a wallet and set it
            up in less than 2 minutes! </p>
          <p>Watch the videos below to learn how to set up a Terra wallet and start using Loop.</p>
        </div>
        
        <div className="downloadPageVideos">
          <div className="downloadPageVideosLeft">
            <h6>Chrome Extension Wallet</h6>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/tHVjzGMwb-8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            {/*
            <div className="downloadButtons">
              <button><a href="https://chrome.google.com/webstore/detail/terra-station-wallet/aiifbnbfobpmeekipheeijimdpnlpgpp?hl=en"><img src="img/googlechrome.png" alt="" /> Download <img src="img/arrow.png" alt="" /></a></button>
            </div>
            */}
          </div>
          <div className="downloadPageVideosLeft">
            <h6>Mobile Wallet</h6>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/U5Qic6mm94Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            {/*
            <div className="downloadButtons">
              <button><a href="https://play.google.com/store/apps/details?id=money.terra.station"><img src="img/googleStore.png" alt="" /> Playstore <img src="img/arrow.png" alt="" /></a></button>
              <button><a href="https://apps.apple.com/us/app/terra-station/id1548434735"><img src="img/appStore.png" alt="" /> Appstore <img src="img/arrow.png" alt="" /></a></button>
            </div>
            */}
          </div>
        </div>
        <div className="downloadBottom">
          <h6>Why Do I Need a Wallet?</h6>
          <p>Loop is a blockchain application, meaning that you will need your own wallet in order to use it.Unlike centralized exchanges, such as Coinbase and Binance, we do not take custody of your assets and they will always remain in your wallet.</p>
          <h5>The advantages of this are many:</h5>
          <ul>
            <li>- You do not need to KYC (prove your identity)</li>
            <li>- We can never freeze or block your account or get access to your funds</li>
            <li>- Your funds are safer than they are on a centralized exchange</li>
          </ul>
          <h4>However, taking control of your own assets comes with great responsibility!<img src="img/shadowPinku.png" alt="" /></h4>
          <p><img src="img/iDownload.png" alt="" /> Always write down your seed phrase and store multiple copies safely as ONLY YOU can recover your wallet if you lose it (we never have access to it)</p>
        </div>
      </div>
      {/* ========================== Footer ====================== */}
        <Footer />
      {/* ========================== Footer End ====================== */}
      </>
    );
  }
}
export default withRouter(
  connect(
    // state => ({
    //   isAuthorized: state.user.userIsLogged
    // }),
    null
  )(Wallets)
);
