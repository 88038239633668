import React from 'react';

const Page = () => {
  return (
    <div className="page404">
      <h2 style={{ textAlign: 'center' }}>Sorry. The page doesnt exist</h2>
    </div>
  );
};

export default Page;
