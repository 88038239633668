import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import * as api from '../../home/Api';

class MarketTrendingHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trendingData: {}
    };
  }

  async componentDidMount() {
    await api.fetchTrendingData()
      .then((trendingData) => {
        console.log(trendingData);
        this.setState({trendingData});
      });
  }

  render() {
    const {trendingData} = this.state;

    return (
      <div className="marketsHeader">
        <ul>
          <li>Cryptos: <span>{trendingData ? trendingData.total_tokens : '...'}</span></li>
          <li>Market cap: <span>{trendingData ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(trendingData.market_cap) : '..'}</span></li>
          {/*<li>24h Vol: <span>$70,319,996,588</span></li>*/}
          {/*<li>Dominance: <span>BTC: 46.3% | ETH: 17.5%</span></li>*/}
        </ul>
      </div>
    );
  }
}


export default withRouter(MarketTrendingHeader);
