import React, { Component } from 'react';
import { Button, Tab, Menu, Modal, Icon, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import * as actions from '../../yourtrybe/articles/articlesActions';
import * as marketApi from '../markets/Api';
import Hero from './widgets/hero/index';
import ServicesSection from './widgets/services/index';
import CollectionsSection from './widgets/collections/index';
import Community from './widgets/community/index';
import CTASection from './widgets/cta/index';
import Banner from './widgets/banner/index';
import Footer from '../../footer/Footer';

class Fans extends Component {
  constructor(props) {
    SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
    super(props);
    this.state = {
      walletModal: false
    };
  }

  async componentDidMount() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'fanspage_view',
      'event_category': 'page',
      'event_action': 'fanspage_view'
    });
  }

  render() {
    const { walletModal } = this.state;
    return (
      <main className="homepage HomePageFull">
        <div className="home">
          <Hero />
          <CollectionsSection />
          <ServicesSection />
          <CTASection />
          <Banner />
          <Footer />
        </div>
      </main >
    );
  }
}
export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        promos: state.articles.promos,
        isAuthorized: state.user.userIsLogged,
        userInfo: state.user.userInfo,
      };
    }
  )(Fans)
);