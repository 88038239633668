import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Image, Card, Form, Button, Header, Icon, Loader, Grid, Button, Divider, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as actions from '../../actions/actions';
import nft1 from './assets/neta-troopers6512873.png';
import nft2 from './nft2.svg';
import nft3 from './nft3.svg';
import nft4 from './nft4.svg';
import nft5 from './nft5.svg';

import profile from './profile.svg';
import profile2 from './profile2.svg';
import profile3 from './profile3.svg';
import { className } from 'classnames';

class FeaturedCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenData: null,
      hideForm: false,
      show: false,
      tokenName: ''
    };
  }

  async componentDidMount() {
    const {getFeaturedNfts} = this.props;
    await getFeaturedNfts();
  }

  render() {
    const {
      featuredNfts,
      featuredNftsLoaded
    } = this.props;

    return (
      <>
        <div className="featured_collections">
          <h3>Featured NFT Collections</h3>
          {featuredNftsLoaded ? (
            <Swiper
              spaceBetween={5}
              //navigation
              loop
              autoplay={{ delay: 3500 }}
              //centeredSlides
              slideToClickedSlide
              breakpoints={{
                1440: {
                  freemode: true,
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1024: {
                  freemode: true,
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                768: {
                  freemode: true,
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                640: {
                  freemode: true,
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                320: {
                  freemode: true,
                  slidesPerView: 1,
                  spaceBetween: 20,
                }
              }}
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
            //onSwiper={swiper => console.log(swiper)}
            //onSlideChange={() => console.log('slide change')}
            >
              {featuredNfts?.data.map((element, index) => {
                return(
                  <SwiperSlide>
                    {element.coming_soon ? (
                      <Card className="collection">
                      <Image src={process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS+element.collection_image} wrapped ui={false} className="featured_nft_image featured_nft_image_cmsoon" />
                      <span className="status status_cmsoon">COMING SOON</span>
                      {element.artist.name != 'null' && (
                        <Card.Content>
                          <Card.Header>
                            <div className="collection_profile">
                              <Image src={process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS+element.artist.artist_image} wrapped ui={false} />
                              <div className="collection_details">
                                <p className='details'>
                                {element.collection_name}
                                <br />
                                <span>Project: <span>{element.artist.name}</span></span>
                                </p>
                                <div className='nft_button'>{element.coming_soon ? 'Coming Soon' : 'View Collection'}</div>
                              </div>
                            </div>
                          </Card.Header>
                        </Card.Content>
                      )}
                    </Card>
                    ) : (
                    <a href={element.url}>
                      <Card className="collection">
                        <Image src={process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS+element.collection_image} wrapped ui={false} className="featured_nft_image" />
                        <span className="status">{element.launching ? 'LAUNCHING' : 'LIVE'}</span>
                        {element.artist.name != 'null' && (
                          <Card.Content>
                            <Card.Header>
                              <div className="collection_profile">
                                <Image src={process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS+element.artist.artist_image} wrapped ui={false} />
                                <div className="collection_details">
                                  <p className='details'>
                                  {element.collection_name}
                                  <br />
                                  <span>Project: <span>{element.artist.name}</span></span>
                                  </p>
                                  <div className='nft_button'>{element.coming_soon ? 'Coming Soon' : 'View Collection'}</div>
                                </div>
                              </div>
                            </Card.Header>
                          </Card.Content>
                        )}
                      </Card>
                    </a>
                    )}
                  </SwiperSlide>
                )}
              )}
            </Swiper>
          ) : (
            <Loader active/>
          )}
        </div>
      </>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      featuredNftsLoaded: state.markets.featuredNftsLoaded,
      featuredNfts: state.markets.featuredNfts,
    }),
    dispatch => ({
      getFeaturedNfts: () => dispatch(actions.getFeaturedNfts())
    })
  )(FeaturedCollections)
);
