import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Accordion, Modal, Container, Image, Icon, Progress, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setPageForGoogleAnalytics } from '../../../util/helperFunctions';
import * as api from './Api';
import Helmet from 'react-helmet';
import TokenCard from './tokenCard';
import Footer from '../../../footer/Footer';
import { Line } from 'react-chartjs-2';
import watchlistIcon from '../assets/watchlist.png'
import searchIcon from '../assets/search.png'
import communityIcon from '../assets/community.png'
import codeIcon from '../assets/code.png'
import whitepaperIcon from '../assets/whitepaper.png'
import websiteIcon from '../assets/website.png'

// import parslLogo from '../../staticAssets/images/parsl-logo.png';
// import chainceLogo from '../../staticAssets/images/chaince-logo.png';
// import worbliLogo from '../../staticAssets/images/worbli-logo.jpg';
// import eosphereLogo from '../../staticAssets/images/eosphere-logo.png';
// import triangles from '../../staticAssets/images/triangles.png';
// import blockchain from '../../staticAssets/images/blockchain.png';
// import arrowRight from '../../staticAssets/images/arrow-right.svg';
// import playImage from '../../staticAssets/images/play-circle.svg';
// import teamImage from '../../staticAssets/images/team.png';

class TokensPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenData: null,
      activeIndex: null,
      volumeSelect: [{text: '24h', change:40}]
    };
  }

  componentDidMount() {
    const asset = window.location.pathname.replace("/token/", "");
    api.fetchTokenData(asset).then(tokenData => this.setState({ tokenData }));
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  tokenTags = () => {
    const {tokenData} = this.state;
    return (
      <ul>
        {tokenData.tags?.map(t => {
            return <li className="tag">{t.name}</li>
          })
        }
        <li className="tag more">More</li>
      </ul>
    )
  }

  volumeMenu = () => {
    const content = [
      {change: 60,text: '24h'},
      {change: 40,text: '7h'},
      {change: 90,text: '1w'},
      {change: 30,text: '1m'},
      {change: 55,text: '1y'},
      {change: 72,text: 'All time'}
    ];
    const { volumeSelect } = this.state;
    return (
      <ul>
        {content.map(content => <li className={volumeSelect[0].text == content.text ? 'active' : ''} onClick={() => this.setState({volumeSelect: [{text: content.text, change:content.change}]})}>{content.text}</li>)}
      </ul>
    )
  };

  tokenLinks = () => {
    const { activeIndex, tokenData} = this.state;
    const content = [
      {text: 'Explorers', icon: searchIcon, id:1},
      {text: 'Community', icon: communityIcon, id:2}
    ];
    return (
      <>
        {tokenData.token?.map(t =>
          <>
          <div className="item">
            <a href={`https://${t.web}`} target="_blank" rel='nofollow' >
              <img src={websiteIcon} style={{color: '#444C64', width: '20px'}} />
              <span>{t.web}</span>
              <img src="/img/open_array.svg" alt="" style={{float: 'right'}}/>
            </a>
        </div>
        <div className="item">
          <Accordion vertical>
            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={this.handleClick}
            >
              <img src={searchIcon} style={{color: '#444C64', width: '20px'}} />
              <span>Explorers</span>
              <Icon name='angle down' style={{float: 'right', color: '#444C64', fontSize: '24px'}} />
            </Accordion.Title>
            {tokenData?.explorers?.map(r =>
              <Accordion.Content active={activeIndex === 2}>
                <div className="socialLinks">
                  <a href={`https://${r.url}`} target="_blank" rel='nofollow' >{r.name}</a>
                </div>
              </Accordion.Content>
            )}
          </Accordion>
        </div>
        <div className="item">
          <Accordion vertical>
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.handleClick}
            >
              <img src={communityIcon} style={{color: '#444C64', width: '20px'}} />
              <span>Community</span>
              <Icon name='angle down' style={{float: 'right', color: '#444C64', fontSize: '24px'}} />
            </Accordion.Title>
            {tokenData?.socials.map(r =>
              <Accordion.Content active={activeIndex === 1}>
                <div className="socialLinks">
                  <a href={r.url} target="_blank" rel='nofollow' >{r.name}</a>
                </div>
              </Accordion.Content>
            )}
          </Accordion>
        </div>
        <div className="item">
          <a href={`${t.code}`} target="_blank" rel='nofollow' >
            <img src={codeIcon} style={{width: '20px'}} />
            <span>Source Code</span>
            <img src="/img/open_array.svg" alt="" style={{float: 'right'}}/>
          </a>
        </div>
        <div className="item">
          <a href={`https://${t.wp}`} target="_blank" rel='nofollow' >
            <img src={whitepaperIcon} style={{width: '20px'}} />
            <span>Whitepaper</span>
            <img src="/img/open_array.svg" alt="" style={{float: 'right'}}/>
          </a>
        </div>
        </>
        )}
      </>
    )
  }


  tokenStats = () => {
    const { activeIndex, volumeSelect, tokenData } = this.state;
    return (
      <>
      {this.volumeMenu()}
      <Progress className="percentProgress" percent={volumeSelect[0].change} />
      <div className="split">
        <p className="gray fw-4"><span>Low</span> <span>High</span></p>
        <p><span>$36,679.23**</span> <span>$40,333.19**</span></p>
      </div>
      <div className="split">
        <p className="gray fw-4"><span>Volume</span></p>
        <p><span>{new Intl.NumberFormat('en-US', {style:'currency',currency: 'USD'}).format(tokenData.market_data[0].market_volume)}</span> <span>-2.74%**</span></p>
      </div>
      </>
    )
  }

  render() {
    const { tokenData } = this.state;
    return (
      tokenData ? tokenData.token?.map(t =>
      <div>
        <Helmet>
          <title>Loop Finance | {t.name}</title>
        </Helmet>
        <div className="markets tokenView">
          <Container>
            <p className="market_location"><a href="/">Market &#x203A;</a> {t.symbol} </p>
            <div className="tokenHeader">
              {/*=================================*/}
              {/*============ Details ============*/}
              {/*=================================*/}
              <div className="tokenCard">
                <TokenCard header={
                  <>
                    <div>
                      <Image floated='left' style={{width: '50px'}} src={t.icon}  />
                      <Card.Header>{t.name}</Card.Header>
                      <Card.Meta><span className="rank">Rank {t.rank ? t.rank : "N/A"}</span> {t.symbol}</Card.Meta>
                    </div>
                    <button className="btn" floated='right'><Icon name='star outline' />  Watchlist</button>
                  </>
                }
                body={this.tokenLinks()}
                footer={this.tokenTags()}
                />
              </div>
              {/*=================================*/}
              {/*============= Volume ============*/}
              {/*=================================*/}
              <div className="tokenCard xl">
                <TokenCard header={
                  <>
                    <div>
                      <Card.Meta style={{fontSize: '14px'}}>Current Price</Card.Meta>
                      <Card.Header style={{fontSize: '24px'}}>${tokenData.market_data[0].current_price}</Card.Header>
                    </div>
                    <span className={tokenData.market_data[0]?.price_change_percentage_24h > 0.01 ? "change up" : "change down"}>{tokenData.market_data[0]?.price_change_percentage_24h}%</span>
                  </>
                }
                body={this.tokenStats()}
                footer={
                  <>
                  <div className="split">
                    <p className="gray fw-4"><span>Marketcap</span></p>
                    <p><span>{new Intl.NumberFormat('en-US', {style:'currency',currency: 'USD'}).format(tokenData.market_data[0].market_cap)}</span> <span>+6.74%**</span></p>
                  </div>
                  <div className="split">
                    <p className="gray fw-4"><span>Fully Diluted Market Cap</span></p>
                    <p><span>{new Intl.NumberFormat('en-US', {style:'currency',currency: 'USD'}).format(tokenData.market_data[0].market_cap_diluted)}</span> <span>+6.74%**</span></p>
                  </div>
                  </>
                }
                />
              </div>
              {/*=================================*/}
              {/*============= Swap ==============*/}
              {/*=================================*/}
              <div style={{display: "none"}} className="tokenCard">
                <TokenCard header={
                  <>
                    <div>
                      <Image floated='left' style={{width: '50px'}} src={t.icon}  />
                      <Card.Header>{t.name}</Card.Header>
                      <Card.Meta><span className="rank">Rank {t.rank}</span> {t.symbal}</Card.Meta>
                    </div>
                    <button className="btn" floated='right'><Icon name='star outline' />  Watchlist</button>
                  </>
                }
                body={this.tokenLinks()}
                footer={this.tokenTags()}
                />
              </div>
            </div>
            <div className="chartHeader">
              {/*=================================*/}
              {/*============= Chart =============*/}
              {/*=================================*/}
              <div className="tokenCard xl">
                <TokenCard header={
                  <>
                    <div>
                      <Card.Header>Chart</Card.Header>
                    </div>
                  </>
                }
                   body={
                  <>
                    <div className="chart">

                    </div>
                  </>
                   }
                   footer={this.tokenTags()}
                />
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    ) : 'Loading....');
  }
}
export default withRouter(
  connect(
    state => ({
      isAdmin: state.user.userInfo.extra_capabilities,
    }),
    // state => ({
    //   isAuthorized: state.user.userIsLogged
    // }),
    null
  )(TokensPage)
);
