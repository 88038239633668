import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Grid, Image, Label, Loader } from 'semantic-ui-react';
import * as actions from '../post/postActions';
// import LazyImage from '../util/feed/components/LazyLoad';
import he from 'he';
import moment from 'moment';
import CommentsList from './components/CommentsList';
import AddComment from './components/AddComment';
import * as api from './bountyApi';
import {
  Grid,
  Image,
  Card,
  Dropdown
} from 'semantic-ui-react';
class BountiesArticles extends Component {
  constructor(props) {
    super(props);
    this.publishReply = this.publishReply.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
    this.defaultClickHandler = this.defaultClickHandler.bind(this);
    this.allowLoginPopup = this.allowLoginPopup.bind(this);
    this.state = {
      bountyFeed: [],
      tab: 1,
      activePage: 1,
      show: false
    };
  }

  componentDidMount() {
    const { getPostComments, info } = this.props;
    const { activePage } = this.state;
      info.id && getPostComments({ post: info.id, page: activePage });
  }

  changeTab = (tab) => {
    this.setState({ tab });
  };

  publishReply = ({ text, replyId, parent }) => {
    const { cookies, sendReply, info, userInfo } = this.props;
    const token = cookies.get('trybe_jwt', { path: '/' });
    sendReply({ token, id: info.id, parent, text, authorId: info.author, userId: userInfo.id, replyId, target: info.slug, });
    window.scrollTo(0, document.body.scrollHeight);
  };

  onUserClick(userData, userId) {
    const { history, setUserInfo } = this.props;
    //sometimes WP puts "sorry, you are not allowed to list users" message to embedded data of comment
    if (userData.id) {
      setUserInfo(userData);
      history.push(`/user/${userData.id}/`);
    } else {
      history.push(`/user/${userId}/`);
    }
  }

  defaultClickHandler = async (postInfo) => {
    const { history, clearPostInfo, getPostInfo } = this.props;
    await history.push(`/${postInfo.slug}`);
    await clearPostInfo();
    await getPostInfo({ id: `/${postInfo.slug}` });
    //window.location.reload();
  }

  allowLoginPopup(){
    document.getElementById('loginBtnHead') && document.getElementById('loginBtnHead').click();
  }

  render() {
    const { tab } = this.state;
    const { isAuthorized, userInfo, bountyFeed, comments, commentPages, isCommentsLoading, status, bounty_reward, vote_end, bounty_end, info } = this.props
    var showSubmitArticle = info?.acf?.start_date ? Date.parse(info?.acf?.start_date) <= Date.now() ? true : false : false
    return (
      <>
        <Grid className="bountyInfo">
          <Grid.Column>
            <ul>
              <li><span>Reward:</span> {bounty_reward ? bounty_reward : '...'}</li>
              <li><span>Submit by:</span> {bounty_end ? bounty_end : '...'}</li>
              <li><span>Voting Ends:</span> {vote_end ? vote_end : '...'}</li>
            </ul>
          </Grid.Column>
        </Grid>

        {showSubmitArticle && (
          <>
            <Grid className="tabBar article_tab">
              <Grid.Column>
                <ul>
                  <li className={tab === 1 && `item_active`} onClick={() => {this.changeTab(1)}}>Entries</li>
                  <li className={tab === 2 && `item_active`} onClick={() => {this.changeTab(2)}}>Discussions</li>
                </ul>
                
                  {isAuthorized && status == true ? (
                    <Link to="../../bounty/editor/new/"><button class="bountySubmitArticle">+ Submit Article</button></Link>
                  ) : status == true ? (
                    <Link to="#"><button class="bountySubmitArticle" onClick={this.allowLoginPopup}>+ Submit</button></Link>
                  ) : (
                    <Link to="#"><button disabled class="bountySubmitArticle">Bounty Closed</button></Link>
                  )
                }
              </Grid.Column>
            </Grid>

            {tab === 1 && (
              <Grid className="all_post-bounties">
                {bountyFeed && bountyFeed.map((data, index) => {
                  let { _embedded, title, excerpt, details, trybe_meta, slug, date_gmt }  = data;
                  return(
                    <Grid className="row post-bounties">
                      <Grid.Column className="post-preview-info cz-new-card img_box" width={8}>
                        <Link to={`/${slug}`}>
                          <Image className="post-preview-avatar" src={_embedded['wp:featuredmedia'][0]?.source_url} />
                          {/*<a className="pay_btn" href="#">pay</a>*/}
                        </Link>
                      </Grid.Column>

                      <Grid.Column className="post-preview-info cz-new-card" width={8}>
                        <Link to={`/${slug}`}>
                          <Grid className="noselect" style={{ padding: '15px' }}>
                            <Grid.Row verticalAlign="top">
                              {/*
                              <Grid.Column width={2} className="position_box">
                                <p>#{index+1}</p>
                              </Grid.Column>
                              */}
                              <Grid.Column width={2} className="icon_thumb">
                                <Image className="post-preview-avatar" circular rounded src={_embedded.author[0].avatar_urls[96]} />
                              </Grid.Column>
                              <Grid.Column width={9}>
                                <Grid.Row>{_embedded.author[0].name}</Grid.Row>
                                <Grid.Row className="dark-text" style={{ fontSize: '1.2em', color: 'var(--green)' }}>@{_embedded.author[0].slug}</Grid.Row>
                              </Grid.Column>
                              <Grid.Column className="dark-text time_box" floated="right" width={3}>
                              {moment
                              .utc(date_gmt)
                              .local()
                              .format('MMMM DD, YYYY')}
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="middle">
                              <Grid.Column>
                                <h4 className="post-preview-title" style={{ marginBottom: '0' }}>
                                  {he.decode(title.rendered)}
                                </h4>
                                <p className="dark-text" style={{ fontSize: '1.1em' }} dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="bottom">
                              <Grid.Column className="dark-text">
                                <Label><Image className="icon_img" src="/img/bomb.png" wrapped ui /> {trybe_meta.ratings_users} </Label>
                                <Label><Image className="icon_img" src="/img/cheet.png" wrapped ui /> {details.post_comments_number} Comments</Label>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="bottom tag_box">
                              <Label>
                                {_embedded['wp:term'] && _embedded['wp:term'][2].slice(0, 8).map((data) => (
                                  <Link to={`../bounty/${he.decode(data.slug).replace(/\s+/g, '-').toLowerCase()}/`}>
                                    {he.decode(data.name)}
                                  </Link>
                                ))}
                              </Label>
                            </Grid.Row>
                          </Grid>
                        </Link>
                      </Grid.Column>
                    </Grid>
                  )
                })}
              </Grid>
            )}
            
            {tab === 2 && (
              <Grid className="all_post-bounties">
                {isAuthorized && <AddComment />}
                {isCommentsLoading ? <Loader active intermediate /> : commentPages > 0 ? <CommentsList comments={comments} userInfo={userInfo} onReply={this.publishReply} onUserClick={this.onUserClick} /> : <h3>Be the first to start a discussion.</h3>}
              </Grid>
            )}
          </>
        )}
      </>
    )
  }
}

export default withCookies(
  withRouter(
    connect(
      (state, ownProps) => {
        return {
          isAuthorized: state.user.userIsLogged,
          userInfo: state.user.userInfo,
          comments: state.post.postComments,
          commentPages: state.post.commentPages,
          isCommentsLoading: state.post.commentsLoading,
          info: state.bounties.bountyInfo,
        };
      },
      dispatch => ({
        getPostComments: (params) => {
          dispatch(actions.getPostComments(params));
        },
        sendReply: (params) => {
          dispatch(actions.replyToPostComment(params));
        },
        clearPostInfo: () => {
          dispatch(actions.clearPostInfo());
        },
        getPostInfo: (params) => {
          dispatch(actions.getPostInfo(params));
        },
      })
    )(BountiesArticles)
  )
);