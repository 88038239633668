import React from "react";
// import ReactDOM from 'react-dom';

// Require Editor JS files.
import { connect } from "react-redux";
import { withCookies } from 'react-cookie';

// Include special components if required.
// import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import $ from "jquery";
import { getMentionSuggestions } from "../dashboard/EditorActions";
import { Editor } from '@tinymce/tinymce-react'; 
import { StepContent } from "semantic-ui-react";

window.$ = $;
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

class Froala extends React.Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.state = {
      postData: props.data && props.data.content
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  handleEditorChange = (modelNew) => {
    const { handleChangeStory } = this.props;
    this.setState({ modelNew });
    handleChangeStory(modelNew);
  }

  render() {
    // const { handleChangeStory } = this.props;
    const { postData } = this.state;
    const { cookies } = this.props;
    const token = cookies.get('trybe_jwt');
    
    function backend_image_upload_handler (blobInfo, success, failure, progress) {
      var xhr, formData;
    
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', 'https://old.trybe.one/wp-json/wp/v2/media/');
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    
      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
      };
    
      xhr.onload = function() {
        var json;

        if (xhr.status === 401) {
          failure('HTTP Error: ' + xhr.status, { remove: true });
          console.log(xhr)
          return;
        }
    
        if (xhr.status === 403) {
          failure('HTTP Error: ' + xhr.status, { remove: true });
          return;
        }
    
        if (xhr.status < 200 || xhr.status >= 300) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
    
        json = JSON.parse(xhr.responseText);
        console.log(json)
    
        if (!json) {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
    
        success(json.source_url);
      };
    
      xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
      };
    
      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
    
      xhr.send(formData);
    };

    return (
      <div className="cz-textarea-setting" style={{ paddingTop: '10px' }}>
        <Editor
          apiKey="4px6zv5yzdhydd9vs4o6q3qnpa9dc08ax1tu79eznbswq7tc"
          onEditorChange={this.handleEditorChange}
          initialValue={postData}
          //disabled
          init={{
            branding: false,
            placeholder: "Tell your story…",
            content_style: `
            @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600&display=swap'); 
            body {
              font-family: 'Lexend' !important;
              background-color: #1b1b1b;
              font-weight: 300 !important;
              color: #d1d1d1 !important;
            }
            .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
              color: #fff;
            }
            img {max-width: 100%;} p {font-size: 16px;}
            h2 { line-height: 2.2em; margin-block-end: 0.33em !important;}
            h3 { line-height: 2em; margin-block-end: 0.23em !important;}
            h4 { line-height: 1.7em; margin-block-end: 0.13em !important;}
            h5 { line-height: 1.5em; margin-block-end: 0 !important; }
            p { margin: 0 !important; font-size: 18px !important; line-height: 36px !important; }
            ul li, ol li { margin: 0 !important; font-size: 18px !important; line-height: 36px !important; }
            blockquote {
              font-size: 22px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: .1em;
              position: relative;
              border: 0;
              padding: 0;
              margin: 2.5em 2rem 2.5em 2rem;
              max-width: 200px;
              text-align: left;
            }
            
            blockquote.pull-left::before,
            blockquote.pull-right::before {
              content: '“';
              font-size: 6em;
              color: var(--pink);
              position: absolute;
              top: -.65em;
              left: 0%;
              pointer-events: none;
            }
            
            blockquote.pull-left::after,
            blockquote.pull-right::after {
              content: '”';
              font-size: 6em;
              color: var(--pink);
              position: absolute;
              bottom: -1.05em;
              right: 30%;
              pointer-events: none;
            }
            
            blockquote.pull-left p,
            blockquote.pull-right p {
              display: inline;
              background-color: var(--pink);
              line-height: 1.5;
            }
            
            blockquote.pull-left {
              float: left;
            }
            
            blockquote.pull-right {
              float: right;
            }
            
            blockquote.center {
              font-size: 22px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: .1em;
              position: relative;
              border: 0;
              padding: 0 0 0 1rem;
              margin: 2.5em 0 2.5em 2rem;
              text-align: left;
              max-width: 100%;
              border-left: 3px solid var(--pink);
            }

            [data-ephox-embed-iri]{
              padding: 0px !important;
            }
            
            @media (min-width: 800px) {
              blockquote {
                font-size: 28px;
                max-width: 280px;
              }
            
              blockquote.pull-left {
                margin-left: -130px;
              }
            
              blockquote.pull-right {
                margin-right: -130px;
              }
            }`,
            skin: "oxide-dark",
            content_css: "dark",
            icons: "thin",
            inline: false,
            menubar: false,
            fileUpload: false,
            plugins: [
              'autoresize',
              'autosave',
              'autolink',
              'codesample',
              'link',
              'lists',
              'media',
              //'mediaembed',
              //'powerpaste',
              'table',
              'image',
              'quickbars',
              'codesample',
              'imagetools',
              'nonbreaking',
              'hr',
              'advlist',
              'help',
              'code',
              'blockquote'
            ],
            toolbar: 'undo redo | formatselect | bold italic | image media | restoredraft | code | blockquote',
            quickbars_insert_toolbar: 'image | media | hr ',
            quickbars_selection_toolbar: 'bold italic link | h2 h3 h4 | blockquote | numlist bullist hr',
            contextmenu: 'undo redo',
            autoresize_max_height: 500,
            autoresize_bottom_margin: 200,
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            lists_indent_on_tab: true,
            default_link_target:"_blank",
            block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4;',
            advlist_number_styles: 'lower-alpha',
            valid_styles: {
              '*': 'font-size,font-family,color,text-decoration,text-align,display,margin,float,margin-left,margin-right,margin-bottom,margin-top,padding-left,padding-top,padding-bottom,padding-right'
            },
            nonbreaking_force_tab: true,
            end_container_on_empty_block: true,
            powerpaste_word_import: 'clean',
            powerpaste_html_import: 'clean',
            media_live_embeds: true,
            // Image upload endpoint and options
            file_picker_types: 'image',
            block_unsupported_drop: true,
            images_file_types: 'jpeg, jpg, png, gif',
            image_dimensions: false,
            images_upload_handler: backend_image_upload_handler,
            imageMaxSize: 25 * 1024 * 1024, // 25MB
            imageUploadParam: 'file',
            imageUploadMethod: 'POST',
            requestHeaders: {
              Authorization: 'Bearer ' + token,
            },
            formats: {
              blockquote: [
                 {block: 'blockquote', attributes: {'class': 'center'}, wrapper: true, remove: 'all'},
                 {block: 'blockquote', attributes: {'class': 'pull-left'}},
                 {block: 'blockquote', attributes: {'class': 'pull-right'}}
              ],
              pullquote_left: {selector: 'blockquote', attributes: {'class': 'pull-left'}, remove: 'all'},
              pullquote_right: {selector: 'blockquote', attributes: {'class': 'pull-right'}, remove: 'all'},
              pullquote_center: {selector: 'blockquote', attributes: {'class': 'center'}, remove: 'all'},
              underline: { styles: { 'text-decoration': 'none' } }
            },
            setup: function(editor) {
              editor.on('ExecCommand', (event) => {
                const command = event.command;
                if (command === 'mceMedia') {
                  const tabElems = document.querySelectorAll('div[role="tablist"] .tox-tab');
                  tabElems.forEach(tabElem => {
                    if (tabElem.innerText === 'Embed') {
                      tabElem.style.display = 'none';
                    }
                    if (tabElem.innerText === 'Advanced') {
                      tabElem.style.display = 'none';
                    }
                  });
                }
              });
            }
          }}
        />
      </div>
    );
  }
}

export default withCookies((
  connect(
    (state, ownProps) => ({
      userInfo: state.user.userInfo,
      cookies: ownProps.cookies,
      mentionSuggestions: state.editor.mentionSuggestions
    }),
    dispatch => ({
      getMentionsSuggestions: (params) => {
        dispatch(getMentionSuggestions(params));
      }
    })
  )
)(Froala));
