import React, { PureComponent } from 'react';
import { Grid, Button, Form, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import * as actions from '../signUpActions';
import { showErrorAlert } from '../../alerts/showAlert';

class EmailAndPasswordStage extends PureComponent {
  state = {
    username: '',
    email: '',
    display_name: '',
    password: '',
    referal_id: '',
    fake_username: '',
    fake_display_name: '',
    checked: true,
    checked_privacy: true,
    recaptcha: false,
    showPassword: 'password'
  };

  handleChange = (e, { id, value }) => this.setState({ [id]: value });

  handleCheckbox = (e, { checked }) => this.setState({ checked });

  handleSubmit = () => {
    const {
      username,
      email,
      password,
      display_name,
      checked,
      checked_privacy,
      referal_id,
      recaptcha
    } = this.state;
    const { signUp } = this.props;

    if (checked, checked_privacy, recaptcha) {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
      if(regex.test(username)){
        showErrorAlert("Please enter a valid username, it should not an email.")
        return false
      }
      signUp({ username, email, password, display_name, referal_id });
    }
  };

  componentDidMount() {
    const ref = localStorage.getItem('trybe_ref');
    const { userInfo } = this.props
    console.log(userInfo);
    if (ref) {
      this.setState({ referal_id: ref });
    }
  }

  showPassword(value) {
    this.setState({ showPassword: value });
  }
  

  render() {
    const { referal_id, recaptcha, checked, showPassword, fake_display_name, fake_username, checked_privacy } = this.state;
    const { signUpMessage, userInfo } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column className="signupBox" padded computer={6} mobile={16} tablet={9}>
            <Grid className="loopsignup mobileSignUpBox">
              <Grid.Row className="loopCard tokenCard signupText">
                <p className="tokenBal">Why Join Loop? </p>
                  <br />
                  <span className="text-light">
                    Get all the latest ALPHA on NFT launches, IDO token sales, airdrops, and other important events in the Cosmos ecosystem!
                    <br />
                    <br />
                  </span>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column className="signupBox" computer={10} mobile={16} tablet={9}>
            <Grid className="loopsignup mobileSignUpBox">
              <p className="title">Please Fill Out the Form Below:</p>
              <Divider />
              {signUpMessage && (
                <span>
                  <p className="error">{signUpMessage}</p>
                  <Divider />
                </span>
              )}
              <Form onSubmit={this.handleSubmit}>
                <Form.Field required>
                  <label htmlFor="display_name">Display name</label>
                  <Form.Input
                    onChange={this.handleChange}
                    id="display_name"
                    type="text"
                  />
                </Form.Field>
                <Form.Field required>
                  <label htmlFor="username">Username</label>
                  <Form.Input
                    id="username"
                    onChange={this.handleChange}
                    type="text"
                  />
                </Form.Field>
                <Form.Field required>
                  <label htmlFor="email">Email</label>
                  <Form.Input
                    id="email"
                    onChange={this.handleChange}
                    type="email"
                  />
                </Form.Field>
                <Form.Field required>
                  <label htmlFor="password">Password</label>
                  <div className="passwordField">
                    <Form.Input
                      id="password"
                      onChange={this.handleChange}
                      type={showPassword}
                      className="passwordInput"
                    />
                    {showPassword == 'password' ? (
                      <span className="eyePosition" onClick={() => this.showPassword('text')}><img className="viewEye" src="/eye.png" /></span>
                    ) : (
                      <span className="eyePosition" onClick={() => this.showPassword('password')}><img className="viewEye" src="/eye-active.png" /></span>
                    )
                    }
                  </div>
                </Form.Field>
                <Form.Field>
                  <label htmlFor="referal_id">Referral ID (not required)</label>
                  <Form.Input
                    onChange={this.handleChange}
                    id="referal_id"
                    value={referal_id}
                    type="number"
                  />
                </Form.Field>
                <Form.Field>
                  <label className="ohnohoney" htmlFor="display_name">Display name</label>
                  <Form.Input
                    className="ohnohoney"
                    onChange={this.handleChange}
                    id="fake_display_name"
                    value={fake_display_name}
                    type="text"
                  />
                </Form.Field>
                <Form.Field>
                  <label className="ohnohoney" htmlFor="username">Your Username</label>
                  <Form.Input
                    className="ohnohoney"
                    onChange={this.handleChange}
                    id="fake_username"
                    value={fake_username}
                    type="text"
                  />
                </Form.Field>
                <Divider />
                <Form.Field>
                  <Form.Checkbox
                    onChange={this.handleCheckbox}
                    id="check"
                    label="I agree to the Terms and Conditions"
                    defaultChecked={checked}
                  />
                </Form.Field>
                <span style={{ color: '#fff' }}><b style={{ color: 'var(--pink)' }}>Terms and Conditions:</b> I promise to be a good, kind, thoughtful human being at all times. Any content I place on this platform will be my own and not copied from some poor, unsuspecting, hardworking soul.</span>
                <Divider />
                <Form.Field className="privacyLinkField">
                  <Form.Checkbox
                    onChange={()=>this.setState({checked_privacy : !checked_privacy})}
                    id="privacy"
                    defaultChecked={checked_privacy}
                  />
                  <span className='privacyLink' style={{ color: '#fff' }}>I've read the <a style={{ color: 'var(--pink)' }} href="https://mail.loop.onl/privacy-policy/" target="_blank">privacy policy</a> and consent.</span>
                </Form.Field>
                <Divider />
                <ReCAPTCHA
                  className="cz-recaptcha"
                  theme="dark"
                  size="normal"
                  badge='bottomright'
                  type="image"
                  tabindex='0'
                  sitekey="6LfMp8gbAAAAAONc0qQfY6MfmXLPbCLkjd5kfaNJ"
                  onChange={() => this.setState({ recaptcha: !recaptcha ? true : false })}
                />
                <Divider />
                <Button onClick={() =>  window.dataLayer.push({ 'event':'signup-proceed', 'event_category':'engagement', 'event_action':'signup-proceed'})} type="submit" disabled={!recaptcha || !checked || !checked_privacy || fake_username || fake_display_name} className="btn px-3 mb-2 mb-lg-0 signUPTag">Proceed</Button>
              </Form>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default withRouter(
  connect(
    (state) => {
      return {
        signUpMessage: state.signUp.signUpMessage,
        userInfo: state.user.userInfo,
      };
    },
    dispatch => ({
      signUp: (params) => {
        dispatch(actions.signUp(params));
      }
    })
  )(EmailAndPasswordStage)
);
