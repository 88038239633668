import React, { Component } from 'react';
import { withRouter,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Grid,Select, Option } from 'semantic-ui-react';
import Autosuggest from 'react-autosuggest';
import Footer from '../../footer/Footer';
import './search.scss';
import * as api from './Api';
import ContentLoader from "react-content-loader"
import SwiperCore, { Navigation, Scrollbar, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PostsList from '../../util/feed/components/PostsList';
import { getAllCategories } from '../../yourtrybe/articles/articlesActions';
import * as feedActions from '../../util/bountyFeed/feedActions';
import { setPostInfo } from '../../post/postActions';
import { Dropdown } from 'semantic-ui-react'
import he from 'he';
import lazyImage from '../../../staticAssets/images/placeholder.png';
import search from '../search2/search';

let languages = [];
let categoryId=null;
const getSuggestionValue = suggestion => suggestion.name;
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div className="suggestions">
    {suggestion.name}
  </div>
);
class Search extends Component {
  constructor(props) {
    super(props);
    this.defaultClickHandler = this.defaultClickHandler.bind(this);
    this.state = {
      searchOptions : [
        {
          key: 'Topic',
          text: 'Topic',
          value: 'Topic',
        },
        /*
        {
          key: 'User',
          text: 'Authors',
          value: 'User',
        },
        {
          key: 'Category',
          text: 'Category',
          value: 'Category',
        }
        */
      ],
      postData:[],
      searchBy:'',
      searchType:'Topic',
      value: '',
      suggestions: [],
      categoryId: null,
      slug:[],
      categoriesData:[],
      contentLoading:true,
      noResult:false,
      page:1,
      scrollStatus:false,
      titlesData:[],
      usersData:[],
      searchStatus:true,
      seraching:true,
      endData:false
    };
  }

  async componentDidMount() {
    const { getCategories, categories } = this.props;

    await api.fetchSearchData({"page": 1,"perPage":10,
      "queryParams": '&author=&cache=true' }).then(
        postData =>  {
          this.setState({ postData })
          this.setState({contentLoading:false})
        });

    await api.fetchAllTitles().then(
      postData =>  {
        if(postData.data){
          this.updateSuggestionsData(postData.data);
        }
    });

    /*
    
    await getCategories();

    if(this.state.searchType=='Category'){
      const newArray=[];
      await categories.map((postInfo) => {
        newArray.push({"name":postInfo.name,"id":postInfo.id})
      })
      languages = newArray;
      this.setState({ categoriesData:newArray })
    }

    await api.fetchAllUsers().then(
      postData =>  {
        if(postData.data){
          this.setState({ usersData:postData.data })
        }
    });

    */

    const el = document.querySelector('.app-body');
    el.addEventListener('scroll', this.onScroll);
  }

  onScroll = ({ target }) => {
    let pageScroll = window.innerHeight + target.scrollTop;
    let pageHeight = target.scrollHeight;
    if(window.innerHeight + target.scrollTop >= target.scrollHeight - 4000 && this.state.seraching==true){
      let page =  this.state.page + 1;
      this.setState({page:page});
      this.searchData(page);
    }
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : languages.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    )
  };
  
  async updateSuggestionsData(posts){
      const newArray=[];
      if(posts && posts.length>0){
        await posts.map((postInfo) => {
          newArray.push({"name":postInfo.slug})
        })
        this.setState({ slug:newArray })
      }
  }

  async searchData(page){
    if(page!=1 && this.state.endData==true){}else{
    this.setState({endData:false});
    this.setState({contentLoading:true})
    this.setState({seraching:false});
    if(page==1){
      this.setState({postData:[]})
      this.setState({contentLoading:true})
    } else{
      this.setState({scrollStatus:true});
    }
    if(this.state.searchType=='Category' || this.state.searchType=='User'){
      const inputValue = this.state.value.trim().toLowerCase();
      const inputLength = inputValue.length;
      await languages.filter(lang => {
        if(lang.name.toLowerCase().slice(0, inputLength) === inputValue){
          this.setState({categoryId:lang.id});
        }
      })
      }
      await api.fetchSearchData({search: this.state.value,
        page,
        perPage: 10,
        type: this.state.searchType,
        categories: this.state.categoryId,
        queryParams: '&author=&cache=true' }).then(
        (postData) => {
          if(postData) {
            if(page==1) {
              this.setState({postData});
              this.setState({searchStatus: true});
              if(postData.length<10) {
                this.setState({endData: true});
              }
            }else if(postData.length==0) {
              this.setState({endData: true});
              this.setState({contentLoading: false});
              this.setState({noResult: true});
              this.setState({searchStatus: true});
            }else{
              this.setState({postData:[...this.state.postData,...postData]});
              this.setState({searchStatus:true});
              if(postData.length<10){
                this.setState({endData:true});
              }
            }
          }else{
            this.setState({contentLoading: false});
            this.setState({endData: true});
          }
        });
      this.setState({contentLoading: false});
      this.setState({scrollStatus: false});
      this.setState({seraching: true});
      this.setState({noResult: true});
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      noResult: false
    });
    this.setState({
      value: newValue
    });
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      //console.log('Enter pressed!')
      this.searchData(1);
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  
  handleChangeEvent = (e, { value }) => this.setState({ searchBy: value });
  handleSelectEvent = (e, { value }) => {
    this.setState({ noResult: false });
    this.setState({ searchBy: '' });
    this.setState({ value: '' });
    this.setState({ searchType: value });
    languages=[];
    if(value=='Category'){
      
      languages = this.state.categoriesData;
    }else if(value=='Topic'){
      
      languages = this.state.slug;
    } else if(value=='User'){
      languages = this.state.usersData;
    }
  }

  defaultClickHandler(postInfo) {
    const { setPost } = this.props;
    setPost(postInfo);
    //history.push(`/${postInfo.slug}`);
  }
  
  render() {
    const {
      postData,
      value, suggestions,contentLoading,page,scrollStatus,endData,searchType,noResult
    } = this.state;
    
    const inputProps = {
      placeholder: 'Search By '+searchType,
      value,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown
    };
    const { categories, categoriesAreLoading, categoriesList, isLoading, noAuthor, clickHandler } = this.props;
    //console.log(postData)
    return (
      <div>
        <div className="search-inputWrap">
            <div className="searchInner">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
            />
            {/* <Input placeholder="Search..." name="searchBy" onChange={this.handleChangeEvent} value={this.state.searchBy}/> */}
            <Dropdown placeholder="Search By" onChange={this.handleSelectEvent} selection options={this.state.searchOptions} value={this.state.searchType} />

            <button className="searchIcon" onClick={() => this.searchData(1)}><i className="fas fa-search" /> Search</button>
          </div>
          <Container className="feed cz-con-new pre_loader_grid">

            {
              postData && postData.length>0
                ? (
                  <PostsList
                    className="posts-list"
                    posts={postData}
                    isLoaderActive={isLoading}
                    noAuthor={noAuthor}
                    clickHandler={
                      clickHandler
                        ? clickHandler
                        : this.defaultClickHandler
                    }
                  />
                )
                : contentLoading==true
                  ? (
                    <div className="ui segment my-feeds-loader">
                      <div className="ui active dimmer">
                        <ContentLoader
                          speed={2}
                          style={{ width: '100%' }}
                          viewBox="0 0 450 145"
                          backgroundColor="#1b1b1b"
                          foregroundColor="#2f2f2f"
                        >
                          <rect x="45" y="20" rx="3" ry="3" width="90" height="4" />
                          <rect x="45" y="30" rx="3" ry="3" width="30" height="4" />
                          <rect x="10" y="61" rx="3" ry="3" width="200" height="4" />
                          <rect x="10" y="71" rx="3" ry="3" width="150" height="4" />
                          <rect x="10" y="81" rx="3" ry="3" width="100" height="4" />
                          {/*<rect x="240" y="0" rx="0" ry="0" width="500" height="100" />*/}
                          <circle cx="25" cy="25" r="15" />
                        </ContentLoader>
                      </div>
                    </div>
                  )
                  : (
                    noResult && (
                      <div className="noFound">
                        <span>No Record Found</span>
                      </div>
                    )
                  )
            }

              {
                endData==false ?
                (<div class="ui segment my-feeds-loader">
                 <div class="ui active dimmer">
                      <ContentLoader
                        speed={2}
                        style={{ width: '100%' }}
                        viewBox="0 0 450 145"
                        backgroundColor="#1b1b1b"
                        foregroundColor="#2f2f2f"
                      >
                        <rect x="45" y="20" rx="3" ry="3" width="90" height="4" />
                        <rect x="45" y="30" rx="3" ry="3" width="30" height="4" />
                        <rect x="10" y="61" rx="3" ry="3" width="200" height="4" />
                        <rect x="10" y="71" rx="3" ry="3" width="150" height="4" />
                        <rect x="10" y="81" rx="3" ry="3" width="100" height="4" />
                        {/*<rect x="240" y="0" rx="0" ry="0" width="500" height="100" />*/}
                        <circle cx="25" cy="25" r="15" />
                      </ContentLoader>
                    </div>
                  </div>
                ): ('')
            }
          </Container>

        </div>

      </div>
    );
  }
}
export default withRouter(connect(
  (state, ownProps) => ({
    isAdmin: state.user.userInfo.extra_capabilities,
    categories: state.articles.categories,
    categoriesAreLoading: state.articles.categoriesAreLoading,
    cookies: ownProps.cookies,
    categoriesList: { 0: 'LOOP', 1: 'Blockchain & Cryptocurrencies', 2: 'NFT' },
    isLoading: state.feed.loading,
    noAuthor: state.feed.noAuthor,
  }),
  dispatch => ({
    getCategories: () => {
      dispatch(getAllCategories());
    },
    setPost: (postInfo) => {
      dispatch(setPostInfo(postInfo));
    }
  })
)(Search));
