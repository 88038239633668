import {useEffect, useState } from "react"
import Twitter_icon from "./images/twitter.svg"
import Discord_icon from "./images/discord.svg"
import Telegram_icon from "./images/telegram.png"
import Down_Arrow from "./images/polygon.svg"
import Reddit_icon from "./images/reddit.png"

const SocialDropdown = () => {
  const [isDropdown, setIsDropDown] = useState(false)

  const toogleDropDown = () => {
    setIsDropDown((prev) => !prev) 
  }

  const closeDropdown = () => {
    window.innerWidth < 600 ? setIsDropDown(true) : setIsDropDown(false)
  }

  useEffect(()=>{

    window.innerWidth < 600 && setIsDropDown(true)

  },[window])

  return (
    <>
      {isDropdown && (
        <div
          className="invisibleDiv"
          onClick={() => setIsDropDown(false)}
        ></div>
      )}
      <div style={{ display: 'flex',  flexDirection: 'column', marginRight: '15px' }}>
        <button
          className="socialDropdown-btn"
          style={isDropdown ? { marginTop: "150px" } : {  }}
          onClick={toogleDropDown}
        >
          <img
            src={Telegram_icon}
            alt="telegram"
            style={{ marginLeft: "9px" }}
          />
          <a
            className="title"
            href="https://t.me/loopfinance"
            target="_blank"
          >
            Telegram
          </a>{" "}
          <img src={Down_Arrow} alt="" style={{ width: "9px" }} />
        </button>

        {isDropdown && (
          <div className="socialDropdown">
            <a
              href="https://t.me/loopannouncements/"
              target="_blank"
              className="socialLink"
              onClick={closeDropdown}
            >
              <img src={Telegram_icon} alt="twitter" />

              <span className="title">TG Alerts</span>
            </a>
            <a
              href="https://twitter.com/loop_markets"
              className="socialLink"
              target="_blank"
              onClick={closeDropdown}
            >
              <img src={Twitter_icon} alt="twitter" />
              <span className="title">Twitter</span>
            </a>
            <a
              href="https://discord.gg/loopfinance"
              className="socialLink"
              target="_blank"
              onClick={closeDropdown}
            >
              <img src={Discord_icon} alt="discord" />
              <span className="title">Discord</span>
            </a>
            <a
              href="https://www.reddit.com/r/Loop/"
              className="socialLink"
              target="_blank"
              onClick={closeDropdown}
            >
              <img src={Reddit_icon} alt="twitter" />
              <span className="title">Reddit</span>
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export default SocialDropdown

