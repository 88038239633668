import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Card, Loader, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import * as actions from "../../actions/actions";
import styles from "../../index.module.scss";

class CollectionsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenData: null,
      hideForm: false,
      show: false,
      tokenName: "",
    };
  }

  async componentDidMount() {
    const { getFeaturedNfts } = this.props;
    await getFeaturedNfts();
  }

  render() {
    const { featuredNfts, featuredNftsLoaded } = this.props;
    const discoverList = [
      {
        name: "Artist NFTs",
        count: "3200",
        url: "#",
        bg:
          "/assets/images/fanartist.png",
      },
      {
        name: "Music Releases",
        count: "5600",
        url: "#",
        bg:
          "/assets/images/fanmusic.png",
      },
      {
        name: "Gated Community",
        count: "12",
        url: "#",
        bg:
          "/assets/images/fancom.png",
      },
      {
        name: "NFT Tickets",
        count: "440",
        url: "#",
        bg:
          "/assets/images/fanticket.png",
      },
      {
        name: "Metaverse concerts",
        count: "65",
        url: "#",
        bg:
          "/assets/images/fanmeta.png",
      },
      {
        name: "Merchandise",
        count: "94",
        url: "#",
        bg:
          "/assets/images/fanmerch.png",
      },
    ];

    return (
      <>
        <div className={styles.discover}>
          <div className={styles.container}>
            <b>Benefits</b>
            <h3>
              Discover amazing things <br /> going on in the{" "}
              <span>community</span>
            </h3>

            <Grid columns="three" divided>
              <Grid.Row>
                {featuredNftsLoaded ? (
                  discoverList?.map((element, index) => {
                    return (
                      <Grid.Column className={styles.column} key={index} style={{ padding: "25px" }}>
                        <Card
                          className={styles.collection}
                          style={{
                            backgroundImage: `url(${element.bg})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <Card.Content>
                            <Card.Header>
                              <div className={styles.collection_profile}>
                                <div className={styles.collection_details}>
                                  <p className={styles.details} style={{marginBottom: '20px'}}>
                                    {element.name}
                                    <br />
                                    {/*
                                    <span>{element.count}</span>
                                    */}
                                  </p>
                                  {/*
                                    <a href={`${element.url}`} target="_blank">
                                      <div className={styles.nft_button}>
                                        View
                                      </div>
                                    </a>
                                  */}
                                </div>
                              </div>
                            </Card.Header>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                    );
                  })
                ) : (
                  <Loader active />
                )}
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      featuredNftsLoaded: state.home.featuredNftsLoaded,
      featuredNfts: state.home.featuredNfts,
    }),
    (dispatch) => ({
      getFeaturedNfts: () => dispatch(actions.getFeaturedNfts()),
    })
  )(CollectionsSection)
);
