import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
} from "semantic-ui-react";

class FeaturedCollections extends Component {
  render() {
    return (
      <>
        <Grid columns={1}>
          <div className="header_section">
            <Container>
              <div className="heading">
                <Grid.Column>
                  <h3>
                    Empowering
                    <span>Decentralization</span>& NFT Experiences
                  </h3>
                  <p>
                    Buy, Sell and Discover digital assets with ease.
                  </p>
                </Grid.Column>
              </div>
            </Container>
          </div>
        </Grid>
      </>
    );
  }
}
export default withRouter(FeaturedCollections);
