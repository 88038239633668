import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Feed from '../util/bountyFeed/Feed';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TextTruncate from 'react-truncate-html';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import Helmet from 'react-helmet';
import he from 'he';
import {
  Grid,
  Image,
  Card,
  Dropdown,
  Container
} from 'semantic-ui-react';
import SideControl from '../yourtrybe/components/SideControl';
import * as actions from './bountyActions';
import * as api from './bountyApi';
import moment from 'moment';
class viewCompetitions extends Component {
  constructor(props) {
    SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
    super(props)
    this.state = {
      bountyType: 'all',
      allTerms: null,
      currentAuthorQuery: [],
      currentCategoryQuery: [],
      getPromos: '',
      friendOptions: [
        {
          key: 'Highest star rating',
          text: 'Highest star rating',
          value: 'Highest star rating',
        },
        {
          key: 'Lowest star rating',
          text: 'Lowest star rating',
          value: 'Lowest star rating',
        },
      ]
    }
  }





  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { getBounties } = this.props;
    await getBounties();
    await api.getAllTerms().then(allTerms => this.setState({ allTerms }));
  }

  sortOfficalAuthor() {
    const { allTerms } = this.state;
    const query = allTerms.map(({ id }) => [id]);
    this.setState({ allTermsQuery: query });
  }

  sortBounties(type) {
    this.setState({ bountyType: type });
  }

  /*
  handleOrderChange(value) {
    localStorage.setItem('tabName', value);
    //document.querySelector('.app-body').scrollTo(0, 0);
    // 30 Days
    const month = moment().subtract(30, 'days').toString();
    const month_date = new Date(month);

    // Week
    const week = moment().subtract(7, 'days').toString();
    const week_date = new Date(week);

    // Today
    const today = moment().subtract(1, 'day').toString();
    //const today_date = new Date(today);
    this.setState({ tabName: value });
    if (value === 'Feed') {
      this.selectFavourites();
      this.setState({ orderQuery: '' });
    } else if (value === 'Latest') {
      this.selectTerraNews();
      this.setState({ orderQuery: '' });
    } else if (value === 'Today') {
      this.setState({ orderQuery: '' });
      this.selectAll();
    } else if (value === 'Week') {
      this.setState({ orderQuery: `&after=${week_date.toISOString()}&orderby=token_score_average` });
      this.selectAll();
    } else if (value === 'Month') {
      this.setState({ orderQuery: `&after=${month_date.toISOString()}&orderby=token_score_average` });
      this.selectAll();
    } else if (!value) {
      this.setState({ orderQuery: '' });
      this.selectAll();
    }
  }
  */
 getFutureFormat(date){
  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  var d = new Date(date)
  return monthNames[d.getMonth()]+' '+d.getDate();
 }

  render() {
    const { bounties, isAuthorized } = this.props
    const { currentCategoryQuery, friendOptions, allTerms, bountyType } = this.state;
    // console.log(bountyType)
    const sortBounties = (data) => bountyType == 'all' ? Date.now()
    : bountyType == 'live' ? Date.parse(data.acf.start_date) < Date.now() && Date.now() < Date.parse(data.acf.end_date)
    : bountyType == 'voting' ? Date.now() < Date.parse(data.acf.voting_period) && Date.now() > Date.parse(data.acf.end_date)
    : bountyType == 'ended' ? Date.now() > Date.parse(data.acf.end_date) && Date.now() > Date.parse(data.acf.voting_period)
    : bountyType == 'future' ? Date.parse(data.acf.start_date) > Date.now() : '';
    return (
      <Container fluid className="your-trybe-wrapper">

        <Grid className="promo">
          <Helmet>
            <title>Loop Finance | Community</title>
          </Helmet>
          <Grid className="page-wrapper">
            <SideControl />
            <div className="content-wrapper cz-bountyPageWrap">
              {/* <div className="cz-bountyPage"> */}
              <Grid className="c-row">

                <Grid.Column computer={11} mobile={16} tablet={16} className="bg-info cz-set-new-blog">

                  <div className="bountyHeading">
                    <ul class="bounty_event">
                      <li className={bountyType == 'all' && 'active'} onClick={() => this.sortBounties('all')}>All</li>
                      <li className={bountyType == 'live' && 'active'} onClick={() => this.sortBounties('live')}>Live Bounties</li>
                      <li className={bountyType == 'voting' && 'active'} onClick={() => this.sortBounties('voting')}>Voting Now</li>
                      <li className={bountyType == 'ended' && 'active'} onClick={() => this.sortBounties('ended')}>Past Bounties</li>
                      <li className={bountyType == 'future' && 'active'} onClick={() => this.sortBounties('future')}>Future Bounties</li>
                    </ul>
                    <div>
                    {isAuthorized &&
                      <Link to="../../bounty/editor/new/"><button class="bountySubmitArticle">+ Submit Article</button></Link>
                    }
                    </div>
                  </div>


                  <Grid.Column className="start-learning-card featured-feed-card bountiesCard_view" computer={16} mobile={16} tablet={16}>
                    {bounties && (
                      <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        navigation
                        //loop
                        autoplay={{ delay: 7000 }}
                        className="feedSwiper"
                        slideToClickedSlide
                        breakpoints={{
                          1200: {
                            freemode: true,
                            slidesPerView: 3,
                            spaceBetween: 20,
                          },
                          768: {
                            freemode: true,
                            slidesPerView: 3,
                            spaceBetween: 20,
                          },
                          640: {
                            freemode: true,
                            slidesPerView: 2,
                            spaceBetween: 20,
                          },
                          320: {
                            freemode: true,
                            slidesPerView: 1,
                            spaceBetween: 20,
                          }
                        }}
                      >
                        {bounties.map(data => (
                          sortBounties(data) && (
                            <SwiperSlide>
                              <Card as={Link} to={`../bounty/${data?.slug}/`}>
                                <Image className="bountisImg" src={data?._embedded['wp:featuredmedia'][0].source_url} wrapped ui={false} />
                                <Card.Content>
                                  <Card.Header>
                                    <TextTruncate
                                      lines={2}
                                      dangerouslySetInnerHTML={{
                                        __html: he.decode(data.title.rendered)
                                      }}
                                    />
                                  </Card.Header>
                                  <Card.Description>
                                    <TextTruncate
                                      lines={2}
                                      dangerouslySetInnerHTML={{
                                        __html: he.decode(data?.excerpt.rendered)
                                      }}
                                    />
                                    <div className='bounty_card_footer'>
                                      Reward:
                                      <p className="bounty_reward">
                                        <span>{data.acf.bounty_reward ? data.acf.bounty_reward : 0}</span>
                                      </p>
                                      { Date.parse(data.acf.start_date)>Date.now() ?
                                        <p className="bounty_status">&nbsp;</p>
                                        :
                                        <p className="bounty_status">{Date.parse(data.acf.end_date) > Date.now() ? 'Active' : Date.parse(data.acf.voting_period) > Date.now() ? 'Voting' : 'Ended'}</p>
                                        
                                      }
                                    </div>
                                    {Date.parse(data.acf.start_date) > Date.now() && 
                                      <div className='bounty_card_footer_right'>
                                        Submission Opens:
                                        <p className="bounty_reward"><span>{data.acf.start_date ? this.getFutureFormat(data.acf.start_date) : ''}</span></p>
                                      </div>
                                    }
                                  </Card.Description>
                                </Card.Content>
                              </Card>
                            </SwiperSlide>
                          )
                        ))}
                      </Swiper>
                    )}
                  </Grid.Column>


                  <Grid className="tabBar">
                    <Grid.Column>
                      <ul>
                        {/*}
                    <li>
                      <div className="tab_box">
                        <div className="select_box">
                          <img src="/filter_icn.png" />  Sort: {' '}
                          <Dropdown
                            inline
                            options={friendOptions}
                            defaultValue={friendOptions[0].value}
                          />
                        </div>
                      </div>
                    </li>
                    <li><p>Competition entries:</p></li>
                    <li><a className="item_active" >All Bounties</a></li>
                    <li><a>LOOP Protocol</a></li>
                    <li><a>Spar Protocol</a></li>
                    <li><a>Some other bounty</a></li>
                    <li><a>Egestas pretium</a></li>
                    <li><a>Vulputate enim</a></li>
                    <li><a>Sollicitudin ac orci</a></li>
                    <li><a>Porttitor leo</a></li>
                    <li><a>Eget sit amet</a></li>
                    <li><a>Lectus sit amet</a></li>
                    <li><a>Eget sit amet</a></li>
                    */}
                      </ul>
                    </Grid.Column>
                  </Grid>

                  {allTerms && (
                    <Feed
                      tabName="Latest"
                      queryParams={
                        `&bounty=${allTerms}`
                      }
                    />
                  )}
                </Grid.Column>

                <Grid.Column computer={5} mobile={16} tablet={16} className="right_side cz-com-page-profile">
                </Grid.Column>




              </Grid>
              {/* </div> */}
            </div>
          </Grid>
        </Grid >
      </Container>
    )
  }
}

// export default viewCompitions

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        bounties: state.bounties.bounties,
        isAuthorized: state.user.userIsLogged,
      };
    },
    dispatch => ({
      getBounties: () => dispatch(actions.getBountyList())
    })
  )(viewCompetitions)
);